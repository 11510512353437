import React, { useContext, useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { AuthContext, AuthStatus } from '../../../providers'
import { UserInviteLookup } from '../../../models'

import * as ROUTES from '../../../constants/routes'

import BasePage from '../../../components/BasePage/BasePage'
import CenterLayout from '../../../components/CenterLayout'

import styles from './InviteAcceptPage.module.css'

const InviteAcceptPage = () => {

  const { inviteToken } = useParams()
  
  const authContext = useContext(AuthContext)

  const navigate = useNavigate()

  //const [decodedToken, setDecodedToken] = useState<IDecodedInviteToken | undefined>()
  const [showForm, setShowForm] = useState<boolean>(true)
  const [inviteDetails, setInviteDetails] = useState<UserInviteLookup>()

  const [name, setName] = useState<string>('')
  const [pass, setPass] = useState<string>('')
  const [confirmPass, setConfirmPass] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [inviteError, setInviteError] = useState<Error | undefined>(undefined) // NB: only used while checking/loading the token details, not for the actual submission, which is handled via the auth provider & its state vars

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // const [inviteResult, setInviteResult] = useState<boolean>(false)

  const [inviteComplete, setInviteComplete] = useState<boolean>(false)

  const runCheckInviteToken = async () => {
    console.log('InviteAcceptPage - runCheckInviteToken - inviteToken:', inviteToken)
    setLoading(true)
    try {
      const _inviteDetails = await authContext.actions.checkInviteToken(inviteToken ?? '')
      setInviteDetails(_inviteDetails)
    } catch (error: any) {
      setInviteError(error)
      setShowForm(false)
    }
    setLoading(false)
  }

  const runChecks = async () => {
    
    // redirect to the home page if already logged in (shouldn't get this far if you are, but just incase)
    const isLoggedIn = authContext.actions.isLoggedIn()
    if (isLoggedIn) {
      console.log('InviteAcceptPage - runAcceptInviteToken - ALREADY LOGGED IN - HALT')
     navigate(ROUTES.HOME)
     return // NB: should already be handled by the base routing code
    }

    // TESTING:
    await runCheckInviteToken()
    // TODO: handle if errors - halt??

    // check we can decode the invite token & we have an email field specified
    // TODO: also check the expiry date? (currently leaving that to the api response when submitting)
    // TODO: ..could possibly add an endpoint to check the token status & auto call it on init to check its expiry & anything else (against the server clock/time etc.)??
    // UPDATE: we now do this via a dedicated api call so extra checks can be performed server side
//     try {
//       console.log('InviteAcceptPage - inviteToken:', inviteToken)
//       const decodedToken = inviteToken ? authContext.actions.decodeInviteToken(inviteToken) : undefined
//       console.log('InviteAcceptPage - decodedToken:', decodedToken)
//       if (!decodedToken || !decodedToken.email) {
// //        setInviteError(new Error('Error reading the invite token'))
//         setShowForm(false)
//       }
//     } catch (error: any) {
//       //setInviteError(error) // NB: hiding the underlying error (as can be quite technical)
// //      setInviteError(new Error('Error reading the invite token'))
//       setShowForm(false)
//     }
  }
  
  const runAcceptInviteToken = async () => {
    console.log('InviteAcceptPage - runAcceptInviteToken - inviteToken:', inviteToken)
    
    // verify the user with the supplied token
    setIsSubmitting(true)
    // setInviteResult(false)
    // setInviteError(undefined)
    //try {
      await authContext.actions.acceptInvite(name, pass, confirmPass, inviteToken ?? '')
      setIsSubmitting(false)
      // NB: check for any auth error set in the provider & rethrow it so we show it here
      // TODO: THIS WON'T WORK, the authError state var won't have set yet!!! <<<<<<<<<<<<<<<<<
      // if (authContext.store.authError) {
      //   console.log('InviteAcceptPage - runAcceptInviteToken - authContext.store.authError:', authContext.store.authError)
      //   throw authContext.store.authError
      // }
      //setInviteResult(true)
    // } catch (error: any) {
    //   console.error('InviteAcceptPage - runAcceptInviteToken - error:', error)
    //   setInviteResult(false)
    //   setIsSubmitting(false)
    //   setInviteError(error)
    // }
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log('InviteAcceptPage - onSubmit - name:', name, 'pass:', pass, 'confirmPass:', confirmPass)
    event.preventDefault()
    //setLoading(true)
    await runAcceptInviteToken()
    //setLoading(false)
  }

  useEffect(() => {
      runChecks()
    },
    // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    console.log('InviteAcceptPage - useEffect - authContext.store.authStatus:', authContext.store.authStatus)
    if (authContext.store.authStatus === AuthStatus.loggedIn && inviteComplete === false && !inviteError) {
      console.log('InviteAcceptPage - useEffect - authContext.store.authStatus - INVITE ACCEPTED/COMPLETE...')
      setInviteComplete(true)
    }
  }, [authContext.store.authStatus, inviteComplete, inviteError])

  const onSuccessClick = () => {
    navigate(ROUTES.HOME)
  }

  return (
    <BasePage>
      <CenterLayout>
        <h1>{!(inviteComplete && !inviteError) ? <>Accept Invite</> : <>Invite Accepted</>}</h1>
        {showForm && !inviteComplete && (<p>You have been invited to join. <br />Enter your details below to create an account.</p>)}
        {inviteToken && loading && (<>Checking...</>)}
        {inviteToken && isSubmitting && (<>Processing...</>)}
        {inviteComplete && !inviteError && (
          <div className={styles.successMsg}>
            <h3>Welcome!</h3>
            <p>You're account has been created</p>
            <br />
            <div>
              <button onClick={onSuccessClick}>Continue</button>
            </div>
          </div>
        )}
        {/* {inviteToken && isSubmitting === false && inviteResult === false && !inviteError && (
          <div className={styles.errMsg}>
            <h3>Error</h3>
            <p>Failed to process the invite</p>
          </div>
        )} */}
        {inviteError && (
          <div className={styles.errMsg}>
            <h3>Invite Error</h3>
            <p>{inviteError.message}</p>
          </div>
        )}
        {showForm && !inviteComplete && (
          <form className={styles.form + (isSubmitting ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
            {authContext.store.authError && (
              <div className={styles.error}>{authContext.store.authError.message}</div>
            )}
            <div className={styles.field}>
              <label htmlFor="email">Name:</label>
              <div className={styles.input}>
                <input type="text" value={name} id="display_name" name="display_name" /* autoComplete="display_name" */ onChange={(event) => { setName(event.target.value) }} />
              </div>
            </div>
            <div className={styles.field}>
              <label htmlFor="email">Email:</label>
              <div className={styles.input}>
                <input type="text" value={inviteDetails?.email ?? ''} id="email" name="email" autoComplete="username" disabled={true} />
              </div>
            </div>
            <div className={styles.field}>
              <label htmlFor="pass">Password:</label>
              <div className={styles.input}>
                <input type="password" value={pass} id="pass" name="pass" autoComplete="password" onChange={(event) => { setPass(event.target.value) }} />
              </div>
            </div>
            <div className={styles.field}>
              <label htmlFor="confirmPass">Confirm Password:</label>
              <div className={styles.input}>
                <input type="password" value={confirmPass} id="confirmPass" name="confirmPass" /*autoComplete="password"*/ onChange={(event) => { setConfirmPass(event.target.value) }} />
              </div>
            </div>
            <div className={styles.buttons}>
              <div className={styles.field + ' ' + styles.button}>
                <input type="submit" value="Accept Invite" disabled={isSubmitting} />
              </div>
            </div>
          </form>
        )}
      </CenterLayout>
    </BasePage>
  )
}

export default InviteAcceptPage
