/**
 * Panel
 */

import React, { useState } from 'react'

import styles from './Panel.module.css'

export type PanelPropertyEditContentCallback = () => Promise<React.ReactNode>

export interface PanelPropertiesProps {
  children?: React.ReactNode
  titleWidth?: number | string
  valueWidth?: number | string
  hintWidth?: number | string
}
export interface PanelPropertySectionProps {
  title: string
  collapsible?: boolean
  collapsed?: boolean // default collapsed state if collapsible == true (ignored if not)
  children?: React.ReactNode
}
export interface PanelPropertyRowProps {
  children?: React.ReactNode
  title?: string | React.ReactNode
  value?: string | React.ReactNode
  hint?: string | React.ReactNode
  titleWidth?: number | string
  valueWidth?: number | string
  hintWidth?: number | string
  className?: string
  titleClassName?: string
  valueClassName?: string
  hintClassName?: string
  hasChanges?: boolean
  editContent?: React.ReactNode | PanelPropertyEditContentCallback
  onSaveEdit?: () => Promise<boolean>
  onStartEdit?: () => void
  onEndEdit?: (didEdit: boolean) => void
}
export interface PanelPropertyRowTitleProps {
  children?: React.ReactNode
  width?: number | string
  className?: string
}
export interface PanelPropertyRowValueProps {
  children?: React.ReactNode
  width?: number | string
  className?: string
  editContent?: React.ReactNode | PanelPropertyEditContentCallback
  onSaveEdit?: () => Promise<boolean>
  onStartEdit?: () => void
  onEndEdit?: (didEdit: boolean) => void
}
export interface PanelPropertyRowHintProps {
  children?: React.ReactNode
  width?: number | string
  className?: string
}
export interface PanelPropertyRowContentProps {
  children?: React.ReactNode
  className?: string
}
// export interface PanelPropertyRowMessageProps extends MessageProps {
//   // children?: React.ReactNode
//   // className?: string
// }

export interface PanelPropertDividerProps {
  children?: React.ReactNode
  className?: string
}

export interface PanelPropertSpacerProps { // NB: loosely based on ArkSpacer
  large?: boolean
  size?: number
  small?: boolean
}

const PanelPropertyRowTitle = (props: PanelPropertyRowTitleProps) => {
  return (<div className={styles.title + (props.className ? ' ' + props.className : '')} style={ props.width ? { flexBasis: props.width } : undefined }>{props.children}</div>)
}

const PanelPropertyEditableRowValue = (props: PanelPropertyRowValueProps) => {
  const { onSaveEdit, onStartEdit, onEndEdit } = props
  const [isEditing, _setIsEditing] = useState<boolean>(false)
  const [dynamicEditContent, setDynamicEditContent] = useState<React.ReactNode | undefined>(undefined)
  const setIsEditing = (_isEditing: boolean, saved: boolean = false) => {
    if (!_isEditing) {
      if (onEndEdit) onEndEdit(saved)
    }
    _setIsEditing(_isEditing)
    if (_isEditing) {
      if (onStartEdit) onStartEdit() // TESTING: trigger this AFTER setting it (so we can use `setFocus`) - NB: if also need both, add an `onWillStartEdit` callback & rename this to `onDid..`?
    }
  }
  // TESTING: handle dynamic `editContent` based async function callbacks - allowing the calling code to supply the panel properties edit input after loading needed data
  // TESTING: ..show a loading indicator if while waiting for the callback to return with the property input content to render
  let editContent = null
  if (isEditing && props.editContent !== undefined && props.editContent !== null && dynamicEditContent === undefined) {
    // TESTING: check if the prop is a function - ref: https://stackoverflow.com/a/55785839
    if (typeof props.editContent === 'function' && (props.editContent instanceof Function || Object.prototype.toString.call(props.editContent) === "[object Function]" || "function" === typeof props.editContent)) {
      // declare an inline function to allow us to trigger the callback in an async way - update a state var with the result once its done
      const loadEditContent = async () => {
        console.log('PanelPropertyEditableRowValue - loadEditContent - props.editContent: ', props.editContent)
        // NB: need to re-check the prop is a function here as its within a (sub)function & so doesn't know of the previous external check aboev/before this
        if (typeof props.editContent === 'function' && (props.editContent instanceof Function || Object.prototype.toString.call(props.editContent) === "[object Function]" || "function" === typeof props.editContent)) {
          const _dynamicEditContent = await props.editContent()
          console.log('PanelPropertyEditableRowValue - loadEditContent - _dynamicEditContent: ', _dynamicEditContent, ' typeof:', typeof _dynamicEditContent)
          setDynamicEditContent(_dynamicEditContent)
        } else {
          // TOOD: shouldn't ever get here - but fallback handling so it always resolves regardless...
          setDynamicEditContent(<>ERROR: failed to load property input</>) // TOOD: style this <<<<<<<<<<
        }
        // await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
      }
      // trigger the callback function
      loadEditContent()
      // use/render the returned callback content if its ready or show a loading indicator in its place until it does
      editContent = dynamicEditContent ?? 'LOADING...' // TODO: change to a loading indicator? <<<<<
    } else {
      editContent = props.editContent
    }
  }
  return (
    <div
      className={
        styles.value +
        (' ' + styles.editableValue) +
        (props.className ? ' ' + props.className : '')
      }
      style={ props.width ? { flexBasis: props.width } : undefined }
      onClick={() => { if (!isEditing) setIsEditing(true) }}
    >
      {isEditing && (
        <div className={styles.valueEdit}>
          <div className={styles.valueInput}>
            {editContent ?? dynamicEditContent}
          </div>
          <div onClick={async () => {
            if (onSaveEdit) { 
              const saved = await onSaveEdit()
              setIsEditing(!saved, saved)
            } else {
              setIsEditing(false, false)
            }
          }} className={styles.valueEditSave}></div>
          <div onClick={() => { setIsEditing(false, false) }} className={styles.valueEditCancel}></div>
        </div>
      )}
      {!isEditing && (props.children)}
    </div>
  )
}

const PanelPropertyRowValue = (props: PanelPropertyRowValueProps) => {
  if (props.editContent) return <PanelPropertyEditableRowValue {...props} />
  return (
    <div
      className={
        styles.value +
        (props.className ? ' ' + props.className : '')
      }
      style={ props.width ? { flexBasis: props.width } : undefined }
    >
      {props.children}
    </div>
  )
}

const PanelPropertyRowHint = (props: PanelPropertyRowHintProps) => {
  return (<div className={styles.hint + (props.className ? ' ' + props.className : '')} style={ props.width ? { flexBasis: props.width } : undefined }>{props.children}</div>)
}

const PanelPropertyRow = (props: PanelPropertyRowProps) => {
  const { children, hasChanges, className } = props
  return (
    <div className={styles.property + (hasChanges ? ' ' + styles.hasChanges : '') + (className ? ' ' + className : '')}>
      {(props.title || props.value) && (
        <>
          <PanelPropertyRowTitle className={props.titleClassName} width={props.titleWidth}>{props.title}</PanelPropertyRowTitle>
          <PanelPropertyRowValue
            className={props.valueClassName}
            width={props.valueWidth}
            editContent={props.editContent}
            onSaveEdit={props.onSaveEdit}
            onStartEdit={props.onStartEdit}
            onEndEdit={props.onEndEdit}
          >
            {props.value}
          </PanelPropertyRowValue>
          {props.hintWidth && (
            <PanelPropertyRowHint className={props.hintClassName} width={props.hintWidth}>{props.hint}</PanelPropertyRowHint>
          )}
        </>
      )}
      {children}
    </div>
  )
}

const PanelPropertyRowContent = (props: PanelPropertyRowContentProps) => {
  return (<div className={styles.content + (props.className ? ' ' + props.className : '')}>{props.children}</div>)
}

// const PanelPropertyRowMessage = (props: PanelPropertyRowMessageProps) => {
//   const { className, children, ...otherProps } = props
//   return (
//     <div className={styles.message + (className ? ' ' + className : '')}>
//       <Message {...otherProps}>
//         {children}
//       </Message>
//     </div>
//   )
// }

const PanelPropertyDivider = (_props: PanelPropertDividerProps) => {
  return (<div className={styles.propertyDivider}></div>)
}

const PanelPropertySpacer = (props: PanelPropertSpacerProps) => {
  const { large, size, small } = props
  let theSize = 10
  if (small) theSize = 5
  else if (large) theSize = 15
  else if (size !== undefined) theSize = size
  return (<div className={styles.propertySpacer} style={{ margin: `0 ${theSize}px ${theSize}px 0` }}></div>)
}

const PanelPropertySection = (props: PanelPropertySectionProps) => {
  const { title, collapsible, collapsed, children } = props
  const [expanded, setExpanded] = useState<boolean>((collapsible === true && collapsed === true))
  const onClick = () => {
    if (!collapsible) return
    setExpanded(!expanded)
  }
  return (
    <div className={`${styles.section}${collapsible ? ' ' + styles.sectionCollapsible : ''}${!expanded ? ' ' + styles.open : ''}`}>
      <div className={styles.sectionTitle} onClick={onClick}>
        <i className={`${styles.sectionArrow} ${expanded ? styles.sectionArrowClosed : styles.sectionArrowOpen}`} />
        {title}
      </div>
      <div className={`${styles.sectionContent}`}>
        {children}
      </div>
    </div>
  )
}

const PanelProperties = (props: PanelPropertiesProps) => {
  const { children, titleWidth, valueWidth, hintWidth } = props
  // TESTING:
  // if a title/value/hint width are set at this higher level we set them as css vars so the child css can use them
  // if they're not set, the default values are used (hard-coded in the css)
  // NB: this seems to work with multiple properties on a single panel/page, each one uses the values passed into it
  const style = titleWidth || valueWidth || hintWidth
    ? {
      ...(titleWidth !== undefined ? { '--panel-title-width': Number.isInteger(titleWidth) ? titleWidth + 'px' : titleWidth } : {}),
      ...(valueWidth !== undefined ? { '--panel-value-width': Number.isInteger(valueWidth) ? valueWidth + 'px' : valueWidth } : {}),
      ...(hintWidth !== undefined ? { '--panel-hint-width': Number.isInteger(hintWidth) ? hintWidth + 'px' : hintWidth } : {})
    } as React.CSSProperties
    : undefined
  return (
    <div className={styles.propertiesPanel} style={ style }>
      {children}
    </div>
  )
}

export default PanelProperties
export {
  PanelPropertyRow,
  PanelPropertyRowTitle,
  PanelPropertyRowValue,
  PanelPropertyRowHint,
  PanelPropertyRowContent,
  //PanelPropertyRowMessage,
  PanelPropertyDivider,
  PanelPropertySpacer,
  PanelPropertySection
}
