/**
 * Panel
 */

import React, { ReactNode, useState } from 'react'


import PanelProperties, {
  PanelPropertyRow,
  PanelPropertyRowTitle,
  PanelPropertyRowValue,
  PanelPropertyRowHint,
  PanelPropertyRowContent,
  //PanelPropertyRowMessage,
  PanelPropertyDivider,
  PanelPropertySpacer,
  PanelPropertySection
} from './PanelProperties'

import styles from './Panel.module.css'
 
export interface PanelProps {
  title?: string | ReactNode
  children: ReactNode
  collapsible?: boolean
  defaultCollapsed?: boolean
  headerRight?: string | ReactNode
  className?: string
  contentClassName?: string
  height?: number | string
  maxHeight?: number | string // NB: height takes priority if both are set
}

// TESTING sub-components (dot notation) - ref: https://skovy.dev/using-component-dot-notation-with-typescript-to-create-a-set-of-components/
interface PanelSubComponents {
  Properties: typeof PanelProperties,
  PropertyRow: typeof PanelPropertyRow
  PropertyRowTitle: typeof PanelPropertyRowTitle
  PropertyRowValue: typeof PanelPropertyRowValue
  PropertyRowHint: typeof PanelPropertyRowHint
  PropertyRowContent: typeof PanelPropertyRowContent
  //PropertyRowMessage: typeof PanelPropertyRowMessage
  PropertyDivider: typeof PanelPropertyDivider
  PropertySpacer: typeof PanelPropertySpacer
  PropertySection: typeof PanelPropertySection
  //Header: typeof PanelHeader
  //Footer: typeof PanelFooter
}

const Panel: React.FC<PanelProps> & PanelSubComponents =  (props: PanelProps) => {
  const { title, children, collapsible, defaultCollapsed, headerRight, className, contentClassName, height, maxHeight } = props
  
  const [collapsed, setCollapsed] = useState<boolean>(collapsible ? (defaultCollapsed ?? false) : false)

  const onToggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  const addStyles = height !== undefined || maxHeight !== undefined
  const cssStyles = (addStyles ? {
    ...(height !== undefined && !collapsed ? { height: height } : {}),
    ...(maxHeight !== undefined && height === undefined && !collapsed ? { maxHeight: maxHeight } : {})
  } : undefined)

  const collapsibleBtn = collapsible
    ? <span className={`${styles.collapsibleBtn} ${collapsed ? styles.closed : styles.open}`} onClick={onToggleCollapse}><i></i></span>
    : undefined

  return (
    <div className={`${styles.panel} ${collapsed ? styles.closed : styles.open} ${className || ''} panel`}>
      {(title || headerRight) && (
        <div className={styles.header}>
          {collapsible ? collapsibleBtn : null}
          {title && (<h2>{title}</h2>)}
          {headerRight ? (<div className={styles.headerRight}>{headerRight}</div>) : null }
        </div>
      )}
      <div className={`${styles.content} ${contentClassName}`} style={cssStyles}>
        {children}
      </div>
    </div>
  )
}

Panel.Properties = PanelProperties
Panel.PropertyRow = PanelPropertyRow
Panel.PropertyRowTitle = PanelPropertyRowTitle
Panel.PropertyRowValue = PanelPropertyRowValue
Panel.PropertyRowHint = PanelPropertyRowHint
Panel.PropertyRowContent = PanelPropertyRowContent
//Panel.PropertyRowMessage = PanelPropertyRowMessage
Panel.PropertyDivider = PanelPropertyDivider
Panel.PropertySpacer = PanelPropertySpacer
Panel.PropertySection = PanelPropertySection
//Panel.Header = PanelHeader
//Panel.Footer = PanelFooter

export default Panel
