export enum ChatMessageRole {
  system = "system",
  user = "user",
  assistant = "assistant"
}

export interface ChatMessage {
  role: ChatMessageRole
  content: string
}
