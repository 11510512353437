import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'

import { User, UserInviteLookup, UserResetPassLookup } from '../models'
import { ServerAPIClient, ServerAuthAPI } from '../services'
import { ServerAPIError } from '../services/ServerAPIClient'

export enum AuthStatus {
  loading, loggedIn, loggedOut // TODO: what should the auth status be set to if we hit errors, loggedOut, or is it worth (re)adding a specific .error status? (which would imply being logged out?)
}

export interface IDecodedInviteToken { email?: string, iat?: number, exp?: number }
//export interface IInviteTokenLookupResult  { email?: string }

interface IAuthStore {
  // auth
  authStatus?: AuthStatus
  authUpdated?: Date
  authError?: Error
  // cache (used during multi stage login & registration handling, as well as invites)
  // cacheType?: 'login' | 'register'
  // cacheEmail?: string
  // cacheName?: { firstName?: string, lastName?: string }
}

interface IAuthActions {
  // -------
  isLoggedIn: () => boolean
  isLoggedInAndVerified: () => boolean
  // getAuthToken: () => string | undefined
  // getAuthDeviceUUID: () => string | undefined
  //resetAuthError: () => void
  // -------
  registerUserWithEmailAndPassword: (name: string, email: string, password: string, confirmPassword: string) => Promise<void>
  // -------
  checkInviteToken: (inviteToken: string) => Promise<UserInviteLookup>
  acceptInvite: (name: string, password: string, confirmPassword: string, inviteToken: string) => Promise<void>
  // -------
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>
  logout: () => void
  // -------
  forgotEmailPassword: (email: string) => Promise<boolean>
  checkForgotPassToken: (resetToken: string) => Promise<UserResetPassLookup>
  resetEmailPassword: (resetToken: string, newPassword: string, confirmPassword: string) => Promise<boolean>
  // -------
  changePassword: (oldPass: string, newPass: string) => Promise<void>
  // -------
  // clearAuthError: () => void
  // -------
  resendVerifyEmail: () => Promise<boolean>
  verifyEmailToken: (verifyToken: string) => Promise<boolean>
  // -------
  decodeInviteToken: (token: string) => IDecodedInviteToken
  // -------
}

interface IAuthContext {
  actions: IAuthActions
  store: IAuthStore
}

interface AuthProviderProps {
  apiClient: ServerAPIClient
  authApi: ServerAuthAPI
  children: ReactNode
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext)

// TESTING: ref: https://stackoverflow.com/a/56767883
//const useMountEffect = (run: React.EffectCallback) => useEffect(run, [])

const AuthProvider = (props: AuthProviderProps) => {
  const { apiClient, authApi, children } = props

  const [authStatus, setAuthStatus] = useState<AuthStatus>()
  const [authUpdated, setAuthUpdated] = useState<Date>()
  const [authError, setAuthError] = useState<Error>()

  // -------

  // checks if a user auth token is cached & re-validates it to check the login status
  // NB: see onAuthChange for success handling
  // NB: the ServerAuthAPI initLoggedInUser call will trigger the onAuthChange on success
  // NB: or trigger a logout if it fails, if the user wasn't logged in (no user object in the local storage) it'll do nothing
  const load = useCallback(async () => {
    console.log('AuthProvider - load')
    try {
      setAuthStatus(AuthStatus.loading)
      setAuthError(undefined)
      const user = await authApi.initLoggedInUser()
      setAuthStatus(user ? AuthStatus.loggedIn : AuthStatus.loggedOut)
      setAuthUpdated(new Date())
    } catch (error) {
      console.error('AuthProvider - load - error: ', error)
      setAuthStatus(AuthStatus.loggedOut)
      setAuthUpdated(new Date())
      setAuthError(error as Error)
    }
  }, [authApi])

  useEffect(() => {
    console.log('AuthProvider - init')
    load()
  }, [load])

  // init - authApi - register for authApi callbacks
  const onAuthChange = (user?: User) => {
    const prevAuthStatus = authStatus
    console.log('AuthProvider - onAuthChange - user:', user, 'prevAuthStatus:', prevAuthStatus)
    if (user && prevAuthStatus !== AuthStatus.loggedIn) {
      console.log('AuthProvider - onAuthChange - LOGIN')
      setAuthStatus(AuthStatus.loggedIn)
      setAuthUpdated(new Date())
      setAuthError(undefined)
    } else if (user && prevAuthStatus === AuthStatus.loggedIn) {
      console.log('AuthProvider - onAuthChange - UPDATE (LOGGED IN)')
      setAuthStatus(AuthStatus.loggedIn)
      setAuthUpdated(new Date())
      setAuthError(undefined)
    } else if (!user && prevAuthStatus !== AuthStatus.loggedOut) {
      console.log('AuthProvider - onAuthChange - LOGOUT')
      setAuthStatus(AuthStatus.loggedOut)
      setAuthUpdated(new Date())
      setAuthError(undefined)
    } else if (!user && prevAuthStatus === AuthStatus.loggedOut) {
      console.log('AuthProvider - onAuthChange - UPDATE (LOGGED OUT)')
      setAuthStatus(AuthStatus.loggedOut)
      setAuthUpdated(new Date())
      setAuthError(undefined)
    }
  }
  // const onAuthChange = useCallback((user?: User) => {
  //   console.log('AuthProvider - onAuthChange - user:', user, 'authStatus(BEFORE):', authStatus)
  // }, [authStatus])
  useEffect(() => {
    console.log('AuthProvider - init - authApi')
    // const onAuthChange = (user?: User) => {
    //   console.log('AuthProvider - onAuthChange - user:', user, 'authStatus(BEFORE):', authStatus)
    // }
    authApi.addDelegate(onAuthChange)
  // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authApi])

  // -------

  const isLoggedIn = () =>
    props.authApi.isLoggedIn()

  const isLoggedInAndVerified = () => {
    const user = props.authApi.authUser
    return !!(props.authApi.isLoggedIn() && user && user?.isVerified)
  }

  // TESTING: allow certain calling code to reset this (e.g. the forgot password reset form if it triggered an error & then the user edits the password to retry, we need to clear this first...)
  // UPDATE: not currently needed, worked around the issue without the need for this for now
  // const resetAuthError = () => {
  //   setAuthError(undefined)
  // }

  // -------

  const registerUserWithEmailAndPassword = async (name: string, email: string, password: string, confirmPassword: string) => {
    try {
      setAuthStatus(AuthStatus.loading)
      setAuthError(undefined)
      // basic local validation (the server side does more thorough validation)
      if (!name || name.trim().length === 0) {
        return setAuthError(new ServerAPIError('Name is required'))
      } else if (!email || email.trim().length === 0) {
        return setAuthError(new ServerAPIError('Email is required'))
      } else if (!password || password.trim().length === 0) {
        return setAuthError(new ServerAPIError('Password is required'))
      } else if (!confirmPassword || confirmPassword.trim().length === 0) {
        return setAuthError(new ServerAPIError('Confirm password is required'))
      } else if (password !== confirmPassword) {
        return setAuthError(new ServerAPIError('Passwords must match'))
      }
      // onAuthChange will fire on success & update the auth status
      await authApi.registerUserWithEmailAndPassword(email, password, name)
      // NB: onAuthChange will fire on success & update the auth status etc.
    } catch (error) {
      console.error('AuthProvider - registerUserWithEmailAndPassword - error: ', error)
      setAuthStatus(AuthStatus.loggedOut)
      setAuthUpdated(new Date())
      setAuthError(error as Error)
    }
  }

  // -------

  const checkInviteToken = async (inviteToken: string) =>
    props.authApi.checkInviteToken(inviteToken)

  const acceptInvite = async (name: string, password: string, confirmPassword: string, inviteToken: string) => {
    try {
      setAuthStatus(AuthStatus.loading)
      setAuthError(undefined)
      // basic local validation (the server side does more thorough validation)
      if (!name || name.trim().length === 0) {
        return setAuthError(new ServerAPIError('Name is required'))
      // } else if (!email || email.trim().length === 0) {
      //   return setAuthError(new ServerAPIError('Email is required'))
      } else if (!password || password.trim().length === 0) {
        return setAuthError(new ServerAPIError('Password is required'))
      } else if (!confirmPassword || confirmPassword.trim().length === 0) {
        return setAuthError(new ServerAPIError('Confirm password is required'))
      } else if (password !== confirmPassword) {
        return setAuthError(new ServerAPIError('Passwords must match'))
      }
      // onAuthChange will fire on success & update the auth status
      await props.authApi.acceptInvite(inviteToken, password, name)
      // NB: onAuthChange will fire on success & update the auth status etc.
    } catch (error) {
      console.error('AuthProvider - acceptInvite - error: ', error)
      setAuthStatus(AuthStatus.loggedOut)
      setAuthUpdated(new Date())
      setAuthError(error as Error)
    }
  }

  // -------

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      setAuthStatus(AuthStatus.loading)
      setAuthError(undefined)
      // basic local validation (the server side does more thorough validation)
      if (!email || email.trim().length === 0) {
        return setAuthError(new ServerAPIError('Email is required'))
      } else if (!password || password.trim().length === 0) {
        return setAuthError(new ServerAPIError('Password is required'))
      }
      // onAuthChange will fire on success & update the auth status
      const user = await authApi.loginUserWithEmailAndPassword(email, password)
      console.log('AuthProvider - loginWithEmailAndPassword - user: ', user)
    } catch (error) {
      console.error('AuthProvider - loginWithEmailAndPassword - error: ', error)
      setAuthStatus(AuthStatus.loggedOut)
      setAuthUpdated(new Date())
      setAuthError(error as Error)
    }
  }

  const logout = () => {
    console.log('AuthProvider - logout')
    authApi.logout()
    // NB: no result or errors to handle on logout, onAuthChange will fire with the change & trigger the auth status update
  }

  // -------

  const forgotEmailPassword = async (email: string): Promise<boolean> => {
    try {
      // TODO: update other state vars like status?
      setAuthError(undefined)
      return await props.authApi.forgotEmailPassword(email)
    } catch (error) {
      console.error('AuthProvider - forgotEmailPassword - error: ', error)
      setAuthError(error as Error)
      throw error
    }
  }

  const checkForgotPassToken = async (resetToken: string): Promise<UserResetPassLookup> =>
    props.authApi.checkForgotPassToken(resetToken)

  const resetEmailPassword = async (resetToken: string, newPassword: string, confirmPassword: string) => {
    try {
      // TODO: update other state vars like status?
      setAuthError(undefined)
      if (!newPassword || newPassword.trim().length === 0) {
        setAuthError(new ServerAPIError('Password is required'))
        return false
      } else if (!confirmPassword || confirmPassword.trim().length === 0) {
        setAuthError(new ServerAPIError('Confirm password is required'))
        return false
      } else if (newPassword !== confirmPassword) {
        setAuthError(new ServerAPIError('Passwords must match'))
        return false
      }
      return await props.authApi.resetEmailPassword(resetToken, newPassword)
    } catch (error) {
      console.error('AuthProvider - resetEmailPassword - error: ', error)
      setAuthError(error as Error)
      throw error
    }
  }

  // -------

  const changePassword = async (oldPass: string, newPass: string): Promise<void> => {
    console.log('AuthProvider - changePassword')
    try {
      await authApi.changePassword(oldPass, newPass)
      console.log('AuthProvider - changePassword - ok')
    } catch (error) {
      console.error('AuthProvider - changePassword - error: ', error)
      // TODO: how to handle errors here.... throw & require calling code to handle, or update local state of some sort & feed it back that way??
    }
  }

  // -------

  const resendVerifyEmail = async () =>
    props.authApi.resendVerifyEmail()

  const verifyEmailToken = async (verifyToken: string) =>
    props.authApi.verifyEmailToken(verifyToken)

  // -------

  const decodeInviteToken = (inviteToken: string) => {
    try {
      const decodedToken = jwtDecode(inviteToken)
      return decodedToken as IDecodedInviteToken
    } catch (error: any) {
      throw error
    }
  }

  // -------

  const actions: IAuthActions = {
    // -------
    isLoggedIn,
    isLoggedInAndVerified,
    //resetAuthError,
    // -------
    registerUserWithEmailAndPassword,
    // -------
    checkInviteToken,
    acceptInvite,
    // -------
    loginWithEmailAndPassword,
    logout,
    // -------
    forgotEmailPassword,
    checkForgotPassToken,
    resetEmailPassword,
    // -------
    changePassword,
    // -------
    resendVerifyEmail,
    verifyEmailToken,
    // -------
    decodeInviteToken
    // -------
  }

  const store: IAuthStore = {
    authStatus,
    authUpdated,
    authError
  }

  return (
    <AuthContext.Provider value={{ actions, store }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
