/**
 * Button
 * 
 * refs:
 *  https://gist.github.com/84961/89cc1e76ae3a70f10b1734b3ed81fd88 # Button With Loading Spinner
 * 
 * TODO: lots of button animation examples: https://www.sliderrevolution.com/resources/css-button-hover-effects/
 */
import React from 'react'
import Icon, { IconName } from '../Icon/Icon'

import styles from './Button.module.css'

export interface ButtonProps {
  children?: React.ReactNode // NB: now optional as you might just use an icon instead (but one or the other should always be used)
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  fluid?: boolean
  loading?: boolean
  slim?: boolean
  iconName?: IconName
  iconSize?: number
  iconColor?: string
  primary?: boolean
  secondary?: boolean
  type?: 'submit' | 'reset' | 'button'
  error?: boolean,
  autoFocus?: boolean,
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>
}

const Button = (props: ButtonProps) => {
  const {
    children,
    className,
    disabled,
    fluid,
    loading,
    slim,
    onClick,
    iconName,
    iconSize,
    iconColor,
    primary,
    secondary,
    type,
    error,
    autoFocus,
    onKeyDown
  } = props
  return (
    <button
      type={type}
      onClick={onClick}
      className={
        (styles.btn) +
        (className ? ' ' + className : '') +
        (fluid ? ' ' + styles.fluid : '') +
        (loading ? ' ' + styles.loading : '') +
        (slim ? ' ' + styles.slim : '') +
        (primary ? ' ' + styles.primary : '') +
        (secondary ? ' ' + styles.secondary : '') +
        (error ? ' ' + styles.error : '') +
        (disabled ? ' ' + styles.disabled : '')
      }
      disabled={disabled}
      autoFocus={autoFocus}
      onKeyDown={onKeyDown}
    >
      {iconName && (<Icon name={iconName} size={iconSize} color={iconColor ?? '#000000'} />)}
      <span className={styles.txt}>{children}</span>
    </button>
  )
}

export default Button
