import React, { createContext, ReactNode } from 'react'
import ServerAPIClient from '../services/ServerAPIClient'
import ServerAPI from '../services/ServerAPI'
import { ServerAuthAPI } from '../services'
import OpenAIAPIClient from 'src/services/OpenAIAPIClient'

interface IServerActions {
}

interface IServerStore {
  apiClient: ServerAPIClient
  serverAPI: ServerAPI
  authApi: ServerAuthAPI
  openAIApiClient?: OpenAIAPIClient
}

interface IServerContext {
  actions: IServerActions
  store: IServerStore
}

interface ServerProviderProps {
  apiClient: ServerAPIClient
  serverAPI: ServerAPI
  authApi: ServerAuthAPI
  openAIApiClient?: OpenAIAPIClient
  children: ReactNode
}

export const ServerContext = createContext<IServerContext>({} as IServerContext)

const ServerProvider = (props: ServerProviderProps) => {
  const { apiClient, serverAPI, authApi, openAIApiClient, children } = props

  const actions: IServerActions = {
  }

  const store: IServerStore = {
    apiClient: apiClient,
    serverAPI: serverAPI,
    authApi: authApi,
    openAIApiClient: openAIApiClient
  }

  return (
    <ServerContext.Provider value={{ actions, store }}>
      {children}
    </ServerContext.Provider>
  )
}

export default ServerProvider
