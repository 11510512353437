import React, { useState, useCallback, useRef, useEffect } from 'react'
import ReactFlow, {
  // useNodesState,
  // useEdgesState,
  addEdge,
  updateEdge,
  applyNodeChanges,
  applyEdgeChanges,
  FitViewOptions,
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  Connection,
  Controls,
  Background,
  BackgroundVariant,
  Panel,
  Viewport,
} from 'reactflow'

import UserInputNode from './nodes/UserInputNode'
import ButtonEdge from './edges/ButtonEdge'
import UserInputSidebar from './sidebars/UserInputSidebar'

import styles from './NodePlaygroundView.module.css'
import 'reactflow/dist/style.css'

const initialNodes: Node[] = [
  {
    id: '1',
    type: 'input',
    data: { label: 'Start Node' },
    position: { x: 0, y: 0 }
  },
  {
    id: '2',
    type: 'userInputNode',
    data: { input: 'Dummy text here...' },
    position: { x: 25, y: 100 }
  },
  // {
  //   id: '2',
  //   type: 'userInputNode',
  //   // data: { label: 'Node 2' },
  //   data: {
  //     onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
  //       const color = event.currentTarget.value
  //       console.log('userInputNode - onChange - color:', color)
  //     },
  //     // color: '#1A192B'
  //     input: 'Some longer default dummy text to test out the input cropping & display handling. Some longer default dummy text to test out the input cropping & display handling.'
  //   },
  //   position: { x: -150, y: 120 }
  // },
  // {
  //   id: '3',
  //   data: { label: 'Node 3' },
  //   position: { x: 0, y: 120 }
  // },
  // {
  //   id: '4',
  //   data: { label: 'Node 4' },
  //   position: { x: 150, y: 120 }
  // },
]

const initialEdges: Edge[] = [
  {
    id: 'e1-2',
    source: '1',
    target: '2',
    // label: 'edge 1',
    // type: 'buttonedge',
  },
  // {
  //   id: 'e1-4',
  //   source: '1',
  //   target: '4',
  //   // label: 'edge 2',
  //   // type: 'buttonedge',
  // }
]

const nodeTypes = {
  userInputNode: UserInputNode,
}
const edgeTypes = {
  buttonedge: ButtonEdge,
}

const defaultViewport: Viewport = {
  x: 0,
  y: 0,
  zoom: 1.0 // NB: do the FitViewOptions override this?
}

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
}

const proOptions = { hideAttribution: true }

const NodePlaygroundView = () => {
  const edgeUpdateSuccessful = useRef(true)

  const [nodes, setNodes] = useState<Node[]>(initialNodes)
  const [edges, setEdges] = useState<Edge[]>(initialEdges)
  const [variant, setVariant] = useState<BackgroundVariant>(BackgroundVariant.Dots)

  const [selectedNode, setSelectedNode] = useState<Node | undefined>()

  const onNodesChange = useCallback(
    // (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
    (changes: NodeChange[]) => {
      console.log('NodePlaygroundView - onNodesChange - changes:', changes, ' nodes:', nodes)
      
      // TESTING:
      const currentSelectedNode = nodes.find(n => n.selected)
      const selectNode = changes.find(c => c.type === 'select' && c.selected === true)
      const deselectNode = changes.find(c => c.type === 'select' && c.selected === false)
      console.log('NodePlaygroundView - onNodesChange - currentSelectedNode:', currentSelectedNode, ' selectNode:', selectNode, ' deselectNode:', deselectNode)

      if (currentSelectedNode && selectNode && !deselectNode) {
        console.log('NodePlaygroundView - onNodesChange - MULTI-SELECT BUG TRIGGERED...')
        changes.push({ id: currentSelectedNode.id, type: 'select', selected: false })
      }
      
      setNodes((nds) => applyNodeChanges(changes, nds))
    },
    [setNodes, nodes]
  )
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  )
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false
  }, [])
  // gets called after end of edge gets dragged to another source or target
  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => setEdges((els) => updateEdge(oldEdge, newConnection, els)),
    []
  )
  const onEdgeUpdateEnd = useCallback((_event: MouseEvent | TouchEvent, edge: Edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id))
    }
    edgeUpdateSuccessful.current = true
  }, [])
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge({...connection /*, type: 'buttonedge' */ }, eds)),
    [setEdges]
  )

  // const onNodeClick =  useCallback(
  //   (event: React.MouseEvent, node: Node) => {
  //     console.log('NodePlaygroundView - onNodeClick - node:', node)
  //   }
  //   , [])

  const addNode = () => {
    // TODO: add a better way to generate unique node ids...
    let nextNodeId = 1
    if (nodes.length > 0) {
      let nodeIdValid = false
      let loopCount = 0
      while (!nodeIdValid && loopCount < 1000) {
        if (nodes.find(n => n.id === `${nextNodeId}`) === undefined) {
          nodeIdValid = true
        } else {
          nextNodeId++
        }
        loopCount++
      }
    }
    const node:Node = {
      id: `${nextNodeId}`,
      type: 'userInputNode',
      data: { label: 'Node ' + nextNodeId },
      position: { x: 5 + (nextNodeId * 10), y: 5 + (nextNodeId * 10) } // NB: offset the position a little each time so it doesn't sit directly on previously added ones (if they haven't moved) - TODO: ideally make this more inteligent...
    }
    setNodes((nds) => [...nds, node])
  }

  // node selection
  // NB: only supporting a single selection for now (although partially coded with possible multi-seelct in mind)
  useEffect(() => {
    console.log('NodePlaygroundView - useEffect - nodes:', nodes)
    const selectedNodes: Array<Node> = []
    for (const node of nodes) {
      if (node.selected) {
        selectedNodes.push(node)
      }
    }
    // console.log('NodePlaygroundView - useEffect - nodes - selectedNodes:', selectedNodes)
    if (selectedNodes.length > 0) {
      const _selectedNode = selectedNodes[0] // NB: only supporting a single node
      if (!selectedNode || (selectedNode && selectedNode.id !== _selectedNode.id)) {
        console.log('NodePlaygroundView - useEffect - nodes - _selectedNode:', _selectedNode)
        setSelectedNode(_selectedNode)
      }
    } else {
      if (selectedNode) {
        console.log('NodePlaygroundView - useEffect - nodes - selectedNode - deselect...')
        setSelectedNode(undefined)
      }
    }
  }, [nodes, selectedNode])

  const onInputTextChange = (node: Node, input: string) => {
    console.log('NodePlaygroundView - onInputTextChange - node:', node, ' input:', input)
    setNodes((nds) => nds.map((nd) => {
      if (nd.id === node.id) {
        nd.data = { ...nd.data, input }
      }
      return nd
    }))
  }

  return (
    <div className={styles.nodePlayground}>
      <div className={styles.nodeCanvas}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          onConnect={onConnect}
          // onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          defaultViewport={defaultViewport}
          fitView
          fitViewOptions={fitViewOptions}
          proOptions={proOptions}
        >
          <Background color="#99b3ec" variant={variant} />
          <Controls />
          <Panel position={'bottom-right'}>
            <div>background:</div>
            <button onClick={() => setVariant(BackgroundVariant.Dots)}>dots</button>
            <button onClick={() => setVariant(BackgroundVariant.Lines)}>lines</button>
            <button onClick={() => setVariant(BackgroundVariant.Cross)}>cross</button>
          </Panel>
          <Panel position={'top-right'}>
            <button onClick={addNode}>ADD</button>
          </Panel>
        </ReactFlow>
      </div>
      <div className={styles.nodeSidebar}>
        <div className={styles.content}>
          <UserInputSidebar
            node={selectedNode}
            onInputTextChange={onInputTextChange}
          />
        </div>
      </div>
    </div>
  )
}

export default NodePlaygroundView
