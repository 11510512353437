import React from 'react'
import { Link } from 'react-router-dom'

import BasePage from '../../../components/BasePage/BasePage'

import * as ROUTES from '../../../constants/routes'

import styles from './AdminMenuPage.module.css'

const AdminMenuPage = () => {
  return (
    <BasePage
      pageTitle='Admin'
      breakcrumb={[<Link to={ROUTES.HOME}>Home</Link>]}
    >
      <div className={styles.admin}>
        <ul>
          <li><Link to={ROUTES.ADMIN_USERS}>Users</Link></li>
        </ul>
      </div>
    </BasePage>
  )
}

export default AdminMenuPage
