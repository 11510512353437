import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { ServerAdminContext } from '../../../providers'

import BasePage from '../../../components/BasePage/BasePage'
import CenterLayout from '../../../components/CenterLayout'

import * as ROUTES from '../../../constants/routes'

import styles from './AdminUsersInvitePage.module.css'

const AdminUsersInvitePage = () => {

  const { actions: adminActions } = useContext(ServerAdminContext)

  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>()

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      setLoading(true)
      setResult(undefined)
      setError(undefined)
      await adminActions.inviteUser(email)
      setResult(true)
      setLoading(false)
    } catch (error: any) {
      setError(error)
      setResult(false)
      setLoading(false)
    }
  }

  return (
    <BasePage
      pageTitle='Invite User'
      breakcrumb={[
        <Link to={ROUTES.HOME}>Home</Link>,
        <Link to={ROUTES.ADMIN}>Admin</Link>,
        <Link to={ROUTES.ADMIN_USERS}>Users</Link>
      ]}
    >
      <CenterLayout>
        <div className={styles.page}>
          <form className={styles.form + (loading ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
            <h1>Invite User</h1>
            {error && (
              <div className={styles.errMsg}>ERROR: {error.message}</div>
            )}
            {result && (
              <div className={styles.successMsg}>Invite sent</div>
            )}
            <div className={styles.field}>
              <label htmlFor="email">Email:</label>
              <div className={styles.input}>
                <input type="text" value={email} id="email" name="email" autoComplete="email" onChange={(event) => { setEmail(event.target.value) }} />
              </div>
            </div>
            <div className={styles.buttons}>
              <div className={styles.field + ' ' + styles.button}>
                <input type="submit" value="Invite User" disabled={loading} />
              </div>
            </div>
          </form>
        </div>
      </CenterLayout>
    </BasePage>
  )
}

export default AdminUsersInvitePage
