import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ServerAdminContext } from '../../../providers'
import { User, UserInvite, UserInviteStatus } from '../../../models'

import BasePage from '../../../components/BasePage/BasePage'

import * as ROUTES from '../../../constants/routes'

import CenterLayout from '../../../components/CenterLayout'
import Button from '../../../components/Button'
import AdminUserRolesView from './AdminUserRolesView'

import styles from './AdminUsersPage.module.css'

const AdminUsersPage = () => {

  const { actions: adminActions } = useContext(ServerAdminContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>() // TODO: split into user * invite specific error vars?

  const [users, setUsers] = useState<Array<User>>()
  const [usersActionStatus, setUsersActionStatus] = useState<Map<string, any>>(new Map())
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>()

  const [userInvites, setUserInvites] = useState<Array<UserInvite>>()
  const [resendInvites, setResendInvites] = useState<Map<string, any>>(new Map())
  const [selectedInviteId, setSelectedInviteId] = useState<string | undefined>()

  const loadUsers = async () => {
    try {
      setLoading(true)
      setError(undefined)
      const _users = await adminActions.getUsers()
      console.log('AdminUsersPage - loadUsers - users:', users)
      setUsers(_users)
      setLoading(false)
    } catch (error: any) {
      setError(error)
      setLoading(false)
    }
  }

  const loadUserInvites = async () => {
    try {
      setLoading(true)
      setError(undefined)
      const _userInvites = await adminActions.getUserInvites()
      console.log('AdminUsersPage - loadUserInvites - _userInvites:', _userInvites)
      setUserInvites(_userInvites)
      setLoading(false)
    } catch (error: any) {
      setError(error)
      setLoading(false)
    }
  }

  const loadData = async () => {
    await loadUsers()
    if (!error) await loadUserInvites()
  }

  useEffect(() => {
      loadData()
    },
    // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onSelectUser = async (id: string) => {
    console.log('AdminUsersPage - onSelectUser - id:', id)
    setSelectedUserId(id)
    setSelectedInviteId(undefined)
  }

  const onSelectInvite = async (id: string) => {
    console.log('AdminUsersPage - onSelectInvite - id:', id)
    setSelectedInviteId(id)
    setSelectedUserId(undefined)
  }

  const onUpdateUserRoles = async (userId: string, roles: Array<string>) => {
    console.log('AdminUsersPage - onUpdateUserRoles - userId:', userId, 'roles:', roles)
    // TODO: ...
    try {
      // NB: updated an ES6 Map state var - ref: https://medium.com/swlh/using-es6-map-with-react-state-hooks-800b91eedd5f
      setUsersActionStatus(new Map(usersActionStatus.set(userId, { updating: true, updated: undefined, error: undefined })))
      //await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
      //throw new Error('DEBUG ERROR') // DEBUG ONLY
      await adminActions.updateUserRoles(userId, roles)
      setUsersActionStatus(new Map(usersActionStatus.set(userId, { updating: false, updated: true })))
      // TESTING: update the user object with the roles (instead of re-loading all users)
      if (users) {
        const newUsers = users.map(user => {
          if (user.id === userId) {
            user.roles = roles
            return user
          }
          return user
        })
        setUsers(newUsers)
      }
    } catch (error: any) {
      console.error('AdminUsersPage - onUpdateUserRoles - error:', error)
      setUsersActionStatus(new Map(usersActionStatus.set(userId, { updating: false, updateError: error })))
    }
  }

  const onDeleteUser = async (userId: string) => {
    console.log('AdminUsersPage - onDeleteUser - userId:', userId)
    if (!window.confirm('Delete User?')) {
      return
    }
    console.log('AdminUsersPage - onDeleteUser...')
    try {
      // NB: updated an ES6 Map state var - ref: https://medium.com/swlh/using-es6-map-with-react-state-hooks-800b91eedd5f
      setUsersActionStatus(new Map(usersActionStatus.set(userId, { deleting: true, deleteError: undefined })))
      //await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
      //throw new Error('DEBUG ERROR') // DEBUG ONLY
      await adminActions.deleteUser(userId)
      setUsersActionStatus(new Map(usersActionStatus.set(userId, { deleting: false, deleted: true })))
    } catch (error: any) {
      console.error('AdminUsersPage - onDeleteUser - error:', error)
      setUsersActionStatus(new Map(usersActionStatus.set(userId, { deleting: false, deleteError: error })))
    }
  }

  const onResendInvite = async (email: string) => {
    console.log('AdminUsersPage - onResendInvite - email:', email)
    // NB: updated an ES6 Map state var - ref: https://medium.com/swlh/using-es6-map-with-react-state-hooks-800b91eedd5f
    setResendInvites(new Map(resendInvites.set(email, { loading: true })))
    //await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
    try {
      await adminActions.resendUserInvite(email)
      //throw new Error('DEBUG ERROR')
      setResendInvites(new Map(resendInvites.set(email, { loading: false, sent: true })))
    } catch (error: any) {
      setResendInvites(new Map(resendInvites.set(email, { loading: false, error })))
    }
  }

  const onCancelInvite = async (email: string) => {
    console.log('AdminUsersPage - onCancelInvite - email:', email)
    if (!window.confirm('Cancel User Invite for ' + email + '?')) {
      return
    }
    try {
      await adminActions.cancelUserInvite(email)
    } catch (error: any) {
      console.error('AdminUsersPage - onCancelInvite - error:', error)
      // TODO: ...
    }
  }

  // user/invite selection
  const selectedUser: User | undefined = (selectedUserId && users) ? users.find(u => u.id === selectedUserId) : undefined
  const selectedInvite: UserInvite | undefined = (selectedInviteId && userInvites) ? userInvites.find(ui => ui.id === selectedInviteId) : undefined
  const showSidepanel = selectedUser || selectedInvite
  
  const showDeleteUserAction = !!selectedUser // TODO: only certain users can be deleted, e.g. not the site/initial admin or your own user?
  
  const showCancelInviteAction = selectedInvite && (selectedInvite.status === UserInviteStatus.created || selectedInvite.status === UserInviteStatus.sent)
  const showResendInviteAction = selectedInvite && (selectedInvite.status === UserInviteStatus.created || selectedInvite.status === UserInviteStatus.sent)
  const isResendingInvite = selectedInvite && resendInvites.get(selectedInvite.email)?.loading ? true : false

  const selectedUserActionStatus = selectedUser ? usersActionStatus.get(selectedUser.id) : undefined
  const selectedUserUpdating = selectedUserActionStatus?.updating ? true : false
  const selectedUserUpdated = selectedUserActionStatus?.updated ? true : false
  const selectedUserUpdateError = selectedUserActionStatus?.updateError
  const selectedUserDeleting = selectedUserActionStatus?.deleting ? true : false
  const selectedUserDeleted = selectedUserActionStatus?.deleted ? true : false
  const selectedUserDeleteError = selectedUserActionStatus?.deleteError
  console.log('AdminUsersPage - selectedUserActionStatus:', selectedUserActionStatus, ' selectedUserDeleted:', selectedUserDeleted)

  return (
    <BasePage
      pageTitle='Users'
      breakcrumb={[
        <Link to={ROUTES.HOME}>Home</Link>,
        <Link to={ROUTES.ADMIN}>Admin</Link>
      ]}
    >
      <div className={styles.adminUsers}>
        <CenterLayout>
          {loading && (<>Loading...</>)}
          {!loading && error && (
            <div>
              ERROR: {error.message}
            </div>
          )}
          {!loading && !error && (
            <>

              <h2>Users</h2>
              {users && users.length > 0 && (
                <table className={styles.users}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Roles</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((user) => {
                      const userActionStatus = usersActionStatus.get(user.id)
                      const deleting = userActionStatus?.deleting ? true : false
                      const deleted = userActionStatus?.deleted ? true : false
                      const deleteError = userActionStatus?.deleteError
                      const updating = userActionStatus?.updating ? true : false
                      const updated = userActionStatus?.updated ? true : false
                      const updateError = userActionStatus?.updateError
                      if (userActionStatus) console.log('AdminUsersPage - user.id:', user.id, ' userActionStatus:', userActionStatus)
                      return (
                        <tr key={user.id} onClick={() => { onSelectUser(user.id) }} className={(selectedUserId && selectedUserId === user.id ? styles.selected : '') + (deleted ? ' ' + styles.deleted : '')}>
                          {/* <td>{user.id}</td> */}
                          <td className={styles.name}>{user.name}</td>
                          <td className={styles.email}>{user.email}</td>
                          <td>{(deleted ? 'DELETED! was: ' : '') + (user.isVerified ? 'Verified' : 'Unverified')}</td>
                          <td>{user.roles && user.roles.length > 0 ? user.roles.join(', ') : '-'}</td>
                          <td className={styles.actions}>
                            {updating && (<div>UPDATING...</div>)}
                            {updated && (<div className={styles.successMsg}>User Updated!</div>)}
                            {updateError && (<div className={styles.errMsg}>UPDATE ERROR: {updateError.message}</div>)}
                            {deleting && (<div>DELETING...</div>)}
                            {deleted && (<div className={styles.successMsg}>User Deleted!</div>)}
                            {deleteError && (<div className={styles.errMsg}>DELETE ERROR: {deleteError.message}</div>)}
                            {/* {!(deleting || deleted || deleteError) && (<></>)} */}
                            {/* {JSON.stringify(userActionStatus)} */}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
              {(!users || users.length === 0) && (
                <div>No users(??)</div>
              )}

              <br />

              <h2>User Invites</h2>
              <div>
                <Link to={ROUTES.ADMIN_USERS_INVITE}>Invite User</Link>
              </div>
              {userInvites && userInvites.length > 0 && (
                <table  className={styles.userInvites}>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th>Updated</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userInvites?.map((userInvite) => {
                      const resendInviteData = resendInvites.get(userInvite.email)
                      const resendingInvite = resendInviteData?.loading ? true : false
                      const resendSuccess = resendInviteData?.sent ? true : false
                      const resendingError = resendInviteData?.error
                      console.log('AdminUsersPage - userInvite.email:', userInvite.email, ' resendInviteData:', resendInviteData, ' resendingInvite:', resendingInvite)
                      return (
                        <tr key={userInvite.email} onClick={() => { onSelectInvite(userInvite.id) }} className={selectedInviteId && selectedInviteId === userInvite.id ? styles.selected : ''}>
                          {/* <td>{userInvite.id}</td> */}
                          <td>{userInvite.email}</td>
                          <td>{UserInviteStatus[userInvite.status]}</td>
                          <td>{userInvite.createdAt.toLocaleString()}</td>
                          <td>{userInvite.updatedAt?.toLocaleString() ?? '-'}</td>
                          <td className={styles.actions}>
                            {(userInvite.status === UserInviteStatus.created || userInvite.status === UserInviteStatus.sent) && (
                              <Button onClick={() => { onResendInvite(userInvite.email) }} disabled={resendingInvite} loading={resendingInvite}>RE-SEND</Button>
                            )}
                            {resendSuccess && (<div className={styles.successMsg}>Invite re-sent</div>)}
                            {resendingError && (<div className={styles.errMsg}>ERROR: {resendingError.message}</div>)}
                            {!(userInvite.status === UserInviteStatus.created || userInvite.status === UserInviteStatus.sent) && (
                              <>-</>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
              {(!userInvites || userInvites.length === 0) && (
                <div>No active invites</div>
              )}
            </>
          )}
          {showSidepanel && (
            <div className={styles.sidepanel}>
              {selectedUser && (
                <div className={selectedUserDeleted ? styles.deleted : ''}>
                  <h2>User: {selectedUser.name ?? selectedUser.email}</h2>
                  <div className={styles.properties}>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>User Id:</div>
                      <div className={styles.propertyValue}>{selectedUser.id}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Name:</div>
                      <div className={styles.propertyValue}>{selectedUser.name}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Email:</div>
                      <div className={styles.propertyValue}>{selectedUser.email}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Status:</div>
                      <div className={styles.propertyValue}>{(selectedUserDeleted ? 'DELETED! was: ' : '') + (selectedUser.isVerified ? 'Verified' : 'Unverified')}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Roles:</div>
                      <div className={styles.propertyValue}>{selectedUser.roles && selectedUser.roles.length > 0 ? selectedUser.roles.join(', ') : '-'}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Created:</div>
                      <div className={styles.propertyValue}>{selectedUser.createdAt?.toLocaleString() ?? '-'}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Updated:</div>
                      <div className={styles.propertyValue}>{selectedUser.updatedAt?.toLocaleString() ?? '-'}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Last Login:</div>
                      <div className={styles.propertyValue}>TODO</div>
                    </div>
                  </div>
                  <div className={styles.actions}>
                    
                    {/* TODO: add a (basic) way to set/update the user roles/permissions... */}
                    {!selectedUserDeleted && (<div className={styles.action}>
                      <AdminUserRolesView
                        user={selectedUser}
                        onUpdateUserRoles={(roles: Array<string>) => {
                          onUpdateUserRoles(selectedUser.id, roles)
                        }}
                        updating={selectedUserUpdating}
                        updated={selectedUserUpdated}
                        error={selectedUserUpdateError}
                      />
                    </div>)}

                    {(selectedUserDeleteError) && (<div className={styles.errMsg}>ERROR: {selectedUserDeleteError.message}</div>)}
                    {(showDeleteUserAction && !selectedUserDeleted) && (
                      <div className={styles.action}><Button fluid className={styles.deleteBtn} loading={selectedUserDeleting} onClick={() => onDeleteUser(selectedUser.id)}>DELETE USER</Button></div>
                    )}
                    {(showDeleteUserAction && selectedUserDeleted) && (<div className={styles.action + ' ' + styles.deleted}>USER DELETED</div>)}
                  </div>
                </div>
              )}
              {selectedInvite && (
                <div>
                  <h2>Invite: {selectedInvite.email}</h2>
                  <div className={styles.properties}>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Invite Id:</div>
                      <div className={styles.propertyValue}>{selectedInvite.id}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Email:</div>
                      <div className={styles.propertyValue}>{selectedInvite.email}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Status:</div>
                      <div className={styles.propertyValue}>{UserInviteStatus[selectedInvite.status]}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Created:</div>
                      <div className={styles.propertyValue}>{selectedInvite.createdAt.toLocaleString()}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Updated:</div>
                      <div className={styles.propertyValue}>{selectedInvite.updatedAt?.toLocaleString() ?? '-'}</div>
                    </div>
                    <div className={styles.property}>
                      <div className={styles.propertyTitle}>Invited By:</div>
                      <div className={styles.propertyValue}>{selectedInvite.invitedBy}</div>
                    </div>
                  </div>
                  <div className={styles.actions}>
                    {showResendInviteAction && (
                      <div className={styles.action}><Button fluid className={styles.resendBtn} onClick={() => onResendInvite(selectedInvite.email)} disabled={isResendingInvite} loading={isResendingInvite}>RE-SEND INVITE</Button></div>
                    )}
                    {showCancelInviteAction && (
                      <div className={styles.action}><Button fluid className={styles.cancelBtn} onClick={() => onCancelInvite(selectedInvite.email)}>CANCEL INVITE</Button></div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </CenterLayout>
      </div>
    </BasePage>
  )
}

export default AdminUsersPage
