import React, { useContext, useEffect, useState } from 'react'

import { ChatContext } from '../../providers'
import { ChatMessage, ChatMessageRole } from '../../models/ChatMessage'
import { APIMode, ChatCompletionResult, TextCompletionResult } from 'src/providers/ChatProvider'

import Button from '../../components/Button'
import Select from '../../components/Select'
import ChatMessagesField from './ChatMessagesField'
import ChatMessageTextArea from './ChatMessageTextArea'

import { AI_MODELS, AI_MODEL_DEFAULT_DEBUG_MODE, AI_MODEL_TEMPERATURE_DEFAULT, getAIModel } from '../../constants/config'

import styles from './ChatDebugView.module.css'


enum InputMode {
  text, chat
}



const ChatDebugView = () => {

  const chatContext = useContext(ChatContext)

  const [aiModels] = useState<Array<{ id: string, inputMode: string }>>(AI_MODELS) // setAIModels
  
  const [modelId, setModelId] = useState<string>(AI_MODEL_DEFAULT_DEBUG_MODE)
  const [inputMode, setInputMode] = useState<InputMode>((InputMode[getAIModel(AI_MODEL_DEFAULT_DEBUG_MODE)?.inputMode as keyof typeof InputMode]) ?? InputMode.text)
  const [temperature, setTemperature] = useState<number>(AI_MODEL_TEMPERATURE_DEFAULT)
  const [maxTokens, setMaxTokens] = useState<number | undefined>(undefined) // getAIModel(AI_MODEL_DEFAULT_DEBUG_MODE)?.maxTokens) // ?? AI_MODEL_MAX_TOKENS_DEFAULT)
  const [apiMode, setApiMode] = useState<APIMode>(APIMode.direct)

  const [prompt, setPrompt] = useState<string>('')
  const [promptResult, setPromptResult] = useState<string | undefined>(undefined)

  const [chatMessages, setChatMessages] = useState<Array<ChatMessage>>([])

  // const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [submitError, setSubmitError] = useState<Error | undefined>()

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log('ChatDebugView - onSubmit - inputMode:', inputMode, ' apiMode:', apiMode)
    event.preventDefault()
    if (promptResult) setPromptResult(undefined)
    setSubmitError(undefined)
    if (inputMode === InputMode.text) {
      console.log('ChatDebugView - onSubmit - InputMode.text - modelId:', modelId, ' temperature:', temperature, ' maxTokens:', maxTokens, ' prompt: \'' + prompt + '\'')
      // TODO: halt if fields missing <<<<
      if (prompt.trim().length === 0) {
        setSubmitError(new Error('Prompt should not be empty'))
        return
      }
      setIsSubmitting(true)
      try {
        console.log('ChatDebugView - onSubmit - InputMode.text - submitting... apiMode:', apiMode)
        let result: TextCompletionResult | undefined
        if (apiMode === APIMode.direct) {
          result = await chatContext.actions.createCompletionDirect(modelId, prompt, temperature, undefined, maxTokens)
        } else {
          result = await chatContext.actions.createCompletion(modelId, prompt, temperature, undefined, maxTokens)
        }
        if (result && result.choices.length > 0) {
          // NB: currently only handling a single choice in the response result
          const resultText = result.choices[0].text
          //setPromptResult(resultText)
          const newPrompt = prompt + resultText
          setPrompt(newPrompt)
        }
      } catch (error: any) {
        console.log('ChatDebugView - onSubmit - InputMode.text - error:', error)
        setSubmitError(error)
      }
      setIsSubmitting(false)
    }
    else if (inputMode === InputMode.chat) {
      console.log('ChatDebugView - onSubmit - InputMode.chat - modelId:', modelId, ' temperature:', temperature, ' maxTokens:', maxTokens, ' chatMessages:', chatMessages, apiMode)
      // TODO: halt if fields missing <<<<
      if (chatMessages.length === 0) {
        setSubmitError(new Error('Add at least one message'))
        return
      }
      for (const chatMessage of chatMessages) {
        if (chatMessage.content.trim().length === 0) {
          setSubmitError(new Error('Messages should not be empty'))
          return
        }
      }
      setIsSubmitting(true)
      try {
        console.log('ChatDebugView - onSubmit - InputMode.chat - submitting...')
        // await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
        // setChatMessages(oldChatMessages => [...oldChatMessages, { role: ChatMessageRole.assistant, content: 'Dummy response...' }])
        let result: ChatCompletionResult | undefined
        if (apiMode === APIMode.direct) {
          result = await chatContext.actions.createChatCompletionDirect(modelId, chatMessages, temperature, undefined, maxTokens) // TODO: add `topP` support
        } else {
          result = await chatContext.actions.createChatCompletion(modelId, chatMessages, temperature, undefined, maxTokens) // TODO: add `topP` support
        }
        console.log('ChatDebugView - onSubmit - InputMode.chat - result:', result)
        if (result && result.choices.length > 0) {
          // NB: currently only handling a single choice in the response result
          const choice = result.choices[0]
          setChatMessages(oldChatMessages => [...oldChatMessages, { role: choice.message.role as ChatMessageRole, content: choice.message.content }])
        }
      } catch (error: any) {
        console.error('ChatDebugView - onSubmit - InputMode.chat - error:', error)
        setSubmitError(error)
      }
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    console.log('ChatDebugView - useEffect - modelId:', modelId)
    const _model = getAIModel(modelId)
    const _modelInputMode = (InputMode[_model?.inputMode as keyof typeof InputMode]) ?? InputMode.text
    const _modelMaxTokens = _model?.maxTokens // ?? AI_MODEL_MAX_TOKENS_DEFAULT
    console.log('ChatDebugView - useEffect - _modelInputMode:', _modelInputMode, ' inputMode:', inputMode)
    if (_modelInputMode !== undefined && _modelInputMode !== inputMode) setInputMode(_modelInputMode)
    if (_modelMaxTokens !== maxTokens) setMaxTokens(_modelMaxTokens)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId])

  return (
    <div className={styles.chatDebug}>
      <form className={styles.form + (isSubmitting ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
          <h1>Chat Debug</h1>
          {/* {authContext.store.authError && (
            <div className={styles.error}>{authContext.store.authError.message}</div>
          )} */}
          {submitError && (
            <div className={styles.error}>
              {submitError.message ?? submitError}
            </div>
          )}
          <div className={`${styles.field} ${styles.fieldModel}`}>
            <label htmlFor="model">Model:</label>
            <div className={styles.input}>
              {/* <input type="text" value={model} id="model" name="model" onChange={(event) => { setModel(event.target.value) }} /> */}
              <Select
                placeholder={'Select Model'} // isLoading ? '' : 
                options={aiModels?.map(m => { return { value: m.id, label: m.id } })}
                defaultValue={modelId}
                onChange={(newValue?: string | number) => {
                  console.log('ChatDebugView - model - onChange - newValue:', newValue)
                  if (typeof newValue === 'string') setModelId(newValue)
                }}
                // isLoading={isLoading}
                className={styles.modelSelect}
              >
              </Select>
            </div>
          </div>
          <div className={styles.field}>
            <label htmlFor="inputMode">Input Mode:</label>
            <div className={styles.input}>
              <Select
                options={[{ value: InputMode.text, label: 'Text' }, { value: InputMode.chat, label: 'Chat' }]}
                defaultValue={inputMode}
                onChange={(newValue?: string | number) => {
                  console.log('ChatDebugView - mode - onChange - newValue:', newValue)
                  setInputMode(newValue as InputMode)
                  if (promptResult) setPromptResult(undefined)
                }}
                // isLoading={isLoading}
                className={styles.modeSelect}
              >
              </Select>
            </div>
          </div>
          <div className={styles.field}>
            <label htmlFor="temperature">Temperature:</label>
            <div className={styles.input}>
              <input type="number" id="temperature" name="temperature" required placeholder="0.00" min={0} max={1} step={0.05} pattern="^\d+(?:\.\d{1,2})?$" defaultValue={temperature} onChange={(event) => {
                const _temp = parseFloat(event.target.value)
                // TOOD: check if isNaN or out of range & flag as error, don't allow submit etc. <<<<
                setTemperature(_temp)
              }} />
            </div>
          </div>
          <div className={styles.field}>
            <label htmlFor="apiMode">API Mode:</label>
            <div className={styles.input}>
              <Select
                options={[{ value: APIMode.internal, label: 'Internal' }, { value: APIMode.direct, label: 'Direct' }]}
                defaultValue={apiMode}
                onChange={(newValue?: string | number) => {
                  console.log('ChatDebugView - apiMode - onChange - newValue:', newValue)
                  setApiMode(newValue as APIMode)
                  // if (promptResult) setPromptResult(undefined)
                }}
                // isLoading={isLoading}
                className={styles.apiModeSelect}
              >
              </Select>
            </div>
          </div>
          
          {inputMode === InputMode.text && (
            <>
              <div className={`${styles.field} ${styles.fieldPrompt}`}>
                <label htmlFor="prompt">Prompt:</label>
                <div className={styles.input}>
                  <ChatMessageTextArea
                    id="prompt"
                    name="prompt"
                    required
                    rows={6}
                    cols={80}
                    onChange={(event) => {
                      setPrompt(event.target.value)
                    }}
                    value={prompt}
                    minHeight={120}
                  ></ChatMessageTextArea>
                </div>
              </div>
              {/* {promptResult && (
                <div className={`${styles.field} ${styles.promptResult}`}>
                  <label>Result:</label>
                  <div className={styles.input}>{promptResult}</div>
                </div>
              )} */}
            </>
          )}

          {inputMode === InputMode.chat && (
            <ChatMessagesField
              messages={chatMessages}
              onAddMessage={() => {
                setChatMessages(oldChatMessages => {
                  const role = oldChatMessages.length > 0 ? ChatMessageRole.user : ChatMessageRole.system // first messages defaults to 'system' others to 'user' (as thats the more likely use case)
                  return [...oldChatMessages, { role: role, content: '' }]
                })
              }}
              onUpdateMessageRole={(index: number, role: ChatMessageRole) => {
                console.log('ChatDebugView - onUpdateMessageRole - index:', index, ' role:', role)
                if (index < chatMessages.length) {
                  const newChatMessages = [...chatMessages]
                  newChatMessages[index].role = role
                  setChatMessages(newChatMessages)
                }
              }}
              onUpdateMessageContent={(index: number, content: string) => {
                console.log('ChatDebugView - onUpdateMessageContent - index:', index, ' content:', content)
                if (index < chatMessages.length) {
                  const newChatMessages = [...chatMessages]
                  newChatMessages[index].content = content
                  setChatMessages(newChatMessages)
                }
              }}
              onDeleteMessage={(index: number) => {
                console.log('ChatDebugView - onDeleteMessage - index:', index)
                if (index < chatMessages.length) {
                  const newChatMessages = [...chatMessages]
                  newChatMessages.splice(index, 1)
                  console.log('ChatDebugView - onDeleteMessage - newChatMessages(AFTER):', newChatMessages)
                  setChatMessages(newChatMessages)
                }
              }}
            />
          )}

          <div className={styles.buttons}>
            <div className={styles.field + ' ' + styles.button}>
              {/* <input type="submit" value="Submit" disabled={isSubmitting} /> */}
              <Button disabled={isSubmitting} loading={isSubmitting}>SUBMIT</Button>
            </div>
          </div>
        </form>
    </div>
  )
}

export default ChatDebugView
