import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import AppRouter from './AppRouter'
import { ServerProvider, ServerConfigProvider, AuthProvider, UserProvider, NavProvider } from './providers'

import { ServerAPI, ServerAPIClient, ServerConfigAPI, ServerAuthAPI } from './services'
import OpenAIAPIClient from './services/OpenAIAPIClient'

import * as CONFIG from './constants/config'

import './App.css'

const apiClient = new ServerAPIClient(CONFIG.API_SERVER_URL)
const serverApi = new ServerAPI(apiClient)
const serverConfigApi = new ServerConfigAPI(apiClient)
const authApi = new ServerAuthAPI(apiClient)

let openAIApiClient: OpenAIAPIClient | undefined
if (CONFIG.AI_DIRECT_MODE_ENABLED) {
  openAIApiClient = new OpenAIAPIClient(CONFIG.AI_DIRECT_API_URL)
}

function App() {
  console.log('App - render')
  return (
    <BrowserRouter>
      <NavProvider>
        <ServerProvider apiClient={apiClient} serverAPI={serverApi} authApi={authApi} openAIApiClient={openAIApiClient}>
          <ServerConfigProvider apiClient={apiClient} serverConfigApi={serverConfigApi}>
            <AuthProvider apiClient={apiClient} authApi={authApi}>
              <UserProvider apiClient={apiClient} authApi={authApi}>
                <div className="App">
                  {/* <div className="AppContent"> */}
                    <AppRouter />
                  {/* </div> */}
                </div>
              </UserProvider>
            </AuthProvider>
          </ServerConfigProvider>
        </ServerProvider>
      </NavProvider>
    </BrowserRouter>
  )
}

export default App
