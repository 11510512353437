import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { Project } from 'src/models'
import { ProjectContext } from 'src/providers'

import Button from 'src/components/Button'
import DeleteConfirmModal from 'src/components/DeleteConfirmModal/DeleteConfirmModal'
import Header from 'src/components/Header'
import Modal from 'src/components/Modal'

import ProjectAddEditForm from './ProjectAddEditForm'
import ProjectSaveAsForm from './ProjectSaveAsForm'
import ProjectImportForm from './ProjectImportForm'
import ProjectExportForm from './ProjectExportForm'

import * as ROUTES from 'src/constants/routes'

import styles from './ProjectsListView.module.css'

const ProjectsListView = () => {
  const { actions: projectActions, store: projectStore} = useContext(ProjectContext)
  const { projects } = projectStore

  const [editProject, setEditProject] = useState<Project | undefined>(undefined)
  const [exportProject, setExportProject] = useState<Project | undefined>(undefined)
  const [duplicateProject, setDuplicateProject] = useState<Project | undefined>(undefined)
  const [deleteProject, setDeleteProject] = useState<Project | undefined>(undefined)
  const [_showAddEditModal, setShowAddEditModal] = useState<boolean>(false)
  const [_showImportModal, setShowImportModal] = useState<boolean>(false)
  const [_showExportModal, setShowExportModal] = useState<boolean>(false)
  const [_showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false)
  const [_showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  // const onOpenProject = (project: Project) => {
  //   console.log('ProjectsListView - onOpenProject - project:', project)
  //   // TODO: 
  // }

  const onDeleteProjectConfirmed = async (): Promise<{result: boolean, error?: Error}> => {
    console.log('ProjectsListView - onDeleteProjectConfirmed')
    try {
      if (!deleteProject) throw new Error('No project to delete')
      const result = await projectActions.deleteProject(deleteProject.key)
      if (!result) throw new Error('Invalid response')
      return { result: true }
    } catch (error: any) {
      console.error('ProjectsListView - onDeleteProjectConfirmed - error:', error)
      return { result: false, error }
    }
  }

  // -------

  const showAddEditModal = (project?: Project) => {
    setEditProject(project)
    setShowAddEditModal(true)
  }
  const hideAddEditModal = () => {
    setEditProject(undefined)
    setShowAddEditModal(false)
  }

  const renderAddEditModal = () => {
    return (
      <Modal
        open={_showAddEditModal}
        onClose={hideAddEditModal}
        closeOnEscapeKey={true}
      >
        <Header title={<>{editProject !== undefined ? <>EDIT</> : <>ADD</>} PROJECT</>}></Header>
        <ProjectAddEditForm
          project={editProject}
          onClose={() => hideAddEditModal()}
          onCancel={() => hideAddEditModal()}
        />
      </Modal>
    )
  }

    // -------

    const showImportModal = (project?: Project) => {
      setShowImportModal(true)
    }
    const hideImportModal = () => {
      setShowImportModal(false)
    }
  
    const renderImportModal = () => {
      return (
        <Modal
          open={_showImportModal}
          onClose={hideImportModal}
          closeOnEscapeKey={true}
        >
          <Header title={<>IMPORT PROJECT</>}></Header>
          <ProjectImportForm
            onClose={() => hideImportModal()}
            onCancel={() => hideImportModal()}
          />
        </Modal>
      )
    }
  
  // -------

  const showExportModal = (project?: Project) => {
    setExportProject(project)
    setShowExportModal(true)
  }
  const hideExportModal = () => {
    setExportProject(undefined)
    setShowExportModal(false)
  }

  const renderExportModal = () => {
    return (
      <Modal
        open={_showExportModal}
        onClose={hideExportModal}
        closeOnEscapeKey={true}
      >
        <Header title={<>EXPORT PROJECT</>}></Header>
        <ProjectExportForm
          project={exportProject}
          onClose={() => hideExportModal()}
          onCancel={() => hideExportModal()}
        />
      </Modal>
    )
  }

  // -------

  const showDuplicateModal = (project?: Project) => {
    setDuplicateProject(project)
    setShowDuplicateModal(true)
  }
  const hideDuplicateModal = () => {
    setDuplicateProject(undefined)
    setShowDuplicateModal(false)
  }

  const renderDuplicateModal = () => {
    return (
      <Modal
        open={_showDuplicateModal}
        onClose={hideDuplicateModal}
        closeOnEscapeKey={true}
      >
        <Header title={<>DUPLICATE PROJECT</>}></Header>
        <ProjectSaveAsForm
          project={duplicateProject}
          onClose={() => hideDuplicateModal()}
          onCancel={() => hideDuplicateModal()}
        />
      </Modal>
    )
  }

  // -------

  const showDeleteModal = (project: Project) => {
    setDeleteProject(project)
    setShowDeleteModal(true)
  }
  const hideDeleteModal = () => {
    setDeleteProject(undefined)
    setShowDeleteModal(false)
  }

  const renderDeleteModal = () => {
    if (!deleteProject) return null
    return (
      <DeleteConfirmModal
        typeName={'Project'}
        deleteName={deleteProject.title}
        open={_showDeleteModal}
        onClose={() => hideDeleteModal()}
        onConfirm={onDeleteProjectConfirmed}
        onCancel={() => hideDeleteModal()}
        size={'small'}
      />
    )
  }

  // -------

  return (
    <div className={styles.view}>
      <div className={styles.topbar}>
        <Button onClick={() => showAddEditModal()}>ADD PROJECT</Button>
        <Button onClick={() => showImportModal()}>IMPORT PROJECT</Button>
      </div>
      <div className={styles.content}>
        <h1>Projects</h1>
        {projects.length > 0 && (
          <>
            <div className={styles.list}>
              <ul>
                {projects.map((project) => {
                  return (
                    <li key={'project_' + project.key}>
                      <div className={styles.title} /* onClick={() => onOpenProject(project)} */>
                        <Link to={ROUTES.PROJECT_EDITOR.replace(':projectId', project.projectId.toString() ?? '')}>{project.title}</Link>
                      </div>
                      <div className={styles.actions}>
                        <div className={styles.dupe}><Button slim onClick={() => showExportModal(project)}>EXPORT</Button></div>
                        <div className={styles.dupe}><Button slim onClick={() => showDuplicateModal(project)}>DUPE</Button></div>
                        <div className={styles.edit}><Button slim onClick={() => showAddEditModal(project)}>EDIT</Button></div>
                        <div className={styles.delete}><Button slim error onClick={() => showDeleteModal(project)}>X</Button></div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </>
        )}
        {projects.length === 0 && (
          <div className={styles.noProjects}>
            <div className={styles.noProjectsMsg}>
              <h2 className={styles.title}>NO PROJECTS</h2>
              <div>Create one to begin!</div>
            </div>
          </div>
        )}
        {renderImportModal()}
        {renderAddEditModal()}
        {renderExportModal()}
        {renderDuplicateModal()}
        {renderDeleteModal()}
      </div>
    </div>
  )
}

export default ProjectsListView
