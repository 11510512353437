import React from 'react'
import BasePage from '../../components/BasePage/BasePage'
import CenterLayout from '../../components/CenterLayout'

const NotFoundPage = () => {
  return (
    <BasePage>
      <CenterLayout>PAGE NOT FOUND</CenterLayout>
    </BasePage>
  )
}

export default NotFoundPage
