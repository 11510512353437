/**
 * CenterLayout
 */

import React, { ReactNode } from 'react'

import styles from './CenterLayout.module.css'

export interface CenterLayoutProps {
  children: ReactNode
  className?: string
}

const CenterLayout = (props: CenterLayoutProps) => {
  const { children, className } = props
  return (
    <div className={`${styles.container} ${className || ''}`}>
      {children}
    </div>
  )
}

export default CenterLayout
