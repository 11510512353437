import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { Project } from 'src/models'
import NodeRunConfig from 'src/models/NodeRunConfig'
import { ProjectContext } from 'src/providers'

import Button from 'src/components/Button'
import Message from 'src/components/Message'
import Select from 'src/components/Select'

import { AI_MODELS, AI_MODEL_DEFAULT_PROJECT_MODE, AI_MODEL_TEMPERATURE_DEFAULT, AI_MODEL_TOP_P_DEFAULT, AI_RUN_ORDER_STRICT_Y_DEFAULT, getAIModel } from '../../constants/config'

import styles from './ProjectEditorSettingsForm.module.css'
import formStyles from 'src/components/Form/Form.module.css'

interface ProjectEditorSettingsFormProps {
  project?: Project
  onClose: Function
  onCancel?: Function
}

const ProjectEditorSettingsForm = (props: ProjectEditorSettingsFormProps) => {
  const { project, onClose, onCancel } = props
  const projectContext = useContext(ProjectContext)
  
  /**
   * react-hook-form refs:
   *  https://blog.logrocket.com/react-hook-form-complete-guide/
   */
  interface IFormData {
    model?: string
    temperature?: number
    topP?: number
    maxTokens?: number
    systemMsg?: string
    strictYOrder: boolean
  }

  const {
    register,
    handleSubmit,
    // watch,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>()

  const [aiModels] = useState<Array<{ id: string, inputMode: string }>>(AI_MODELS) // setAIModels
  
  // TODO: ditch the state vars & use the form values directly instead? is that possible with current usage (some bits rely on the state vars, include the select field wrapped with the Controller)
  // WARNING: if changing the default/init values also update then in the `useEffect` init hook that triggers the react-hook-form's `reset` function to init its internal state with loaded values (& defaults if the project doesn't have the values set)
  const [modelId, setModelId] = useState<string | undefined>((project?.runConfig?.model && getAIModel(project?.runConfig?.model) ? project?.runConfig?.model : undefined))
  const [temperature, setTemperature] = useState<number | undefined>((project?.runConfig?.temp && typeof project?.runConfig?.temp === 'number' ? project?.runConfig?.temp : undefined))
  const [topP, setTopP] = useState<number | undefined>((project?.runConfig?.topP && typeof project?.runConfig?.topP === 'number' ? project?.runConfig?.topP : undefined))
  const [maxTokens, setMaxTokens] = useState<number | undefined>((project?.runConfig?.maxTokens && typeof project?.runConfig?.maxTokens === 'number' ? project?.runConfig?.maxTokens : undefined))
  const [systemMsg, setSystemMsg] = useState<string | undefined>((project?.runConfig?.systemMsg && typeof project?.runConfig?.systemMsg === 'string' ? project?.runConfig?.systemMsg : undefined))
  const [strictYOrder, setStrictYOrder] = useState<boolean>((project?.runConfig?.strictYOrder !== undefined ? project?.runConfig?.strictYOrder : AI_RUN_ORDER_STRICT_Y_DEFAULT))
  
  const defaultModel = getAIModel(AI_MODEL_DEFAULT_PROJECT_MODE)
  const defaultTemp = AI_MODEL_TEMPERATURE_DEFAULT
  const defaultTopP = AI_MODEL_TOP_P_DEFAULT
  const defaultMinTokens = (modelId ? getAIModel(modelId)?.minTokens : (defaultModel ? getAIModel(defaultModel?.id)?.minTokens : undefined))
  const defaultMaxTokens = (modelId ? getAIModel(modelId)?.maxTokens : (defaultModel ? getAIModel(defaultModel?.id)?.maxTokens : undefined))

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // console.log('ProjectEditorSettingsForm - watch - firstName:', watch('firstName'))

  const onSubmit = async (data: IFormData) => {
    console.log('ProjectEditorSettingsForm - onSubmit - data:', data)
    if (submitting) return
    setSubmitting(true)
    setSaved(false)
    setError(undefined)
    // await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
    try {
      if (project) {
        // TODO: is it ok to update the project prop ref like this, or does it need to be done differently? (within the ProjectProvider most likely if so?)
        if (!project.runConfig) {
          project.runConfig = new NodeRunConfig()
        }
        
        // TESTING: check for NaN when using `valueAsNumber: true` in the fields `register` call (or numbers are returned as strings & we'd need to parse instead)
        const modelId = data.model  && getAIModel(data.model ) ? data.model  : undefined
        const temperature: number | undefined = typeof data.temperature === 'number' && !isNaN(data.temperature) ? data.temperature : undefined
        const topP: number | undefined = typeof data.topP === 'number' && !isNaN(data.topP) ? data.topP : undefined
        const maxTokens: number | undefined = typeof data.maxTokens === 'number' && !isNaN(data.maxTokens) ? data.maxTokens : undefined
        const systemMsg: string | undefined = typeof data.systemMsg === 'string' && data.systemMsg.trim().length > 0 ? data.systemMsg : undefined
        const strictYOrder: boolean | undefined = typeof data.strictYOrder === 'boolean' && data.strictYOrder !== AI_RUN_ORDER_STRICT_Y_DEFAULT ? data.strictYOrder : undefined // NB: only set if not the same as the default

        project.runConfig.model = modelId
        project.runConfig.temp = temperature
        project.runConfig.topP = topP
        project.runConfig.maxTokens = maxTokens
        project.runConfig.systemMsg = systemMsg
        project.runConfig.strictYOrder = strictYOrder
        console.log('ProjectEditorSettingsForm - onSubmit - project.runConfig:', project.runConfig)

        console.log('ProjectEditorSettingsForm - onSubmit - project:', project)
        await projectContext.actions.saveProject(project)
      } else {
        throw new Error('Invalid project data')
      }
      setSaved(true)
    } catch (error: any) {
      console.error('ProjectEditorSettingsForm - onSubmit - error:', error)
      setError(error)
    }
    setSubmitting(false)
  }

  const onError = (errors: any) => { // TOOD: type <<<<
    console.log('ProjectEditorSettingsForm - onError - errors:', errors)
  }

  // TESTING: submit on enter - ref: https://github.com/react-hook-form/react-hook-form/issues/936
  // const onKeyDownInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   console.log('ProjectEditorSettingsForm - onKeyDownInput - event:', event)
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     console.log('ProjectEditorSettingsForm - onKeyDownInput - OK - SUBMIT...')
  //     event.preventDefault()
  //     handleSubmit(onSubmit)()
  //   }
  // }
  // const onKeyDownButton = (event: React.KeyboardEvent<HTMLButtonElement>) => {
  //   console.log('ProjectEditorSettingsForm - onKeyDownButton - event:', event)
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     console.log('ProjectEditorSettingsForm - onKeyDownButton - ENTER - SUBMIT...')
  //     event.preventDefault()
  //   }
  // }

  // TESTING: (re)set the default values on init
  // NB: seems to be needed for the Select `model` field to work (the standard `temperature` input field seemed to load the default ok without but setting it anyway while at it)
  // ref: https://stackoverflow.com/a/70667544
  useEffect(() => {
    console.log('ProjectEditorSettingsForm - useEffect - mount - modelId:', modelId, ' temperature:', temperature, ' topP:', topP, ' maxTokens:', maxTokens)
    let defaultValues:IFormData = {
      model: modelId, // project?.runConfig?.model,
      temperature: temperature, // (project?.runConfig?.temp && typeof project?.runConfig?.temp === 'number' ? project?.runConfig?.temp : undefined),
      topP: topP, // (project?.runConfig?.topP && typeof project?.runConfig?.topP === 'number' ? project?.runConfig?.topP : undefined),
      maxTokens: maxTokens, //(project?.runConfig?.maxTokens && typeof project?.runConfig?.maxTokens === 'number' ? project?.runConfig?.maxTokens : undefined)
      systemMsg: systemMsg,
      strictYOrder: strictYOrder,
    }
    console.log('ProjectEditorSettingsForm - useEffect - mount - defaultValues(AFTER/LOADED):', defaultValues)
    reset({ ...defaultValues })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   console.log('ProjectEditorSettingsForm - useEffect - modelId:', modelId)
  //   // TODO: update the min/max token values etc?
  // }, [modelId])

  const aiModelOptions = [{ value: undefined, label: 'Default' }, ...aiModels?.filter(m => m.inputMode === 'chat').map(m => { return { value: m.id, label: m.id } }) ]

  return (
    <>
      {saved && (
        <div className={styles.successMsg}>
          <Message positive>
            <p>Project saved</p>
          </Message>
          <Button secondary autoFocus onClick={() => { onClose() }} className={styles.successMsgBtn} /* onKeyDown={onKeyDownButton} */>OK</Button>
        </div>
      )}
      {error && (
        <div className={styles.errorMsg}>
          <Message error>
            <p>Error: {error.message}</p>
          </Message>
          {/* <Button secondary onClick={() => { onClose() }} className={styles.errorMsgBtn}>OK</Button> */}
        </div>
      )}
      {!saved && (
        <form onSubmit={handleSubmit(onSubmit, onError)} className={formStyles.form + ' ' + styles.form}>
          
          <div className={styles.field /* formStyles.field */}>
            <label htmlFor='model'>Model</label>
            <div className={styles.input}>
              {/**
               * TESTING: react-select with hook-react-form...
               * refs:
               *  https://stackoverflow.com/a/67427010
               *  https://codesandbox.io/s/react-select-with-react-hook-form-sc2xz?file=/src/App.js:1219-1221
               *  https://nihalmahesh.medium.com/how-to-use-react-form-hook-and-react-select-together-8dcdc56490b5
               */}
              <Controller
                control={control}
                name="model"
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    placeholder={'Select Model'} // isLoading ? '' : 
                    options={aiModelOptions}
                    defaultValue={modelId}
                    onChange={(newValue?: string | number) => {
                      console.log('ProjectEditorSettingsForm - model - onChange - newValue:', newValue, ' value:', value)
                      if (typeof newValue === 'string' || newValue === undefined) setModelId(newValue)
                      onChange(newValue)
                    }}
                    // isLoading={isLoading}
                    className={styles.modelSelect}
                    slimline
                  >
                  </Select>
                )}
                // defaultValue={[colouroptions[2], colourOptions [3]]}
                // options={aiModels?.map(m => { return { value: m.id, label: m.id } })}
              />
              {errors.model && <div className={formStyles.fieldError}>{errors.model.message}</div>}
            </div>
            <div className={styles.desc}>(Default: {defaultModel?.id ?? '-'})</div>
          </div>

          <div className={styles.field}>
            <label htmlFor="temperature">Temperature:</label>
            <div className={styles.input}>
              <input
                type="number"
                id="temperature"
                // name="temperature"
                // required
                placeholder={"" + AI_MODEL_TEMPERATURE_DEFAULT.toFixed(2)}
                min={0}
                max={1}
                step={0.05}
                pattern="^\d+(?:\.\d{1,2})?$"
                defaultValue={temperature}
                {...register('temperature', {
                  valueAsNumber: true, // ref: https://stackoverflow.com/a/73206877
                  // required: 'Temperature is required',
                  // NB: ported default field `onChange` to support react-hook-form's version - ref: https://stackoverflow.com/a/69448858
                  onChange: (event) => {
                    const _temp = parseFloat(event.target.value)
                    console.log('ProjectEditorSettingsForm - temperature - onChange - _temp:', _temp)
                    setTemperature(!isNaN(_temp) ? _temp : undefined)
                  }
                })}
              />
              {errors.temperature && <div className={formStyles.fieldError}>{errors.temperature.message}</div>}
            </div>
            <div className={styles.desc}>(Default: {defaultTemp})</div>
          </div>

          <div className={styles.field}>
            <label htmlFor="top_p">Top-P:</label>
            <div className={styles.input}>
              <input
                type="number"
                id="top_p"
                // name="top_p"
                // required
                placeholder={"" + AI_MODEL_TOP_P_DEFAULT.toFixed(2)}
                min={0}
                max={1}
                step={0.05}
                pattern="^\d+(?:\.\d{1,2})?$"
                defaultValue={topP}
                {...register('topP', {
                  valueAsNumber: true, // ref: https://stackoverflow.com/a/73206877
                  // required: 'Top-P is required',
                  // NB: ported default field `onChange` to support react-hook-form's version - ref: https://stackoverflow.com/a/69448858
                  onChange: (event) => {
                    const _top_p = parseFloat(event.target.value)
                    console.log('ProjectEditorSettingsForm - top_p - onChange - _top_p:', _top_p)
                    setTopP(!isNaN(_top_p) ? _top_p : undefined)
                  }
                })}
              />
              {errors.topP && <div className={formStyles.fieldError}>{errors.topP.message}</div>}
            </div>
            <div className={styles.desc}>(Default: {defaultTopP})</div>
          </div>

          <div className={styles.field}>
            <label htmlFor="maxTokens">Max Tokens:</label>
            <div className={styles.input}>
              <input
                type="number"
                id="maxTokens"
                // name="maxTokens"
                // required
                placeholder={"" + (defaultMaxTokens ? defaultMaxTokens : '')}
                min={defaultMinTokens}
                max={defaultMaxTokens}
                // step={100}
                pattern="^\d+(?:\.\d{1,2})?$"
                defaultValue={maxTokens}
                {...register('maxTokens', {
                  valueAsNumber: true, // ref: https://stackoverflow.com/a/73206877
                  // required: 'Max Tokens is required',
                  // NB: ported default field `onChange` to support react-hook-form's version - ref: https://stackoverflow.com/a/69448858
                  onChange: (event) => {
                    const _maxTokens = parseFloat(event.target.value)
                    console.log('ProjectEditorSettingsForm - maxTokens - onChange - _maxTokens:', _maxTokens)
                    setMaxTokens(!isNaN(_maxTokens) ? _maxTokens : undefined)
                  }
                })}
              />
              {errors.maxTokens && <div className={formStyles.fieldError}>{errors.maxTokens.message}</div>}
            </div>
            <div className={styles.desc}>(Default: {defaultMaxTokens} | Range: {defaultMinTokens} - {defaultMaxTokens})</div>
          </div>

          <div className={styles.field}>
            <label htmlFor="systemMsg">System Message:</label>
            <div className={styles.input}>
              <textarea
                id={'systemMsg'}
                // name={'systemMsg'}
                cols={40}
                rows={4}
                value={systemMsg}
                // onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                //   // console.log('ProjectEditorSettingsForm - systemMsg - onChange - event.target?.value:', event.target?.value)
                //   setSystemMsg(event.target?.value && event.target.value.trim().length > 0 ? event.target.value : undefined)
                // }}
                {...register('systemMsg', {
                  // valueAsNumber: true, // ref: https://stackoverflow.com/a/73206877
                  // required: 'Temperature is required',
                  // NB: ported default field `onChange` to support react-hook-form's version - ref: https://stackoverflow.com/a/69448858
                  onChange: (event) => {
                    // console.log('ProjectEditorSettingsForm - systemMsg - onChange - event.target?.value:', event.target?.value)
                    setSystemMsg(event.target?.value && event.target.value.trim().length > 0 ? event.target.value : undefined)
                  }
                })}
              ></textarea>
              {errors.systemMsg && <div className={formStyles.fieldError}>{errors.systemMsg.message}</div>}
            </div>
          </div>

          <div className={styles.field}>
            <label htmlFor="strictYOrder">Strict Y Order:</label>
            <div className={styles.input}>
            <input
                type="checkbox"
                id="strictYOrder"
                {...register('strictYOrder', {
                  onChange: (event) => {
                    setStrictYOrder(event.target.checked)
                  }
                })}
              />
              {errors.strictYOrder && <div className={formStyles.fieldError}>{errors.strictYOrder.message}</div>}
            </div>
            {!strictYOrder && (<span className={styles.strictYOrderWIPWarning}>WARNING: child priority ordering is currently broken!</span>)}
          </div>

          <div className={formStyles.fieldGroup + ' spaceEqual padTop'}>
            <div className={formStyles.field}>
              {/* <input type="button" value="Cancel" onClick={() => { if (onCancel) onCancel() }} /> */}
              <Button secondary disabled={submitting} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.preventDefault() // don't submit
                if (onCancel) onCancel()
              }}>Cancel</Button>
            </div>
            <div className={formStyles.field}>
              {/* <input type="submit" value="Save" /> */}
              <Button type='submit' primary loading={submitting} disabled={submitting}>Save</Button>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default ProjectEditorSettingsForm
