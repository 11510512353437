import axios, { AxiosResponse, AxiosError, ResponseType, Method } from 'axios'
import ServerAuthAPI from './ServerAuthAPI'

export class ServerAPIError extends Error {
  public statusCode?: number // http status code
  public errorCode?: number // internal Ark error code
  public data?: any // the axios data field, incase additional data was passed along as part of the error
  constructor (message?: string, statusCode?: number, errorCode?: number, data?: any) {
    super(message) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    this.statusCode = statusCode
    this.errorCode = errorCode
    this.data = data
  }
}

export class ServerAPICancelledError extends ServerAPIError {
  constructor () {
    super('API Request Cancelled')
  }
}

export interface ServerAPIRequest {
  apiPath: string
  method: Method | string
  abortController?: AbortController
  // url?: string
}

class ServerAPIClient {
  public apiBaseUrl: string
  public authToken?: string
  public authDeviceUUID?: string
  public authApi?: ServerAuthAPI
  private _activeRequests: Array<ServerAPIRequest>

  constructor (apiBaseUrl: string, authToken?: string, authDeviceUUID?: string) {
    console.log('ServerAPIClient - init')
    this.apiBaseUrl = apiBaseUrl
    this.authToken = authToken
    this.authDeviceUUID = authDeviceUUID
    this._activeRequests = []
  }

  // TODO: how to support multiple calls to the same api path/endpoint simultaneously?
  // TODO: ..require a unique key to be passed to to allow dupe calls & error when trying to make a call if one isn't set & a request is already active? or auto id in some way??
  _getActiveRequest = (apiPath: string, method: Method | string) => {
    // NB: currently assumes only 1 request of a give api path & method at a time
    return this._activeRequests.find((r) => r.apiPath === apiPath && r.method === method)
  }

  _addActiveRequest = (apiPath: string, method: Method | string) => {
    const activeReq = { apiPath, method, abortController: new AbortController() }
    this._activeRequests.push(activeReq)
    return activeReq
  }

  _removeActiveRequest = (apiPath: string, method: Method | string) => {
    const index = this._activeRequests.findIndex((r) => r.apiPath === apiPath && r.method === method)
    if (index >= 0) {
      this._activeRequests.splice(index, 1)
    }
  }

  apiGet = async (apiPath: string, headers?: any, refreshExpiredAuth: boolean = true, responseType?: ResponseType): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiGet - apiPath: ', apiPath, ' headers: ', headers, ' responseType: ', responseType)
    try {
      const activeReq = this._addActiveRequest(apiPath, 'get') // TODO: handle multiples of the same api endpoint & method?
      const config = this._apiConfig(headers, undefined, responseType, activeReq.abortController)
      const response = await axios.get(this.apiBaseUrl + apiPath, config)
      console.log('ServerAPIClient - apiGet - apiPath: ', apiPath, ' response: ', response)
      this._removeActiveRequest(apiPath, 'get') // TODO: handle multiples of the same api endpoint & method?
      return response
    } catch (error: any) {
      console.error('ServerAPIClient - apiGet - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      this._removeActiveRequest(apiPath, 'get') // TODO: handle multiples of the same api endpoint & method?
      // if (refreshExpiredAuth) {
      //   const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
      //   if (tokenRefreshed) {
      //     return await this.apiGet(apiPath, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
      //   }
      // }
      throw newError
    }
  }

  apiPost = async (apiPath: string, data: Object, headers?: any, refreshExpiredAuth: boolean = true): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiPost - apiPath: ', apiPath, ' data: ', data, ' headers: ', headers)
    try {
      const activeReq = this._addActiveRequest(apiPath, 'post') // TODO: handle multiples of the same api endpoint & method?
      const config = this._apiConfig(headers, undefined, undefined, activeReq.abortController)
      const response = await axios.post(this.apiBaseUrl + apiPath, data, config)
      console.log('ServerAPIClient - apiPost - apiPath: ', apiPath, ' response: ', response)
      this._removeActiveRequest(apiPath, 'post') // TODO: handle multiples of the same api endpoint & method?
      return response
    } catch (error: any) {
      console.error('ServerAPIClient - apiPost - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      this._removeActiveRequest(apiPath, 'post') // TODO: handle multiples of the same api endpoint & method?
      // if (refreshExpiredAuth) {
      //   const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
      //   if (tokenRefreshed) {
      //     return await this.apiPost(apiPath, data, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
      //   }
      // }
      throw newError
    }
  }

  apiPut = async (apiPath: string, data: Object, headers?: any, refreshExpiredAuth: boolean = true): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiPut - apiPath: ', apiPath, ' data: ', data)
    try {
      const activeReq = this._addActiveRequest(apiPath, 'put') // TODO: handle multiples of the same api endpoint & method?
      const config = this._apiConfig(headers, undefined, undefined, activeReq.abortController)
      const response = await axios.put(this.apiBaseUrl + apiPath, data, config)
      console.log('ServerAPIClient - apiPut - apiPath: ', apiPath, ' response: ', response)
      this._removeActiveRequest(apiPath, 'put') // TODO: handle multiples of the same api endpoint & method?
      return response
    } catch (error: any) {
      console.error('ServerAPIClient - apiPut - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      this._removeActiveRequest(apiPath, 'put') // TODO: handle multiples of the same api endpoint & method?
      // if (refreshExpiredAuth) {
      //   const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
      //   if (tokenRefreshed) {
      //     return await this.apiPut(apiPath, data, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
      //   }
      // }
      throw newError
    }
  }

  apiPatch = async (apiPath: string, data: Object, headers?: any, refreshExpiredAuth: boolean = true): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiPatch - apiPath: ', apiPath, ' data: ', data)
    try {
      const activeReq = this._addActiveRequest(apiPath, 'patch') // TODO: handle multiples of the same api endpoint & method?
      const config = this._apiConfig(headers, undefined, undefined, activeReq.abortController)
      const response = await axios.patch(this.apiBaseUrl + apiPath, data, config)
      console.log('ServerAPIClient - apiPatch - apiPath: ', apiPath, ' response: ', response)
      this._removeActiveRequest(apiPath, 'patch') // TODO: handle multiples of the same api endpoint & method?
      return response
    } catch (error: any) {
      console.error('ServerAPIClient - apiPatch - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      this._removeActiveRequest(apiPath, 'patch') // TODO: handle multiples of the same api endpoint & method?
      // if (refreshExpiredAuth) {
      //   const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
      //   if (tokenRefreshed) {
      //     return await this.apiPatch(apiPath, data, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
      //   }
      // }
      throw newError
    }
  }

  // TODO: the data arg doesn't seem to be working with delete calls & the current api server setup? needs looking into (maybe server side & not client?)
  // TODO: tried the following below, but it doesn't seem to be working - ref: https://stackoverflow.com/a/56210828
  // TODO: UPDATE: see the new comments on data line used further down, may fix it (untested)
  // UPDATE: this was with the old firebase api - does it apply to the newer custom api server? (might be useful in the future?)
  apiDelete = async (apiPath: string, data?: Object, headers?: any, refreshExpiredAuth: boolean = true): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiDelete - apiPath: ', apiPath)
    try {
      const activeReq = this._addActiveRequest(apiPath, 'delete') // TODO: handle multiples of the same api endpoint & method?
      const config = this._apiConfig(headers, data, undefined, activeReq.abortController)
      const response = await axios.delete(this.apiBaseUrl + apiPath, config)
      console.log('ServerAPIClient - apiDelete - apiPath: ', apiPath, ' response: ', response)
      this._removeActiveRequest(apiPath, 'delete') // TODO: handle multiples of the same api endpoint & method?
      return response
    } catch (error: any) {
      console.error('ServerAPIClient - apiDelete - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      this._removeActiveRequest(apiPath, 'delete') // TODO: handle multiples of the same api endpoint & method?
      // if (refreshExpiredAuth) {
      //   const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
      //   if (tokenRefreshed) {
      //     return await this.apiDelete(apiPath, data, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
      //   }
      // }
      throw newError
    }
  }

  // cancel support - ref: https://axios-http.com/docs/cancellation
  // NB: currently will cancel all/any active apis calls, might need to extend to be able to cancel specific ones in the future?
  apiAbort = async (apiPath: string, method: Method | string): Promise<boolean> => {
    // this._abortController.abort()
    const activeReq = this._getActiveRequest(apiPath, method)
    if (activeReq && activeReq.abortController) {
      activeReq.abortController.abort()
      return true
    }
    return false
  }

  apiParseErrorResponse = async (error: Error) : Promise<Error> => {
    console.log('ServerAPIClient - apiParseErrorResponse - error: ', error, ' _isAxiosError:', this._isAxiosError(error), ' isCancel:', axios.isCancel(error))
    if (this._isAxiosError(error)) {
      // NB: isCancel throws a TS error on any code after it that accesses error sub-properties e.g: `Property 'response' does not exist on type never`
      // this seems related to > ref: https://github.com/axios/axios/issues/5153
      // TEMP WORK-AROUND: check the error message instead until this is fixed in the axios lib (linked PR in the ref above not yet merged)
      // if (axios.isCancel(error)) {
      if (error.code === 'ERR_CANCELED') {
        console.log('ServerAPIClient - apiParseErrorResponse - throw ServerAPICancelledError...')
        return new ServerAPICancelledError()
      }
      else if (error.response) {
        if (error.response.status === 401) {
          const errorCode = error.response?.data?.error_code
          console.log('ServerAPIClient - apiParseErrorResponse (status === 401) - errorCode: ', errorCode)
          // TODO: ...
          // NB: some 401 responses like the forgot password token status check (when its expired) include a custom message we want to show/return, so for now we parse it here (may want to fork off the handling for 401's depending on certain criteria in the future?)
          if (error.response.data) {
            let errorData = error.response.data
            // console.log('ServerAPIClient - apiParseErrorResponse - errorData: ', errorData)
            if (errorData.error) {
              console.error('ServerAPIClient - apiParseErrorResponse - AxiosError - error: ', error) // log out the original error incase extra data gets lost in the current basic custom error handling below
              console.error('ServerAPIClient - apiParseErrorResponse - AxiosError - errorData: ', errorData)
              return new ServerAPIError(errorData.error, error.response.status, errorData.error_code, errorData)
            }
          }
        } else if (error.response.data) {
          let errorData = error.response.data
          // console.log('ServerAPIClient - apiParseErrorResponse - errorData: ', errorData)
          if (errorData.error) {
            console.error('ServerAPIClient - apiParseErrorResponse - AxiosError - error: ', error) // log out the original error incase extra data gets lost in the current basic custom error handling below
            console.error('ServerAPIClient - apiParseErrorResponse - AxiosError - errorData: ', errorData)
            return new ServerAPIError(errorData.error, error.response.status, errorData.error_code, errorData)
          }
        }
      } else if (error.request) { // client never received a response, or request never left
        // TODO: ?
      } else { // fallback error handling
        // TODO: ?
      }
    }
    return error
  }

  _isAxiosError (error: any): error is AxiosError<any> {
    // ref: https://www.reddit.com/r/typescript/comments/f91zlt/how_do_i_check_that_a_caught_error_matches_a/fipdbxd?utm_source=share&utm_medium=web2x&context=3
    return (error as AxiosError).isAxiosError !== undefined
  }

  private _apiConfig = (headers?: any, data?: Object, responseType?: ResponseType, abortController?: AbortController) => {
    const config = {
      headers: this._apiHeaders(headers),
      // TESTING: moved here, was mistakenly added within the 'headers' section before - TODO: not tested this change but it may fix the issue mentioned above
      ...(data ? { data: data } : {}), // add data to a delete call - ref: https://stackoverflow.com/a/56210828
      ...(responseType ? { responseType: responseType } : {}),
      ...(abortController ? { signal: abortController.signal } : {}) // cancel support - ref: https://axios-http.com/docs/cancellation
    }
    console.log('ServerAPIClient - _apiConfig - config: ', config)
    return config
  }

  private _apiHeaders = (headers?: any) => {
    return {
      ...(this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}),
      ...(this.authDeviceUUID ? { 'device-uuid': this.authDeviceUUID } : {}),
      ...headers
    }
  }
}

export default ServerAPIClient
