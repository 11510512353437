/**
 * hook: useMountEffect
 * 
 * A tiny helper react hook used to run a function only once on init within a functional react component
 * (& make it obvious thats what its doing, while also only having to disable strict ESLint rules the once here rather than every component that does it manually without this)
 * ref: https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once/56767883#56767883
 */

import { useEffect } from 'react'

export const useMountEffect = (effect: React.EffectCallback) =>
  // NB: disable the `exhaustive-deps` eslint rule for this, as we don't want (& shouldn't) add the `effect` arg to the deps array - ref: https://stackoverflow.com/a/58101280
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect,[])
