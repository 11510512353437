import React, { FC, SVGProps } from 'react'

import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg'
import { ReactComponent as ThreeDotsDownIcon } from '../../assets/icons/three dots down.svg'

import styles from './Icon.module.css'

export type IconName =
  | 'copy'
  | 'error'
  | 'home'
  | 'three dots down'

interface IconProps {
  className?: string
  color?: string
  name: IconName
  size?: number
}

const Icon = (props: IconProps) => {
  const { className, color = '#fff', name, size = 28 } = props
  let Icon: FC<SVGProps<SVGSVGElement>>
  switch (name) {
    case 'copy': Icon = CopyIcon; break
    case 'error': Icon = ErrorIcon; break
    case 'home': Icon = HomeIcon; break
    case 'three dots down': Icon = ThreeDotsDownIcon; break
  }
  return <Icon className={`${styles.icon} ${className}`} /*fill={color}*/ stroke={color} color={color} height={size} width={size} />
}

export default Icon
