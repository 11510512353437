import React from 'react'

import styles from './Loader.module.css'

// ref: https://loading.io/css/
// NB: also see https://cssloaders.github.io for good alternatives

export type LoaderName =
  | 'spin1'
  | 'spin2'
  | 'spin3'
  | 'spin4'

  interface LoaderProps {
    className?: string
    // color?: string
    name?: LoaderName
    size?: number
  }

const Loader = (props: LoaderProps) => {
  const { className, name = 'spin4', size = 40 } = props // color = '#fff',
  if (name !== 'spin1') {
    let spinnerClass = styles.loaderSpin2
    switch (name) {
      case 'spin3': spinnerClass = styles.loaderSpin3; break
      case 'spin4': spinnerClass = styles.loaderSpin4; break
    }
    return (
      <div className={styles.loader + ' ' + spinnerClass + (className ? ' ' + className : '')} style={{ width: size, height: size }}>

      </div>
    )
  }
  return (
    <div className={styles.loader + ' ' + styles.loaderSpin1 + (className ? ' ' + className : '')}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
