import React from 'react'

import styles from './PageContent.module.css'

interface PageContentProps {
  leftSidebar?: React.ReactNode
  rightSidebar?: React.ReactNode
  children?: React.ReactNode
}

const PageContent = (props: PageContentProps) => {
  const { leftSidebar, rightSidebar, children } = props
  return (
    <>
      {leftSidebar && (
        <div className={styles.sidebarLeft + ' ' + styles.sidebar + ' sidebar'}>
          {leftSidebar}
        </div>
      )}
      <div className={styles.content}>
        {children}
      </div>
      {rightSidebar && (
        <div className={styles.sidebarRight + ' ' + styles.sidebar + ' sidebar'}>
          {rightSidebar}
        </div>
      )}
    </>
  )
}

export default PageContent
