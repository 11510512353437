import React, { useContext, useEffect, useRef } from 'react'
import {
  Routes,
  Route,
  // Outlet,
  useNavigate,
  useLocation,
  Link,
  useOutletContext
} from 'react-router-dom'

import PrivateRoute, { AccessLevel, PrivateRouteRedirectState } from './components/PrivateRoute/PrivateRoute'

import { UserContext, UserStatus } from './providers'

import * as ROUTES from './constants/routes'

import AdminRouter from './pages/Admin/AdminRouter'

import LoadingPage from './pages/LoadingPage/LoadingPage'

import HomePage from './pages/Home/HomePage'

import LoginPage from './pages/Auth/Login/LoginPage'
import RegisterPage from './pages/Auth/Register/RegisterPage'
import ForgotPasswordPage from './pages/Auth/ForgotPassword/ForgotPasswordPage'
import ForgotPasswordResetPage from './pages/Auth/ForgotPassword/ForgotPasswordResetPage'
import InviteAcceptPage from './pages/Auth/Invite/InviteAcceptPage'

import VerifyEmailInputPage from './pages/Auth/Verify/VerifyEmailInputPage'

import AccountMenuPage from './pages/Account/AccountMenuPage/AccountMenuPage'
import AccountDetailsPage from './pages/Account/AccountDetailsPage/AccountDetailsPage'
import ChangePasswordPage from './pages/Account/ChangePassword/ChangePasswordPage'

import ProjectsPage from './pages/Projects/ProjectsPage'
import ProjectEditorPage from './pages/ProjectEditor/ProjectEditorPage'

import ChatDebugPage from './pages/ChatDebug/ChatDebugPage'
import AIModelsPages from './pages/AIModels/AIModelsPages'
import NodePlaygroundPage from './pages/NodePlayground/NodePlaygroundPage'

import NotFoundPage from './pages/NotFoundPage/NotFoundPage'

import UserHeaderMenu from './components/UserHeaderMenu/UserHeaderMenu'
import Button from './components/Button'

import styles from './components/BasePage/BasePage.module.css'

// react router v6 refs:
//  https://reactrouter.com/docs/en/v6/getting-started/overview

// TESTING:
export type AppLayoutContextType = {
  pageTitle?: string, setPageTitle: (title?: string) => void
  breadcrumb?: Array<React.ReactNode>, setBreadcrumb: (breadcrumb?: Array<React.ReactNode>) => void
  sidebarLeftContent?: React.ReactNode, setSidebarLeftContent: (content?: React.ReactNode) => void
  sidebarRightContent?: React.ReactNode, setSidebarRightContent: (content?: React.ReactNode) => void
}

export const useAppLayoutContext = () => {
  return useOutletContext<AppLayoutContextType>()
}

const AppRouter = () => {

  const userContext = useContext(UserContext)
  const { userStatus } = userContext.store

  const location = useLocation()
  const navigate = useNavigate()

  // redirect on login/logout
  // TODO: handle this in a dedicated provider instead of here?
  const prevUserStatusRef = useRef<UserStatus>()
  //const prevUserRef = useRef<User>()
  useEffect(() => {
    const prevUserStatus = prevUserStatusRef.current
    const userJustLoggedIn = !!(userStatus === UserStatus.loggedIn && prevUserStatus !== UserStatus.loggedIn) // NB: allow this to fire on page load (not just after a direct login) so it redirects away from login & similar pages
    const userJustLoggedOut = !!(userStatus === UserStatus.loggedOut && prevUserStatus === UserStatus.loggedIn)
    const currentPath = location.pathname
    console.log('AppRouter - componentDidUpdate - currentPath:', currentPath, 'userStatus:', UserStatus[userStatus], 'prevUserStatus:', (prevUserStatus !== undefined ? UserStatus[prevUserStatus] : prevUserStatus), ' userJustLoggedIn:', userJustLoggedIn, ' userJustLoggedOut:', userJustLoggedOut)
    if (userJustLoggedIn) {
      // check if we're on an auth focused page that we should auto navigate away from when logged in (login, register etc. pages)
      // TODO: this includes check fails for paths with params (e.g. the invite accept page once invite accepting (& registration) is complete)
      // TODO: UPDATE: if this is fixed down the line, ideally DON'T auto redirect away from the invite accept page when the invite was just accepted so the success message shows (or handle/show it in some other way worst case)
      if (ROUTES.AUTH_ROUTES.includes(currentPath)) {
        console.log('AppRouter - componentDidUpdate - JUST LOGGED IN (OR LOADED PAGE) - REDIRECT TO HOME/PREV..')
        // TODO: if adding invite support - check if a cached inviteToken exists & handle if so
        // TESTING: prev locationset) - ref: https://stackoverflow.com/a/73600806 & https://github.com/reach/router/issues/414
        console.log('AppRouter - componentDidUpdate - useLocation - location.state:', location.state)
        const state = location.state as PrivateRouteRedirectState
        if (state && state.prev) {
          navigate(state.prev)
        } else {
          navigate(ROUTES.HOME)
        }
      }
    } else if (userJustLoggedOut) {
      if (currentPath !== ROUTES.LOGIN) {
        console.log('AppRouter - componentDidUpdate - JUST LOGGED OUT - REDIRECT TO LOGIN..')
        navigate(ROUTES.LOGIN)
      }
    }
    prevUserStatusRef.current = userStatus
  // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus]) // NB: we don't need/want to listen for `user` changed here I think, only the userStatus


  if (userStatus === UserStatus.init || userStatus === UserStatus.loading) {
    return (
      <LoadingPage />
    )
  }

  return (
    // <BrowserRouter>
    <div className={styles.page}>
      <div className={styles.header}>
        <div className={styles.headerLeft + ' ' + styles.headerCol}>
          <Button iconName='home' iconSize={28} slim={false} iconColor={'#1f2127'} onClick={() => { navigate(ROUTES.HOME) }}></Button>
          {userStatus === UserStatus.loggedIn && (
            <Link to={ROUTES.HOME}>Gupta</Link>
          )}
        </div>
        <div className={styles.headerMain + ' ' + styles.headerCol}>

        </div>
        <div className={styles.headerRight + ' ' + styles.headerCol}>
          <UserHeaderMenu />
        </div>
        {/* <div className={styles.headerRow2}>
          {breadcrumb?.map((b, i) => (<div key={'bc_' + i} className={styles.breadcrumbItem}>{b}</div>))}
          <div className={styles.breadcrumbItem}><h1>{pageTitle}</h1></div>
        </div> */}
      </div>
      <main className={styles.body}>
      <Routes>
        <Route path="/" /*element={<AppLayout />}*/>

          {/* public routes */}
          <Route index element={<PrivateRoute accessLevel={userStatus === UserStatus.loggedIn ? AccessLevel.UserVerified : AccessLevel.NoAuth} outlet={<HomePage />} />} />
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
          <Route path={ROUTES.LOGIN_PASSWORD_FORGOT} element={<ForgotPasswordPage />} />
          <Route path={ROUTES.LOGIN_PASSWORD_RESET} element={<ForgotPasswordResetPage />} />
          <Route path={ROUTES.LOGIN_PASSWORD_RESET_TOKEN} element={<ForgotPasswordResetPage />} />
          <Route path={ROUTES.USER_INVITE_ACCEPT} element={<InviteAcceptPage />} />
          <Route path={ROUTES.USER_INVITE_ACCEPT_TOKEN} element={<InviteAcceptPage />} />
          
          {/* restricted user routes */}
          <Route path={ROUTES.USER_VERIFY} element={<PrivateRoute accessLevel={AccessLevel.UserUnverified} outlet={<VerifyEmailInputPage />} />} />
          <Route path={ROUTES.USER_VERIFY_TOKEN} element={<PrivateRoute accessLevel={AccessLevel.UserUnverified} outlet={<VerifyEmailInputPage />} />} />
          <Route path={ROUTES.ACCOUNT} element={<PrivateRoute accessLevel={AccessLevel.UserVerified} outlet={<AccountMenuPage />} />} />
          <Route path={ROUTES.ACCOUNT_DETAILS} element={<PrivateRoute accessLevel={AccessLevel.UserVerified} outlet={<AccountDetailsPage />} />} />
          <Route path={ROUTES.ACCOUNT_PASSWORD} element={<PrivateRoute accessLevel={AccessLevel.UserVerified} outlet={<ChangePasswordPage />} />} />
          
          {/* */}
          <Route path={ROUTES.PROJECTS} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<ProjectsPage />} />} />
          <Route path={ROUTES.PROJECT_EDITOR} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<ProjectEditorPage />} />} />

          <Route path={ROUTES.CHAT_DEBUG} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<ChatDebugPage />} />} />
          <Route path={ROUTES.MODELS} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<AIModelsPages />} />} />
          <Route path={ROUTES.NODE_PLAYGROUND} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<NodePlaygroundPage />} />} />

          {/* admin only routes */}
          {/* <Route path={ROUTES.ADMIN} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<AdminMenuPage />} />} />
          <Route path={ROUTES.ADMIN_USERS} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<AdminUsersPage />} />} /> */}
          <Route path={ROUTES.ADMIN_ROUTER} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<AdminRouter />} />} />

          {/* test/debug routes */}
          <Route path="test-unverified" element={<PrivateRoute accessLevel={AccessLevel.UserUnverified} outlet={<>UNVERIFIED</>} />} />
          <Route path="test-verified" element={<PrivateRoute accessLevel={AccessLevel.UserVerified} outlet={<>VERIFIED</>} />} />

          {/* unknown route fallback */}
          <Route path="*" element={<NotFoundPage />} />

        </Route>
      </Routes>
      </main>
    </div>
    // </BrowserRouter>
  )
}

export default AppRouter
