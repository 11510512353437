import React from 'react'

import styles from './Message.module.css'

interface MessageProps {
  positive?: boolean
  warning?: boolean
  error?: boolean
  children?: React.ReactNode
  className?: string
}

const Message = (props: MessageProps) => {
  const { positive, warning, error, children, className } = props
  return (
    <div
      className={
        (styles.message) +
        (positive ? ' ' + styles.positive : '') +
        (warning ? ' ' + styles.warning : '') +
        (error ? ' ' + styles.error : '') +
        (className ? ' ' + className : '')
      }
    >
      {children}
    </div>
  )
}

export default Message
