import React from 'react'

import styles from './Header.module.css'

interface HeaderProps {
  type?: 'h1' | 'h2' | 'h3' | 'h4'
  title?: string | React.ReactNode
  desc?: string | React.ReactNode
  rightOptions?: React.ReactNode
  children?: React.ReactNode
}

const Header = (props: HeaderProps) => {
  const { type = 'h1', title, desc, rightOptions, children } = props
  if ((title || desc) && children) {
    /// TODO: halt or throw an error of some kind if both are set?
  }
  // NB: temp adding `<>&nbsp;</>` if no content in the current required field elements to keep the height consistent (TODO: should really do that via css!)
  const CustomTag = `${type}` as keyof JSX.IntrinsicElements // ref: https://stackoverflow.com/a/59685929
  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <CustomTag>
          {title ?? children ?? <>&nbsp;</>}
        </CustomTag>
        <span className={styles.desc}>{desc ?? <>&nbsp;</>}</span>{/* NB: always adding this element even if no `desc` set so spacing remains consistent */}
      </div>
      {rightOptions && (
        <div className={styles.headerRight}>
          {rightOptions}
        </div>
      )}
    </div>
  )
}

export default Header
