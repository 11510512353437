
class NodeRunConfig {
  constructor(
    public model?: string,
    public temp?: number,
    public topP?: number,
    public maxTokens?: number,
    public strictYOrder?: boolean,
    public systemMsg?: string
  ) {}

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : NodeRunConfig | null {
    if (!json) return null
    console.log('NodeRunConfig - fromJSON - json:', json)
    const config = new NodeRunConfig(
      json.model,
      json.temp,
      json.topP,
      json.maxTokens,
      json.strictYOrder,
      json.systemMsg
    )
    console.log('NodeRunConfig - fromJSON - config:', config)
    return config
  }
}

export default NodeRunConfig
