
// user reset password token details, for a forgot password token after a req has been made to reset it (non admin)
class UserResetPassLookup {

  json?: any // raw json data for debugging only

  constructor (
    public email: string,
    public createdAt: Date,
    //public updatedAt?: Date,
    public expiresAt?: Date,
    public currentAt?: Date,
    public expiresIn?: number
  ) {
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : UserResetPassLookup | null {
    if (!json) return null
    const userInvite = new UserResetPassLookup(
      json.email,
      new Date(json.createdAt),
      //json.updatedAt ? new Date(json.updatedAt) : undefined,
      json.expiresAt ? new Date(json.expiresAt) : undefined,
      json.currentAt ? new Date(json.currentAt) : undefined,
      json.expiresIn
    )
    return userInvite
  }
}

export default UserResetPassLookup
