import React, { useContext } from 'react'

import { ChatProvider, ServerContext } from '../../providers'

import PageContent from '../../components/PageContent'
import ChatDebugView from './ChatDebugView'

import styles from './ChatDebugPage.module.css'

const ChatDebugPage = () => {
  const serverContext = useContext(ServerContext)
  return (
    <PageContent>
      <div className={styles.page}>
        <ChatProvider apiClient={serverContext.store.apiClient} openAIApiClient={serverContext.store.openAIApiClient}>
          <ChatDebugView />
        </ChatProvider>
      </div>
    </PageContent>
  )
}

export default ChatDebugPage
