
class Model {
  constructor(
    public id: string,
    public data: any
  ) {}

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : Model | null {
    if (!json) return null
    const user = new Model(
      json.id ?? json.id,
      json
    )
    return user
  }
}

export default Model
