import React, { useContext, useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { AuthContext } from '../../../providers'

import * as ROUTES from '../../../constants/routes'

import BasePage from '../../../components/BasePage/BasePage'
import CenterLayout from '../../../components/CenterLayout'

const VerifyEmailInputPage = () => {

  const { verifyToken } = useParams()
  
  const authContext = useContext(AuthContext)

  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [veryifyResult, setVeryifyResult] = useState<boolean>(false)
  const [verifyError, setVerifyError] = useState<Error | undefined>(undefined)
  
  const runVerifyEmailToken = async () => {
    console.log('VerifyEmailInputPage - runVerifyEmailToken - verifyToken:', verifyToken)
    
    // NB: currently requiring the user to be logged in before being able to verify their email (checks handled at the router level before we reach here)
    const isLoggedIn = authContext.actions.isLoggedIn()
    if (!isLoggedIn) {
      console.log('VerifyEmailInputPage - runVerifyEmailToken - NOT LOGGED IN - HALT')
      return // NB: should already be handled by the base routing code
    }

    // redirect to the home page if the user is already verified & attempts to call the verify page
    const isLoggedInAndVerified = authContext.actions.isLoggedInAndVerified()
    if (isLoggedInAndVerified) {
      console.log('VerifyEmailInputPage - runVerifyEmailToken - ALREADY VERIFIED - HALT')
      navigate(ROUTES.HOME)
      return
    }

    // verify the user with the supplied token
    setIsSubmitting(true)
    setVeryifyResult(false)
    setVerifyError(undefined)
    try {
      await authContext.actions.verifyEmailToken(verifyToken ?? '')
      setVeryifyResult(true)
      setIsSubmitting(false)
    } catch (error: any) {
      setVeryifyResult(false)
      setIsSubmitting(false)
      setVerifyError(error)
    }
  }

  useEffect(() => {
      runVerifyEmailToken()
    },
    // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onSuccessClick = () => {
    navigate(ROUTES.HOME)
  }

  return (
    <BasePage>
      <CenterLayout>
        {verifyToken && isSubmitting && (<>Verifying...</>)}
        {verifyToken && isSubmitting === false && veryifyResult && (
          <div>{/* TODO: positive message */}
            <h3>Success</h3>
            <p>You&apos;re now verified!</p>
            <div>
              <button onClick={onSuccessClick}>Continue</button>
            </div>
          </div>
        )}
        {verifyToken && isSubmitting === false && veryifyResult === false && (
          <div>{/* TODO: negative message */}
            <h3>Error</h3>
            <p>Failed to verify the token</p>
          </div>
        )}
        {verifyError && (
          <div>{/* TODO: negative message */}
            <h3>Verify Error</h3>
            <p>{verifyError.message}</p>
          </div>
        )}
      </CenterLayout>
    </BasePage>
  )
}

export default VerifyEmailInputPage
