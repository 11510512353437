import React, { useEffect, useRef, useState } from 'react'
import { useMountEffect } from '../../hooks'

// textarea form field with auto sizing support (NB: only for certain use cases / scenarios currently)
// refs:
//  https://medium.com/@oherterich/creating-a-textarea-with-dynamic-height-using-react-and-typescript-5ed2d78d9848

export interface ChatMessageTextAreaProps {
  id?: string
  name?: string
  required?: boolean
  rows?: number
  cols?: number
  value?: string // | number
  defaultValue?: string // | number
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  minHeight?: number
}

const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  minHeight?: number
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px"
      let scrollHeight = textAreaRef.scrollHeight
      if (minHeight && scrollHeight < minHeight) {
        scrollHeight = minHeight
      }
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px"
    }
  }, [textAreaRef, value, minHeight])
}

const ChatMessageTextArea = (props: ChatMessageTextAreaProps) => {
  const {
    id,
    name,
    required,
    rows,
    cols,
    value,
    defaultValue,
    onChange: _onChange,
    minHeight
  } = props
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [textValue, setTextValue] = useState<string>('')
  useAutosizeTextArea(textAreaRef.current, textValue, minHeight)
  useMountEffect(() => {
    console.log('ChatMessageTextArea - useMountEffect')
    if (value) setTextValue(value)
    else if (defaultValue) setTextValue(defaultValue)
  })
  useEffect(() => {
    setTextValue(value ?? '')
  }, [value])
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // console.log('ChatMessageTextArea - onChange - event.target?.value:', event.target?.value)
    setTextValue(event.target?.value ?? '')
    if (_onChange) _onChange(event)
  }
  return (
    <textarea
      ref={textAreaRef}
      id={id}
      name={name}
      required={required}
      rows={rows}
      cols={cols}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
    ></textarea>
  )
}

export default ChatMessageTextArea
