import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'

import { ChatProvider, ProjectEditorProvider, ProjectProvider, ServerContext } from '../../providers'

import PageContent from '../../components/PageContent'
import ProjectEditorView from './ProjectEditorView'

import styles from './ProjectEditorPage.module.css'

const ProjectEditorPage = () => {
  const { projectId: projectIdStr } = useParams()
  const projectId = projectIdStr !== undefined && !isNaN(parseInt(projectIdStr)) ? parseInt(projectIdStr) : undefined
  console.log('ProjectEditorPage - render - projectId:', projectId)
  const serverContext = useContext(ServerContext)
  return (
    <PageContent>
      <div className={styles.page}>
        <ProjectProvider apiClient={serverContext.store.apiClient}>
          <ProjectEditorProvider>
            <ChatProvider apiClient={serverContext.store.apiClient} openAIApiClient={serverContext.store.openAIApiClient}>
              {projectId && (
                <ReactFlowProvider>
                  <ProjectEditorView projectId={projectId} />
                </ReactFlowProvider>
              )}
              {!projectId && (<>WARNING: Invalid Project</>)/* TODO: proper error */}
            </ChatProvider>
          </ProjectEditorProvider>
        </ProjectProvider>
      </div>
    </PageContent>
  )
}

export default ProjectEditorPage
