/**
 * hook: useUnmountEffect
 * 
 * A tiny helper react hook used to run a function when a functional react component unmounts (an alternative to class based the `componentWillUnmount` function)
 * NB: this is a kind of inversion/extension of the useMountEffect hook (see that for additional refs)
 * ref: https://robertmarshall.dev/blog/componentwillunmount-functional-components-react/
 */

import { useEffect } from 'react'

export const useUnmountEffect = (effect: React.EffectCallback) =>
  useEffect(() => {
    return () => {
      effect()
    }
  // NB: disable the `exhaustive-deps` eslint rule for this, as we don't want (& shouldn't) add the `effect` arg to the deps array - ref: https://stackoverflow.com/a/58101280
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
