/**
 * PrivateRoute
 * - a custom react router route component that adds some basic auth requirement checks to route declarations
 * - it either shows/loads the target page/route if the auth checks pass
 * - or shows a related error page if logged in but no valid access, or redirects to the login page if no/invalid auth is found
 * refs: 
 *  https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
 *  ORIG: https://stackoverflow.com/questions/43164554/how-to-implement-authenticated-routes-in-react-router-4
 */
import React, { useContext } from 'react'
import { /* Route, */ Navigate, /*, RouteComponentProps*/ 
useLocation} from 'react-router-dom'

import * as ROUTES from '../../constants/routes' // TODO: can we make move this import out of this component & instead pass in as a prop/config in some way? (keep this component generic)
import VerifyEmailWarningPage from '../../pages/Auth/Verify/VerifyEmailWarningPage'
//import { User } from '../../models'
import { /* AuthContext, */ UserContext } from '../../providers'
// import BasePage from '../BasePage/BasePage'
// import CenterLayout from '../CenterLayout'

// import VerifyEmailWarningPage from 'src/core/pages/auth/Verify/VerifyEmailWarningPage'
// import AccessDeniedPage from 'src/core/pages/auth/AccessDenied/AccessDeniedPage'
// const VerifyEmailWarningPage = () => {
//   return (
//     <BasePage><CenterLayout>VERIFY EMAIL</CenterLayout></BasePage>
//   )
// }

interface AccessDeniedPageProps { message?: string }
const AccessDeniedPage = (props: AccessDeniedPageProps) => { return (<>{props.message ?? 'ACCESS DENIED'}</>) }

// describes the custom react router v6 state we use to specify the `prev` pathname in the `Navigate` call (e.g. if your not logged in)
// ref: https://github.com/reach/router/issues/414
export interface PrivateRouteRedirectState {
  prev: string
}

export enum AccessLevel {
  NoAuth, // not logged in
  UserUnverified, // logged in but email isn't verified/confifmed yet, so access to some areas is limited
  UserVerified, // logged in with a verified/confirmed email
  Admin // NB: could pull this out to a separate 'roles' type field, but for now this seemed good enough
}

type TParams = {
  //authUser?: User
  accessLevel: AccessLevel
  outlet: JSX.Element
  //args?: any
}
const PrivateRoute = ({ accessLevel, outlet, ...rest }: TParams) => { //authUser, 

  // const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const { user: authUser } = userContext.store
  const location = useLocation()
  
  let allowAccess = false
  if (accessLevel === AccessLevel.NoAuth) { // no auth required - all users allowed access
    allowAccess = true
  } else if (accessLevel === AccessLevel.UserUnverified) { // unverified users allowed, pages that allow access without the user verifying their email address
    allowAccess = !!((authUser))
  } else if (accessLevel === AccessLevel.UserVerified) { // must be a verified user
    allowAccess = !!(authUser && authUser.isVerified)
    if (!allowAccess) console.log('PrivateRoute - UserVerified CHECK FAILED - authUser:', authUser)
  } else if (accessLevel === AccessLevel.Admin) { // must be a verified admin user
    allowAccess = !!(authUser && authUser.isVerified && authUser.roles && authUser.roles.includes('admin'))
    if (!allowAccess) console.log('PrivateRoute - Admin CHECK FAILED - authUser:', authUser)
  }

  // TESTING: if the user doesn't have access - redirect to login it no-auth or anon, show verify email message if that was required & failed, or show an access denied message otherwise
  const redirectPath = ROUTES.LOGIN
  let showAccessDenied = false
  let showVerifyEmail = false
  const accessDeniedMsg = 'ACCESS DENIED'
  if (!allowAccess || !authUser) {
    if (authUser) {
      showAccessDenied = true
      if (authUser && !authUser.isVerified) {
        showVerifyEmail = true
      }
    }
    // NB: will use the redirectPath if no user
  }
  
  if (allowAccess) return outlet
  console.log('PrivateRoute - allowAccess == false...')
  if (showAccessDenied) {
    if (showVerifyEmail) {
      console.log('PrivateRoute - VerifyEmailWarningPage...')
      return (<VerifyEmailWarningPage />)
    } else {
      console.log('PrivateRoute - AccessDeniedPage...')
      return (<AccessDeniedPage message={accessDeniedMsg} />)
    }
  } else {
    console.log('PrivateRoute - navigate:', redirectPath, 'location(prev):', location)
    // add the current location to the router state so we can redirect back to it later (e.g after login) - ref: https://stackoverflow.com/a/73600806
    return <Navigate to={{ pathname: redirectPath }} state={{ prev: location.pathname }} />
  }
}

export default PrivateRoute
