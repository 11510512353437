import React from 'react'
import { useOutletContext } from 'react-router-dom'
// import UserHeaderMenu from '../UserHeaderMenu/UserHeaderMenu'

// import * as ROUTES from '../../constants/routes'

import styles from './BasePage.module.css'
import { AppLayoutContextType } from '../../AppRouter'
import { useMountEffect } from '../../hooks'

export interface BasePageProps {
  pageTitle?: string
  breakcrumb?: Array<React.ReactNode>
  children?: React.ReactNode
}

/*
const BasePageOriginal = (props: BasePageProps) => {
  const { pageTitle, breakcrumb, children } = props
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div className={styles.headerRow1}>
          <div className={styles.headerLeft}>
            <SocketStatusView />
          </div>
          <div className={styles.headerTitle}><Link to={ROUTES.HOME}>HOME</Link></div>
          <div className={styles.headerRight}>
            <UserHeaderMenu />
          </div>
        </div>
        <div className={styles.headerRow2}>
          {breakcrumb?.map((b, i) => (<div key={'bc_' + i} className={styles.breadcrumbItem}>{b}</div>))}
          <div className={styles.breadcrumbItem}><h1>{pageTitle}</h1></div>
        </div>
      </div>
      <main className={styles.body}>
        <div className={styles.content}>
          {children}
        </div>
        <SocketOutputView />
      </main>
    </div>
  )
}
*/

const BasePage = (props: BasePageProps) => {
  const { pageTitle, breakcrumb, children } = props

  // TESTING: pass the page specific title & breadcrumb back to the router outlet (via `useOutletContext`)
  const outletContext = useOutletContext<AppLayoutContextType>()
  console.log('BasePage - render - outletContext:', outletContext, ' pageTitle:', props.pageTitle)
  useMountEffect(() => {
    if (outletContext) {
      outletContext.setPageTitle(pageTitle)
      outletContext.setBreadcrumb(breakcrumb)
    }
  })
  // TESTING: pass the page specific title & breadcrumb back to the router outlet (via `useOutletContext`) when it loads or changes
  // WARNING: can cause an infinite re-render loop if not careful!
  // TODO: if re-enabling this, add a way to properly check if the breadcrumb has changed before (re)setting it or it can trigger an infinite loop
  // TOOD: ..started to add array check handling, but decided with other changes we no longer need to support changing of the breadcrumb once its rendered
  // useEffect(() => {
  //   console.log('BasePage - useEffect - [pageTitle, breakcrumb] - pageTitle:', pageTitle, ' outletContext.pageTitle:', outletContext?.pageTitle, ' breakcrumb:', breakcrumb, ' outletContext.breadcrumb', outletContext?.breadcrumb)
  //   if (outletContext) {
  //     if (outletContext.pageTitle !== pageTitle) outletContext.setPageTitle(pageTitle)
  //     console.log('BasePage - useEffect - breakcrumb:', breakcrumb)
  //     const arrayCompare = breakcrumb?.every((b) => {
  //       if (!outletContext.breadcrumb) return false
  //       console.log('BasePage - useEffect - outletContext.breadcrumb.indexOf(b):', outletContext.breadcrumb.indexOf(b), ' b:', b)
  //       return outletContext.breadcrumb.indexOf(b) >= 0
  //     })
  //     console.log('BasePage - useEffect - breakcrumb?.length:', breakcrumb?.length, ' outletContext.breadcrumb?.length:', outletContext.breadcrumb?.length, ' arrayCompare:', arrayCompare)
  //     if (breakcrumb?.length !== outletContext.breadcrumb?.length) { // || !breakcrumb?.every((b) => outletContext.breadcrumb && outletContext.breadcrumb.indexOf(b) >= 0)) {
  //       outletContext.setBreadcrumb(breakcrumb)
  //     }
  //   }
  // }, [pageTitle, breakcrumb])
  return (
    <div className={styles.page}>
      {children}
    </div>
  )
}

export default BasePage
