import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReactFlow, {
  // useNodesState,
  // useEdgesState,
  useReactFlow,
  addEdge,
  updateEdge,
  applyNodeChanges,
  applyEdgeChanges,
  getOutgoers,
  FitViewOptions,
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  Connection,
  Controls,
  Background,
  BackgroundVariant,
  Panel,
  Viewport,
  ReactFlowJsonObject,
  HandleType,
} from 'reactflow'

import { useMountEffect } from 'src/hooks'

import { ChatContext, ProjectContext, ProjectEditorContext } from 'src/providers'
import { APIMode, ChatCompletionResult } from 'src/providers/ChatProvider'

import { ChatMessage, ChatMessageRole } from '../../models/ChatMessage'
import { Project } from 'src/models'
import NodeRunConfig from 'src/models/NodeRunConfig'

import { ServerAPICancelledError } from 'src/services/ServerAPIClient'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import LoaderView from 'src/components/LoaderView/LoaderView'
import Modal from 'src/components/Modal'
import Select from 'src/components/Select'

import UserInputNode, { UserInputNodeDataKeys } from './nodes/UserInputNode'
import UserInputSidebar from './sidebars/UserInputSidebar'

import ProjectEditorSettingsForm from './ProjectEditorSettingsForm'

import { AI_DIRECT_MODE_ENABLED, AI_MODEL_TEMPERATURE_DEFAULT, AI_RUN_ORDER_STRICT_Y_DEFAULT, EDITOR_SHOW_SIMULATED_RUN_MODAL } from '../../constants/config'

import styles from './ProjectEditorView.module.css'
import 'reactflow/dist/style.css'

/**
 * NOTE: when updating reactflow node data you must update the existing object instead of replacing it or its node in the array of nodes
 * e.g:
 * ```
 *   setNodes((nds) => nds.map((nd) => {
 *     nd.data = { ...nd.data, <DATA UPDATES HERE...> }
 *     return nd
 *   }))
 * ```
 */

// const initialNodes: Node[] = []
// const initialEdges: Edge[] = []

const nodeTypes = {
  userInputNode: UserInputNode,
}
const edgeTypes = {}

const defaultViewport: Viewport = {
  x: 0,
  y: 0,
  zoom: 1.0 // NB: do the FitViewOptions override this?
}

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
}

const proOptions = { hideAttribution: true }

// type NodeRunOrder = { id: string, order: number, childOrder?: Array<NodeRunOrder> }

type EdgeUpdateData = { edge: Edge<any>, handleType: HandleType}

export interface ProjectEditorViewProps {
  projectId: number
}

const ProjectEditorView = (props: ProjectEditorViewProps) => {
  const { projectId } = props

  const mounted = useRef(false)

  const { actions: projectActions, store: projectStore } = useContext(ProjectContext)
  const { projectsLoaded } = projectStore

  const projectEditorContext = useContext(ProjectEditorContext)
  const { nodes, edges, dupeNodeId } = projectEditorContext.store
  const { setNodes, setEdges } = projectEditorContext.actions

  const { actions: chatActions } = useContext(ChatContext)
  
  const [project, setProject] = useState<Project | undefined>()
  const [projectError, setProjectError] = useState<Error | undefined>()

  const [apiMode, setApiMode] = useState<APIMode>(APIMode.direct)

  // -------
  
  const _defaultModelId = 'gpt-3.5-turbo-0301'

  // -------

  const reactFlowInstance = useReactFlow()
  const edgeUpdateSuccessful = useRef(true)
  const edgeUpdateData = useRef<EdgeUpdateData | undefined>()

  // const [nodes, setNodes] = useState<Node[]>(initialNodes)
  // const [edges, setEdges] = useState<Edge[]>(initialEdges)
  const [variant] = useState<BackgroundVariant>(BackgroundVariant.Dots) // setVariant

  const [selectedNode, setSelectedNode] = useState<Node | undefined>()
  const [selectedNodeParentNodes, setSelectedNodeParentNodes] = useState<Array<Node> | undefined>()
  const [selectedNodePrompt, setSelectedNodePrompt] = useState<string | undefined>()
  // const [copiedNode, setCopiedNode] = useState<Node | undefined>()

  const [isRunning, setIsRunning] = useState<boolean>(false)
  const [isSimulated, setIsSimulated] = useState<boolean>(false)
  const [runningNode, setRunningNode] = useState<Node | undefined>()
  const [currentRunNodeCount, setCurrentRunNodeCount] = useState<number>(0)
  const [totalRunNodeCount, setTotalRunNodeCount] = useState<number>(0)
  const [runError, setRunError] = useState<Error | undefined>()

  const isCancellingRunRef = useRef<boolean>(false)

  const [_showSettingsModal, setShowSettingsModal] = useState<boolean>(false)
  const [_showConfirmRunModal, setShowConfirmRunModal] = useState<boolean>(false)

  // -------

  useEffect(() => {
    console.log('ProjectEditorView - MOUNTED')
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('ProjectEditorView - UN-MOUNTED')
    }
  }, [])

  // -------

  // direct parents (1 level up)
  const getParentNodes = useCallback((node: Node): Array<Node> | undefined => {
    // console.log('UserInputNode - getParentNodes - node:', node, ' edges:', edges)
    const parentEdges = edges.filter(edge => edge.target === node.id)
    // console.log('UserInputNode - getParentNodes - parentEdges:', parentEdges)
    if (parentEdges.length > 0) {
      parentEdges.sort((a, b) => ((a.targetHandle ? parseInt(a.targetHandle) : 0) < (b.targetHandle ? parseInt(b.targetHandle) : 0)) ? -1 : 1) // sort by targetHandle order
      //console.log('UserInputNode - getParentNodes - parentEdges(SORTED):', parentEdges)
      const parentNodes: Array<Node> = []
      for (const parentEdge of parentEdges) {
        const parentNodeId = parentEdge.source
        const parentNode = nodes.find(n => n.id === parentNodeId)
        if (parentNode) parentNodes.push(parentNode)
      }
      if (parentNodes.length > 0) return parentNodes
    }
    return undefined
  }, [edges, nodes])

  // full parent tree (all levels above across multiple parents)
  // TODO: how to handle the return value, should each Node have an option 'parents' type ref/value (or equiv separate data structure)
  // const getParentNodes = useCallback((node: Node): Array<Node> | undefined => {
  // }

  const canConnectSourceToTarget = useCallback((sourceId: string, targetId: string, targetHandle: string) => {
    console.log('UserInputNode - canConnectSourceToTarget - sourceId:', sourceId, ' targetId:', targetId, ' targetHandle:', targetHandle, ' edgeUpdateData:', edgeUpdateData.current)
    const edges = projectEditorContext.store.edges
    console.log('UserInputNode - canConnectSourceToTarget - edges:', edges)
    // check if we're updating/editing an existing edge or adding/creating a new one
    const isUpdatingEdge = edgeUpdateData.current !== undefined
    console.log('UserInputNode - canConnectSourceToTarget - isUpdatingEdge:', isUpdatingEdge)
    // check if the source node already links to the target node // (but isn't the source node itself, being moved to a different handle)
    // TODO: how to tell the difference between a new edge & moving an existing one?
    // UPDATE: see the new `isUpdatingEdge` bool & the `edgeUpdateData` ref its based off
    const sourceToTargetEdge = edges.find((e) => e.source === sourceId && e.target === targetId) // && e.targetHandle !== targetHandle)
    // check if the target handle already has an edge attached/assigned
    const targetHandleEdge = edges.find((e) => e.target === targetId && e.targetHandle === targetHandle)
    console.log('UserInputNode - canConnectSourceToTarget - sourceToTargetEdge:', sourceToTargetEdge, ' targetHandleEdge:', targetHandleEdge)
    // halt if either are true/assigned
    return ((sourceToTargetEdge === undefined || isUpdatingEdge) && targetHandleEdge === undefined)
  }, [projectEditorContext.store.edges])

  // -------

  const _calcNextRunNode = useCallback((node: Node, parentNodeIds?: Array<string>, parentLookup: boolean = false): { node: Node, parentNodeIds?: Array<string> } | undefined => {
    const strictYOrder = project?.runConfig?.strictYOrder ?? AI_RUN_ORDER_STRICT_Y_DEFAULT
    console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' parentNodeIds:', parentNodeIds, ' parentLookup:', parentLookup, ' (strictYOrder: ' + strictYOrder + ')')

    if (strictYOrder) {
      // `strictYOrder === true` > prioritise node Y position over anything else (ignoring the node tree parent>child relationships for run order calcs)

      const allNodes = [...nodes]
      allNodes.sort((a, b) => (a.position.y < b.position.y) ? -1 : 1) // sort by Y pos (regardless of the tree structure)
      if (allNodes.length > 0) {
        const nodeIndex = allNodes?.findIndex((an) => an.id === node.id)
        const nextNodeIndex = nodeIndex >= 0 ? nodeIndex + 1 : nodeIndex
        // console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - nodeIndex:', nodeIndex, ' nextNodeIndex:', nextNodeIndex)
        if (nextNodeIndex >= 0 && nextNodeIndex < allNodes.length) {
          return { node: allNodes[nextNodeIndex] }
        }
      }

    } else {
      // `strictYOrder === false` > prioritise node tree parent>child relationships, with Y ordering just used to sort sibling run order
      console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - strictYOrder === false - parentNodeIds:', parentNodeIds)
      // const parentLookup = parentNodeIds && parentNodeIds.length > 0

      // if this node has child nodes, process them first...
      // skip if we're doing a parent lookup from a recursive call made below by a child node (when its come to the end of its siblings list)
      if (!parentLookup) {
        const childNodes = getOutgoers(node, nodes, edges)
        childNodes.sort((a, b) => (a.position.y < b.position.y) ? -1 : 1) // sort by Y pos vs the other nodes attached to the same parent
        console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - childNodes(SORTED):', childNodes?.map(n => n.id))
        if (childNodes.length > 0) {
          return { node: childNodes[0], parentNodeIds }
        }
        // TODO: halt if we reach here or allow to continue?
      }

      // otherwise check for sibling nodes to run next
      // TESTING: updated to handle multiple node inputs, now also requires a `prevNode` to be set so we know which parent was the one that ran this node
      const parentNodes = getParentNodes(node)
      console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - parentNodes:', parentNodes)
      const parentNode = parentNodeIds !== undefined && parentNodeIds.length > 0 ? parentNodes?.find((n) => n.id === parentNodeIds[parentNodeIds.length-1]) : undefined
      console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - parentNode.id:', parentNode?.id)
      if (parentNode) {
        const siblingNodes = getOutgoers(parentNode, nodes, edges)
        siblingNodes.sort((a, b) => (a.position.y < b.position.y) ? -1 : 1) // sort by Y pos vs the other nodes attached to the same parent
        if (siblingNodes && siblingNodes.length > 0) {
          // console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - siblingNodes(SORTED):', siblingNodes?.map(n => n.id))
          const nodeIndex = siblingNodes?.findIndex((sn) => sn.id === node.id)
          const nextNodeIndex = nodeIndex >= 0 ? nodeIndex + 1 : nodeIndex
          // console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - nodeIndex:', nodeIndex, ' nextNodeIndex:', nextNodeIndex)
          if (nextNodeIndex >= 0 && nextNodeIndex < siblingNodes.length) {
            return { node: siblingNodes[nextNodeIndex], parentNodeIds }
          }
        }
        // TESTING: no siblings so check the parent a level up (if this parent wasn't the start node)
        console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - NO SIBLINGS - CHECK PARENT...')
        //if (!parentNode.data.start) {
          // const newPrevNodes = parentNodeIds && parentNodeIds.length > 1 ? parentNodeIds.splice(-1) : undefined
          const newPrevNodes = parentNodeIds
          if (newPrevNodes && newPrevNodes.length > 1) newPrevNodes.splice(-1)
          console.log('ProjectEditorView - _calcNextRunNode - node.id:', node.id, ' - newPrevNodes:', newPrevNodes, ' parentNodeIds(PREV):', parentNodeIds)
          return _calcNextRunNode(parentNode, newPrevNodes, true)
        //}
      }
    }
    return undefined
  }, [edges, getParentNodes, nodes, project?.runConfig?.strictYOrder])
  
  const calcNodesRunOrder = useCallback(() => {
    // console.log('ProjectEditorView - calcNodesRunOrder - isRunning:', isRunning)

    // don't (re)calc while a run is in progress
    if (isRunning) {
      console.log('ProjectEditorView - calcNodesRunOrder - ERROR: CURRENTLY RUNNING - SKIP RUN ORDER CALC <<<')
      return // TODO: flag/indicate theres an error?
    }

    const startNode = nodes.find((n) => n.data.start)
    if (!startNode) {
      console.log('ProjectEditorView - calcNodesRunOrder - ERROR: NO START NODE')
      return // TODO: flag/indicate theres an error?
    }

    // clone the nodes array into a working copy (the state is updated once all node orders have been calculated & updated in their relevant object)
    const _nodes = [...nodes]

    // clear all existing node run orders from prev calcs
    for (const node of _nodes) {
      if (node.type !== 'userInputNode') { continue }
      const nodeData = node.data
      nodeData.nodeOrder = undefined
    }

    const _startNode = _nodes.find((n) => n.data.start)
    if (!_startNode) return
    _startNode.data.nodeOrder = 1

    const _stopNode = _nodes.find((n) => n.data.stop)

    // OLD/ORIG: pre strict Y ordering support
    /*
    const nodeChildOrder = _calcChildNodesRunOrder(_startNode, 1)
    console.log('ProjectEditorView - calcNodesRunOrder - nodeChildOrder:', nodeChildOrder)
    const nodeOrder: NodeRunOrder = { id: _startNode.id, order: _startNode.data.nodeOrder, childOrder: nodeChildOrder }
    const nodeOrderFlat = nodeOrder ? getNodeRunOrderFlat([nodeOrder]) : undefined
    console.log('ProjectEditorView - calcNodesRunOrder - nodeOrderFlat:', nodeOrderFlat)
    console.log('ProjectEditorView - calcNodesRunOrder - _nodes(AFTER):', _nodes)
    setNodes((nds) => nds.map((nd) => {
      // const _nd = _nodes.find((_n) => _n.id === nd.id)
      // if (_nd) nd.data = { ...nd.data, nodeOrder: _nd.data.nodeOrder }
      const nodeOrder = nodeOrderFlat?.find((no) => no.id === nd.id)
      if (nodeOrder) nd.data = { ...nd.data, nodeOrder: nodeOrder.order }
      return nd
    }))
    */

    // NEW: supports strict Y ordering enabled or disabled
    const maxNodeCount = 1000 // TODO: set a suitable max count/attempt/timeout (to stop infinite loops if calcs are wrong) & move to a config var
    let nodeOrderLookup = new Map<string, number>()
    nodeOrderLookup.set(_startNode.id, 1)
    if (_startNode && (!_stopNode || _startNode.id !== _stopNode.id)) {
      let nextNodeOrder = 2
      let nextNodeData = _calcNextRunNode(_startNode)
      console.log('ProjectEditorView - calcNodesRunOrder - nextNodeData:', nextNodeData)
      let nextNode = nextNodeData?.node
      if (nextNode) nodeOrderLookup.set(nextNode.id, nextNodeOrder)
      if (nextNode && _stopNode && nextNode.id === _stopNode.id) nextNode = undefined
      // console.log('ProjectEditorView - calcNodesRunOrder - nextNode.id(' + nextNodeOrder + '):', nextNode?.id)
      let prevNode: Node | undefined = _startNode
      let prevNodeTemp: Node | undefined
      while (nextNode !== undefined && nextNodeOrder < maxNodeCount) {
        nextNodeOrder++
        prevNodeTemp = nextNode
        const nextNodeParentIds = nextNodeData?.parentNodeIds ? nextNodeData?.parentNodeIds : []
        nextNodeData = _calcNextRunNode(nextNode, [...nextNodeParentIds, prevNode.id], false)
        console.log('ProjectEditorView - calcNodesRunOrder - nextNodeData:', nextNodeData)
        nextNode = nextNodeData?.node
        if (nextNode) nodeOrderLookup.set(nextNode.id, nextNodeOrder)
        if (nextNode && _stopNode && nextNode.id === _stopNode.id) nextNode = undefined
        prevNode = prevNodeTemp
        console.log('ProjectEditorView - calcNodesRunOrder - nextNode.id(' + nextNodeOrder + '):', nextNode?.id)
      }
    }
    console.log('ProjectEditorView - calcNodesRunOrder - nodeOrderLookup:', nodeOrderLookup)
    setNodes((nds) => nds.map((nd) => {
      const nodeOrder = nodeOrderLookup.get(nd.id)
      nd.data = { ...nd.data, nodeOrder: nodeOrder } // NB: always update the nodeOrder, so previous calcs are cleared if they're not updated/set above
      return nd
    }))
  }, [isRunning, nodes, setNodes, _calcNextRunNode])

  const constructNodePrompt = (node: Node, parentNodes?: Array<Node>) => {
    console.log('ProjectEditorView - constructNodePrompt - node:', node, ' parentNodes:', parentNodes)
    let prompt = ''
    if (parentNodes && parentNodes.length > 0) {
      for (const parentNode of parentNodes) {
        const parentNodeOutput = (parentNode?.data.output ? parentNode.data.output.trim() + "\n\n" : '')
        prompt += parentNodeOutput
      }
    }
    console.log('ProjectEditorView - constructNodePrompt - node.data:', node.data, ' node.data.input:\'' + node.data.input + '\'')
    prompt += (node.data?.input?.trim() ?? '')
    console.log('ProjectEditorView - constructNodePrompt - prompt:\'' + prompt + '\'')
    return prompt
  }
  // -------

  const onNodesChange = useCallback(
    // (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
    (changes: NodeChange[]) => {
      console.log('ProjectEditorView - onNodesChange - changes:', changes)
      
      // WORK-AROUND: we seem to hit a bug/quirk after selecting/scrolling a text field that stops the deselect change being fired, so we instead handle/mimic it ourselves
      // TESTING: detect if we have a node select change but no corresponding deselect when a node is already selected
      // TODO: is the underlying issue related to a `[Violation] Added non-passive event listener to a scroll-blocking 'wheel' event...` warning that seems to always fire (at least in Chrome) after certain scroll/select actions within a textarea or input text field? (or is it just a coincidence?)
      const currentSelectedNode = nodes.find(n => n.selected)
      const selectNode = changes.find(c => c.type === 'select' && c.selected === true)
      const deselectNode = changes.find(c => c.type === 'select' && c.selected === false)
      // console.log('ProjectEditorView - onNodesChange - currentSelectedNode:', currentSelectedNode, ' selectNode:', selectNode, ' deselectNode:', deselectNode)
      if (currentSelectedNode && selectNode && !deselectNode) {
        console.log('ProjectEditorView - onNodesChange - MULTI-SELECT BUG TRIGGERED...')
        // TESTING: add our own 'deselect' change for the currently selected node so we maintain only a single selection at a time
        changes.push({ id: currentSelectedNode.id, type: 'select', selected: false })
      }

      setNodes((nds) => applyNodeChanges(changes, nds))

      // TESTING HERE
      // TODO: only run for certain node changes not all?
      if (mounted.current) calcNodesRunOrder()
    },
    [setNodes, nodes, calcNodesRunOrder]
  )

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
      console.log('ProjectEditorView - onEdgesChange - changes:', changes)
      setEdges((eds) => applyEdgeChanges(changes, eds))
      
      // TESTING HERE
      // TODO: only run for certain edge changes not all? (or move to specific edit update start/update/end callbacks instead? (&/or on edge connect/disconnect maybe?))
      if (mounted.current) calcNodesRunOrder()
    }, 
    [setEdges, calcNodesRunOrder]
  )

  const onEdgeUpdateStart = useCallback((_event: React.MouseEvent<Element, MouseEvent>, edge: Edge<any>, handleType: HandleType) => {
    console.log('ProjectEditorView - onEdgeUpdateStart - edge:', edge, ' handleType:', handleType)
    edgeUpdateData.current = { edge, handleType }
    edgeUpdateSuccessful.current = false
  }, [])

  // gets called after end of edge gets dragged to another source or target
  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      console.log('ProjectEditorView - onEdgeUpdate - oldEdge:', oldEdge, ' newConnection:', newConnection)
      setEdges((els) => updateEdge(oldEdge, newConnection, els))
    },
    [setEdges]
  )

  const onEdgeUpdateEnd = useCallback((_event: MouseEvent | TouchEvent, edge: Edge) => {
    console.log('ProjectEditorView - onEdgeUpdateEnd - edge:', edge)
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id))
    }
    edgeUpdateData.current = undefined
    edgeUpdateSuccessful.current = true
  }, [setEdges])

  const onConnect = useCallback((connection: Connection) => {
    console.log('ProjectEditorView - onConnect - connection:', connection)
    setEdges((eds) => addEdge({...connection /*, type: 'buttonedge' */ }, eds))
  }, [setEdges])

  // WORK-AROUND: flow-wide validation callback to add validation to edge updates
  // NB: ONLY seems to be called when moving an edge, not creating/assigning them initially, which has its own `isValidConnection` check within the `UserInputNode` handle(s)
  // UPDATE: this ALSO fires for when creating an edge IF the handle specific version of this callback isn't implemented, so commented its usage out within the `UserInputNode` so we handle all scenarios in one here!
  // ref: https://github.com/wbkd/react-flow/issues/1034#issuecomment-1528971529
  const isValidConnection = useCallback((connection: Connection) => {
    console.log('ProjectEditorView - isValidConnection - connection:', connection, ' edgeUpdateData:', edgeUpdateData.current)
    if (!connection.source || !connection.target || !connection.targetHandle) return false
    return canConnectSourceToTarget(connection.source, connection.target, connection.targetHandle)
    // return true // DEBUG ONLY
  }, [canConnectSourceToTarget])

  // const onNodeClick =  useCallback(
  //   (event: React.MouseEvent, node: Node) => {
  //     console.log('ProjectEditorView - onNodeClick - node:', node)
  //   }
  //   , [])

  const getNextNodeId = useCallback(() => {
    // TODO: add a better way to generate unique node ids...
    let nextNodeId = 1
    if (nodes.length > 0) {
      let nodeIdValid = false
      let loopCount = 0
      while (!nodeIdValid && loopCount < 1000) {
        // NB: I 'think' this is ok to ignore the linter warning below in this case as we don't edit the var within the anon function?
        // NB: was giving a `Function declared in a loop contains unsafe references to variable(s) 'nextNodeId'` linter warning within the `find` anon function
        // NB: seemingly relevant ref: https://stackoverflow.com/a/71467041
        // eslint-disable-next-line no-loop-func
        if (nodes.find(n => n.id === `${nextNodeId}`) === undefined) {
          nodeIdValid = true
        } else {
          nextNodeId++
        }
        loopCount++
      }
    }
    return nextNodeId
  }, [nodes])

  // const onCopy = () => {
  //   const selectedNodes = nodes.filter((n) => n.selected)
  //   if (selectedNodes.length === 1) {
  //     setCopiedNode(selectedNodes[0])
  //   }
  // }

  const _onPasteNode = useCallback((copyNode: Node) => {
    if (!copyNode) {
      return
    }
    const nextNodeId = getNextNodeId()
    const newNode: Node = {
      ...copyNode,
      id: `${nextNodeId}`,
      position: {
        x: copyNode.position.x + 10,
        y: copyNode.position.y + 10,
      },
    }
    if (newNode.data) newNode.data = { ...newNode.data, start: false } // make sure the start flag isn't copied across as enabled (so we don't end up with more than one enabled at once)
    // deselect the source/copied node - ref: https://github.com/wbkd/react-flow/issues/1602#issuecomment-1158190898
    setNodes((nds) => nds.map((nd) => {
      if (nd.selected) nd.selected = false
      return nd
    }))
    // set the new node as selected
    // UPDATE: seems to auto select without this (as the source/copied node was selected before it was duped?), so commented out the below for now
    // newNode.selected = true
    // setSelectedNode(newNode) // auto select the node
    // add the new node to the main nodes array
    setNodes((nds) => [...nds, newNode])
  }, [getNextNodeId, setNodes])

  // const onPaste = () => {
  //   if (!copiedNode) {
  //     return
  //   }
  //   _onPasteNode(copiedNode)
  // }

  const onDupeNode = useCallback((id: string) => {
    console.log('ProjectEditorView - onDupeNode - id:', id, ' nodes:', nodes)
    const copyNode = nodes.find((n) => n.id === id)
    console.log('ProjectEditorView - onDupeNode - copyNode:', copyNode)
    if (copyNode) {
      _onPasteNode(copyNode)
    }
  }, [_onPasteNode, nodes])

  const addNode = () => {
    const nextNodeId = getNextNodeId()
    const node:Node = {
      id: `${nextNodeId}`,
      type: 'userInputNode',
      data: {
        onDupe: onDupeNode
      }, // label: 'Node ' + nextNodeId },
      position: { x: 5 + (nextNodeId * 10), y: 5 + (nextNodeId * 10) }, // NB: offset the position a little each time so it doesn't sit directly on previously added ones (if they haven't moved) - TODO: ideally make this more inteligent...
      selected: true // auto selected it by default
    }
    // deselect any selected node(s) - ref: https://github.com/wbkd/react-flow/issues/1602#issuecomment-1158190898
    setNodes((nds) => nds.map((nd) => {
      if (nd.selected) nd.selected = false
      return nd
    }))
    // add the new node to the main nodes array
    setNodes((nds) => [...nds, node])
  }

    // -------

    const calcRunChildNodesCount = (node: Node) => {
      let childNodeCount = 0
      const nextNodes = getOutgoers(node, nodes, edges)
      if (nextNodes && nextNodes.length > 0) {
        for (const nextNode of nextNodes) {
          childNodeCount++
          childNodeCount = childNodeCount + calcRunChildNodesCount(nextNode)
        }
      }
      return childNodeCount
    }
    const calcRunNodesCount = () => {
      const startNode = nodes.find((n) => n.data.start)
      if (!startNode) return 0
      return 1 + calcRunChildNodesCount(startNode)
    }
  
    // -------
  
    const _getNodeWithRunOrder = (nodeOrder: number) => {
      console.log('ProjectEditorView - _getNodeWithRunOrder - nodes:', nodes)
      return nodes.find((n) => n.data.nodeOrder === nodeOrder)
    }
  
    const _getNextRunNode = (node: Node) => {
      const currentRunOrder = node.data.nodeOrder
      const nextRunOrder = currentRunOrder !== undefined && typeof currentRunOrder === 'number' ? currentRunOrder + 1 : undefined
      console.log('ProjectEditorView - _getNextRunNode - currentRunOrder:', currentRunOrder, ' nextRunOrder:', nextRunOrder)
      if (nextRunOrder !== undefined) return _getNodeWithRunOrder(nextRunOrder)
      return undefined
    }
  
    // const _getPreviousRunNode = (node: Node) => {
    //   const currentRunOrder = node.data.nodeOrder
    //   const prevRunOrder = currentRunOrder !== undefined && typeof currentRunOrder === 'number' && currentRunOrder > 1 ? currentRunOrder - 1 : undefined
    //   console.log('ProjectEditorView - _getPreviousRunNode - currentRunOrder:', currentRunOrder, ' prevRunOrder:', prevRunOrder)
    //   if (prevRunOrder !== undefined) return _getNodeWithRunOrder(prevRunOrder)
    //   return undefined
    // }

    // TODO: if re-enabling/keeping this, previous nodes are (mostly) irrelevant should this be flipped to be parent node specific instead? & so traverse up the (potentially multiple) parent nodes tree??
    // const _getPreviousRunNodes = (node: Node) => {
    //   console.log('ProjectEditorView - _getPreviousRunNodes - node:', node)
    //   const parentNodes: Array<Node> = []
    //   let parentNode: Node | undefined = _getPreviousRunNode(node)
    //   if (parentNode) parentNodes.push(parentNode)
    //   while (parentNode !== undefined) {
    //     console.log('ProjectEditorView - _getPreviousRunNodes - parentNode:', parentNode)
    //     parentNode = _getPreviousRunNode(parentNode)
    //     if (parentNode) parentNodes.push(parentNode)
    //   }
    //   return parentNodes.length ? parentNodes : undefined
    // }
  
    // -------

  // node selection
  // NB: only supporting a single selection for now (although partially coded with possible multi-seelct in mind)
  // TODO: should we also clear/reset `node.selected` when doing this? ref: https://github.com/wbkd/react-flow/issues/1602#issuecomment-1158190898
  // TODO: ..see the `onPaste` handling for initial trial usage just for that scenario
  useEffect(() => {
    // console.log('ProjectEditorView - useEffect - nodes:', nodes)
    const selectedNodes: Array<Node> = []
    for (const node of nodes) {
      if (node.selected) {
        selectedNodes.push(node)
      }
    }
    // console.log('ProjectEditorView - useEffect - nodes - selectedNodes:', selectedNodes)
    if (selectedNodes.length > 0) {
      const _selectedNode = selectedNodes[0] // NB: only supporting a single node
      if (!selectedNode || (selectedNode && selectedNode.id !== _selectedNode.id)) {
        console.log('ProjectEditorView - useEffect - nodes - selectedNode - select - _selectedNode:', _selectedNode)
        setSelectedNode(_selectedNode)
      }
    } else {
      if (selectedNode) {
        console.log('ProjectEditorView - useEffect - nodes - selectedNode - deselect...')
        setSelectedNode(undefined)
      }
    }
  }, [nodes, selectedNode])

  useEffect(() => {
    if (selectedNode) {
      const parentNodes = getParentNodes(selectedNode)
      setSelectedNodeParentNodes(parentNodes)
      const prompt = constructNodePrompt(selectedNode, parentNodes)
      setSelectedNodePrompt(prompt)
      console.log('ProjectEditorView - useEffect - selectedNode - selectedNode:', selectedNode, ' parentNodes:', parentNodes, ' prompt:', prompt)
    } else {
      if (selectedNodeParentNodes) setSelectedNodeParentNodes(undefined)
      if (selectedNodePrompt) setSelectedNodePrompt(undefined)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode])

  const onInputTextChange = (node: Node, input: string) => {
    console.log('ProjectEditorView - onInputTextChange - node:', node, ' input:', input)
    setNodes((nds) => nds.map((nd) => {
      if (nd.id === node.id) {
        nd.data = { ...nd.data, input }
      }
      return nd
    }))
  }

  const onOutputTextChange = (node: Node, output: string) => {
    console.log('ProjectEditorView - onOutputTextChange - node:', node, ' output:', output)
    setNodes((nds) => nds.map((nd) => {
      if (nd.id === node.id) {
        nd.data = { ...nd.data, output }
      }
      return nd
    }))
  }

  const onStartNodeChange = (node: Node, start: boolean) => {
    console.log('ProjectEditorView - onStartNodeChange - node:', node, ' start:', start)
    setNodes((nds) => nds.map((nd) => {
      if (nd.id === node.id) {
        nd.data = { ...nd.data, start }
      } else {
        // TESTING: remove the start flag from any other node if it currently has it set (should only be one)
        if (nd.data && nd.data.start === true) {
          nd.data = { ...nd.data, start: false }
        }
      }
      return nd
    }))
    // TESTING HERE (to catch node `start` changes)
    // TODO: only run for certain node changes not all?
    if (mounted.current) calcNodesRunOrder()
  }

  const onStopNodeChange = (node: Node, stop: boolean) => {
    console.log('ProjectEditorView - onStopNodeChange - node:', node, ' stop:', stop)
    setNodes((nds) => nds.map((nd) => {
      if (nd.id === node.id) {
        nd.data = { ...nd.data, stop }
      } else {
        // TESTING: remove the start flag from any other node if it currently has it set (should only be one)
        if (nd.data && nd.data.stop === true) {
          nd.data = { ...nd.data, stop: false }
        }
      }
      return nd
    }))
    // TESTING HERE (to catch node `start` changes)
    // TODO: only run for certain node changes not all?
    if (mounted.current) calcNodesRunOrder()
  }

  const onShowSettings = () => {
    showSettingsModal()
  }

  // -------

  const loadProject = async () => {
    console.log('ProjectEditorView - loadProject - projectId:', projectId)
    await new Promise((resolve) => setTimeout(resolve, 250)) // DEBUG ONLY
    const _project = await projectActions.getProjectForId(projectId)
    console.log('ProjectEditorView - loadProject - _project:', _project)
    setProject(_project)
    if (!_project) {
      setProjectError(new Error('Failed to load project'))
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100)) // TESTING: delay the react flow data upadte - TODO: handle this properly instead of just a blind delay! <<<
      // TESTING: load the saved reactflow data
      if (_project.data) {
        // TODO: clear/reset any existing nodes/edges first?
        if (_project.data.nodes) {
          for (const node of _project.data.nodes) {
            if (node.type === 'userInputNode') {
              node.data.onDupe = onDupeNode
            }
          }
          reactFlowInstance.setNodes(_project.data.nodes)
        }
        if (_project.data.edges) {
          reactFlowInstance.setEdges(_project.data.edges)
        }
        if (_project.data.viewport) {
          reactFlowInstance.setViewport(_project.data.viewport)
        }
      }
    }
  }

  useMountEffect(() => {
    console.log('ProjectEditorView - useMountEffect - projectsLoaded:', projectsLoaded)
    if (projectsLoaded) loadProject()
  })

  useEffect(() => {
    if (projectsLoaded && !project) {
      console.log('ProjectEditorView - useEffect - projectsLoaded/project...')
      loadProject()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsLoaded, project])

  useEffect(() => {
    console.log('ProjectEditorView - useEffect - dupeNodeId:', dupeNodeId)
    if (dupeNodeId) {
      onDupeNode(dupeNodeId)
      projectEditorContext.actions.setDupeNode(undefined)
    }
  }, [dupeNodeId, onDupeNode, projectEditorContext.actions])
  // -------

  if (!project && !projectError) {
    return (<LoaderView />)
  }

  if (projectError || !project) {
    return (
      <div className={styles.projectError}>
        ERROR: {projectError?.message ?? 'Failed to load project'}
      </div>
    )
  }

  // -------

  // DEPRECIATED: not currently needed
  // TESTING: flatten the child order tree data structure (for easy lookup/usage below)...
  // ref: https://www.techighness.com/post/javascript-flatten-deeply-nested-array-of-objects-into-single-level-array/
  // const getNodeRunOrderFlat = (members: Array<NodeRunOrder>): Array<NodeRunOrder> => {
  //   let children: Array<NodeRunOrder> = []
  //   return members.map(mem => {
  //     const m = { ...mem } // copy using the spread operator
  //     if (m.childOrder && m.childOrder.length) {
  //       children = [...children, ...m.childOrder]
  //     }
  //     delete m.childOrder // this will not affect the original array object
  //     return m
  //   }).concat(children.length ? getNodeRunOrderFlat(children) : children)
  // }

  // DEPRECIATED: not currently needed
  // NB: older node run order calc - all in one handling (& pre strict Y ordering support), see the newer `_calcNextRunNode` usage instead
  /*
  const _calcChildNodesRunOrder = (node: Node, runOrder: number, prevRunOrders?: Array<NodeRunOrder>) => {
    console.log('ProjectEditorView - _calcChildNodesRunOrder - node.id:', node.id)
    let childNodeOrder: Array<NodeRunOrder> = []
    const nextNodes = getOutgoers(node, nodes, edges)
    if (nextNodes && nextNodes.length > 0) {

      // TESTING: sort/order by position vs the other nodes attached to the same parent
      nextNodes.sort((a, b) => {
        // TODO: x AND/OR y position comparions?
        if (a.position.y < b.position.y) return -1
        return 1
      })
      console.log('ProjectEditorView - _calcChildNodesRunOrder - nextNodes(SORTED):', nextNodes)

      let nextNodeRunOrder = runOrder + 1
      console.log('ProjectEditorView - _calcChildNodesRunOrder - nextNodeRunOrder:', nextNodeRunOrder)
      for (const nextNode of nextNodes) {
        if ((prevRunOrders && prevRunOrders.length > 0) || childNodeOrder.length > 0) {
          const prevRunOrdersFlat = getNodeRunOrderFlat([...(prevRunOrders ? prevRunOrders : []), ...childNodeOrder])
          console.log('ProjectEditorView - _calcChildNodesRunOrder - prevRunOrdersFlat:', prevRunOrdersFlat)
          for (const prevRunOrder of prevRunOrdersFlat) {
            if (prevRunOrder.order >= nextNodeRunOrder) {
              console.log('ProjectEditorView - _calcChildNodesRunOrder - BUMP nextNodeRunOrder - WAS:', nextNodeRunOrder)
              nextNodeRunOrder = prevRunOrder.order + 1
              console.log('ProjectEditorView - _calcChildNodesRunOrder - BUMP nextNodeRunOrder - NOW:', nextNodeRunOrder)
            }
          }
        }
        console.log('ProjectEditorView - _calcChildNodesRunOrder - nextNode.id:', nextNode.id, ' nextNodeRunOrder:', nextNodeRunOrder)
        const nextNodeChildOrder = _calcChildNodesRunOrder(nextNode, nextNodeRunOrder, childNodeOrder)
        console.log('ProjectEditorView - _calcChildNodesRunOrder - nextNode.id:', nextNode.id, ' nextNodeChildOrder:', nextNodeChildOrder)
        const nextNodeRunOrderData: NodeRunOrder = { id: nextNode.id, order: nextNodeRunOrder, childOrder: nextNodeChildOrder }
        childNodeOrder.push(nextNodeRunOrderData)
        nextNodeRunOrder++
      }
    }
    return childNodeOrder.length > 0 ? childNodeOrder : undefined
  }
  */
  

  // -------

  // WARNING: when this is called recursively it seems to get old/previous state var values, so careful using state vars here while recursive calls are directly used
  // WARNING: ..could possibly launch each recursive call indirectly (via single timer maybe?) to possibly avoid that
  // WARNING: ..or see the `isCancellingRunRef` usage which uses references (via `useRef`) so we can access it during the current run loop
  const runNode = async (node: Node, parentNodes?: Array<Node>, runConfig?: NodeRunConfig, simulated: boolean = true) => {
    console.log('ProjectEditorView - runNode - node:', node, ' parentNodes:', parentNodes, ' runConfig:', runConfig, ' simulated:', simulated, ' isCancellingRunRef.current:', isCancellingRunRef.current, ' apiMode:', apiMode)
    try {

      if (isCancellingRunRef.current) {
        throw new Error('Run Cancelled')
      }

      const nodeRunOrder = node.data.nodeOrder && typeof node.data.nodeOrder === 'number' ? node.data.nodeOrder : 0

      // flag as 'running' the node
      setRunningNode(node)
      setNodes((nds) => nds.map((nd) => {
        if (nd.id === node.id) nd.data = { ...nd.data, isRunning: true }
        return nd
      }))
      setCurrentRunNodeCount((oldValue) => oldValue + 1)

      const modelId = runConfig?.model ?? _defaultModelId // WARNING: currently only handling 'chat' input mode based models here (chat gpt etc.), would need to lookup the models input mode & switch code handling if we wanted to handle the other input mode...
      const temperature = runConfig?.temp ?? AI_MODEL_TEMPERATURE_DEFAULT
      const topP = runConfig?.topP
      const maxTokens = runConfig?.maxTokens
      const systemMsg = runConfig?.systemMsg
      console.log('ProjectEditorView - runNode - modelId:', modelId, ' temperature:', temperature)
      // let prompt = '' // (parentNode?.data.output ? parentNode.data.output.trim() + "\n\n" : '') + (node.data.input ?? '')
      // if (parentNodes && parentNodes.length > 0) {
      //   for (const parentNode of parentNodes) {
      //     prompt += (parentNode?.data.output ? parentNode.data.output.trim() + "\n\n" : '')
      //   }
      // }
      // console.log('ProjectEditorView - runNode - node.data:', node.data, ' node.data.input:\'' + node.data.input + '\'')
      // prompt += (node.data?.input?.trim() ?? '')
      // console.log('ProjectEditorView - runNode - prompt:\'' + prompt + '\'')
      const prompt = constructNodePrompt(node, parentNodes)
      console.log('ProjectEditorView - runNode - prompt:\'' + prompt + '\'')
      // TODO: throw error if no/invalid input (&/or other params once we support them)
      const chatMessages: Array<ChatMessage> = []
      if (systemMsg) chatMessages.push({ role: ChatMessageRole.system, content: systemMsg })
      chatMessages.push({ role: ChatMessageRole.user, content: prompt })
      
      let result: ChatCompletionResult | undefined
      let error: Error | undefined
      try {
        if (!simulated) {
          console.log('ProjectEditorView - runNode - REAL RUN...')
          if (apiMode === APIMode.direct) {
            result = await chatActions.createChatCompletionDirect(modelId, chatMessages, temperature, topP, maxTokens)
          } else {
            result = await chatActions.createChatCompletion(modelId, chatMessages, temperature, topP, maxTokens)
          }
        } else {
          console.log('ProjectEditorView - runNode - SIMULATED RUN...')
          await new Promise((resolve) => setTimeout(resolve, 1500)) // DEBUG ONLY
          // DEBUG ONLY (mock response)
          result = {
            id: 'MOCK',
            model: 'MOCK',
            choices: [ { finish_reason: 'MOCK', index: 0, message: { content: 'MOCK RESPONSE ' + nodeRunOrder, role: 'MOCK' } } ],
            created: 0,
            object: 'MOCK', // e.g: "chat.completion"
            usage: { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 }
          }
        }
      } catch (_error: any) {
        console.error('ProjectEditorView - runNode - error:', _error)
        if (_error instanceof ServerAPICancelledError) {
          console.error('ProjectEditorView - runNode - error == cancelled <<<<')
          // NB: don't treat/log cancellations as an error
        } else {
          error = _error
        }
      }
      console.log('ProjectEditorView - runNode - result:', result)

      setRunningNode(undefined)
      setNodes((nds) => nds.map((nd) => {
        if (nd.id === node.id) nd.data = { ...nd.data, isRunning: false, error }
        return nd
      }))

      if (result && result.choices.length > 0) {
        // NB: currently only handling a single choice in the response result
        const choice = result.choices[0]
        console.log('ProjectEditorView - runNode - choice:', choice)
        if (choice.message.content) {
          setNodes((nds) => nds.map((nd) => {
            if (nd.id === node.id) {
              nd.data = { ...nd.data, output: choice.message.content }
            }
            return nd
          }))
          node.data.output = choice.message.content // NB: also set the passed in node directly as we don't currently get the state based updated node on subsequent (child) calls

          // if the node is currently selected, update the selection so the new output is shown
          if (selectedNode && selectedNode.id === node.id) {
            console.log('ProjectEditorView - runNode - UPDATE SELECTED NODE...')
            setSelectedNode(node)
          }
        } else {
          // TODO: handle?
        }
        // setChatMessages(oldChatMessages => [...oldChatMessages, { role: choice.message.role as ChatMessageRole, content: choice.message.content }])
      }

      // OLD: calc the next node directly
      /*
      // get all attached/child nodes
      const nextNodes = getOutgoers(node, nodes, edges)
      console.log('ProjectEditorView - runNode - nextNodes:', nextNodes)
      if (nextNodes && nextNodes.length > 0) {
        // TESTING: sort/order by position vs the other nodes attached to the same parent
        nextNodes.sort((a, b) => {
          // TODO: x AND/OR y position comparions?
          if (a.position.y < b.position.y) return -1
          return 1
        })
        console.log('ProjectEditorView - runNode - nextNodes(SORTED):', nextNodes)
        // loop through each attached node & run it
        for (const nextNode of nextNodes) {
          console.log('ProjectEditorView - runNode - nextNode:', nextNode)
          // run this node (& all its child nodes in turn)
          await runNode(nextNode, node, runConfig, simulated)
        }
      } else { 
        console.log('ProjectEditorView - runNode - no child nodes...')
      }
      */

      // NEW: use the pre-calculated nodeOrder to get & run the next node
      const nextNode = _getNextRunNode(node)
      console.log('ProjectEditorView - runNode - nextNode:', nextNode)
      if (nextNode) {
        // NB: the parent node to the next node might not be the one just run above, so we directly look it up (depending on run order settings etc.)
        const nextNodeParents = getParentNodes(nextNode)
        console.log('ProjectEditorView - runNode - nextNodeParents:', nextNodeParents)
        await runNode(nextNode, nextNodeParents, runConfig, simulated)
      }

    } catch (error: any) {
      console.error('ProjectEditorView - runNode - node.id:', node.id, ' - error:', error)

      // setRunningNode(undefined)
      // setNodes((nds) => nds.map((nd) => {
      //   if (nd.id === node.id) nd.data = { ...nd.data, isRunning: false, error }
      //   return nd
      // }))

      if (!parentNodes) {
      }
      throw error
    }
  }

  const startRun = async (simulated: boolean = true) => {
    console.log('ProjectEditorView - startRun - simulated:', simulated, ' project.runConfig:', project.runConfig)
    try {
      // find the start node, halt if no start node is current set
      const startNode = nodes.find((n) => n.data.start)
      if (!startNode) throw new Error('No start node found')
      if (startNode.data.nodeOrder !== 1) throw new Error('Invalid start node order')
      console.log('ProjectEditorView - startRun - START')
      isCancellingRunRef.current = false
      setIsRunning(true)
      setIsSimulated(simulated)
      setCurrentRunNodeCount(0)
      setTotalRunNodeCount(calcRunNodesCount())
      setRunError(undefined)
      // reset all nodes that will run (previous errors etc.)
      setNodes((nds) => nds.map((nd) => {
        if (nd.data.nodeOrder !== undefined) {
          nd.data = { ...nd.data, error: undefined }
        }
        return nd
      }))
      // await new Promise((resolve) => setTimeout(resolve, 1000)) // DEBUG ONLY
      // start the run with the first/start node...
      const parentNodes = getParentNodes(startNode)
      await runNode(startNode, parentNodes, project.runConfig, simulated)
      setRunningNode(undefined) // clean-up
      setIsRunning(false)
      // setIsSimulated(false)
      isCancellingRunRef.current = false
      console.log('ProjectEditorView - startRun - END')
    } catch (error: any) {
      console.error('ProjectEditorView - startRun - error:', error, ' isCancellingRunRef.current:', isCancellingRunRef.current)
      if (isCancellingRunRef.current === false) {
        setRunError(error)
      } else {
        // don't set the error if it was from cancelling the run
      }
      setRunningNode(undefined) // clean-up
      setIsRunning(false)
      // setIsSimulated(false)
    }
  }

  const cancelRun = () => {
    if (!isRunning || isCancellingRunRef.current) return
    console.log('ProjectEditorView - cancelRun')
    isCancellingRunRef.current = true
    // TESTING: cancel the current node (any node)
    // WARNING: this will cancel ANY chat api request running (not a specific node)
    if (apiMode === APIMode.direct) {
      chatActions.cancelChatCompletionDirect()
    } else {
      chatActions.cancelChatCompletion()
    }
  }

  const checkRunNodeParentHasOutput = (node: Node): number => {
    // console.log('ProjectEditorView - checkRunNodeParentHasOutput - node:', node)
    // const _nodes = reactFlowInstance.getNodes()
    // const _edges = reactFlowInstance.getEdges()
    // console.log('ProjectEditorView - checkRunNodeParentHasOutput - nodes:', nodes, ' edges:', edges)
    // TODO: check the new multi-parent node update works as expected (ported blind, not tested!)
    const parentNodes = getParentNodes(node)
    // console.log('ProjectEditorView - checkRunNodeParentHasOutput - parentNode:', parentNode)
    if (parentNodes) {
      // if (parentNode.data.output === undefined || parentNode.data.output.trim() === '') {
      //   console.log('ProjectEditorView - checkRunNodeParentHasOutput - WARNING: PARENT NODE HAS NO OUTPUT SET/LOADED')
      //   const parentNoOutputCount = checkRunNodeParentHasOutput(parentNode)
      //   return 1 + parentNoOutputCount
      // }
      let parentNoOutputCountTotal = 0
      for (const parentNode of parentNodes) {
        if (parentNode.data.output === undefined || parentNode.data.output.trim() === '') {
          console.log('ProjectEditorView - checkRunNodeParentHasOutput - WARNING: PARENT NODE HAS NO OUTPUT SET/LOADED')
          const parentNoOutputCount = checkRunNodeParentHasOutput(parentNode)
          // return 1 + parentNoOutputCount
          parentNoOutputCountTotal = parentNoOutputCountTotal + 1 + parentNoOutputCount
        }
      }
      return parentNoOutputCountTotal
    }
    return 0
  }

  // -------

  const onSave = () => {
    console.log('ProjectEditorView - onSave - isRunning:', isRunning)
    console.log('ProjectEditorView - onSave - reactFlowInstance.toObject():', reactFlowInstance.toObject())

    if (isRunning) {
      // TODO: add a dedicated error type for this, or at least separate out from run specific errors so we don't override?
      // TODO: ..maybe make this a temp error that auto hides after x seconds?
      setRunError(new Error('Cannot save while running!'))
      return
    }

    // TESTING: filter out fields from our custom node type(s) that we don't want/need saving (runtime only field)
    // NB: react-flow doesn't seem to have a way to do this natively, so we do so manually here before saving the data
    // NB: currently ONLY supports the `userInputNode` node type (`type` key as declared by our `nodeTypes` object)
    // NB: ..& requires all that nodes keys we want to save to be listed in the `UserInputNodeDataKeys` object
    const data: ReactFlowJsonObject<any, any> = reactFlowInstance.toObject()
    console.log('ProjectEditorView - onSave - reactFlowInstance.toObject - data(BEFORE):', data)
    if (data && data.nodes && data.nodes.length > 0) {
      const dataNodes: Array<Node> = []
      for (const node of data.nodes) {
        // console.log('ProjectEditorView - onSave - node:', node)
        if (node.type === 'userInputNode') {
          const nodeData = node.data
          const newNodeData: {[key: string]: any} = {}
          if (nodeData && typeof nodeData === 'object') {
            // console.log('ProjectEditorView - onSave - nodeData:', nodeData, ' typeof nodeData:', typeof nodeData)
            const nodeDataKeys = Object.keys(nodeData)
            // console.log('ProjectEditorView - onSave - nodeDataKeys:', nodeDataKeys)
            for (const dataKey of UserInputNodeDataKeys) {
              if (nodeDataKeys.includes(dataKey)) {
                console.log('ProjectEditorView - onSave - valid dataKey:', dataKey)
                newNodeData[dataKey] = nodeData[dataKey]
              }
            }
          }
          // console.log('ProjectEditorView - onSave - newNodeData:', newNodeData)
          node.data = newNodeData // override the default custom node data with all props included with our filtered version with just the fields we actually want to save
          dataNodes.push(node)
        } else {
          dataNodes.push(node) // passthrough/add other node types un-altered
        }
      }
      data.nodes = dataNodes // override the default nodes array with our altered version before saving
    }
    console.log('ProjectEditorView - onSave - reactFlowInstance.toObject - data(AFTER):', data)
    project.data = data
    projectActions.saveProject(project)
  }

  const onRun = () => {
    console.log('ProjectEditorView - onRun')
    // NB: skipping the run modal prompt by default now we no longer need the simulated mode as we pre-calc the run order & can see it in the node tree
    // NB: update `EDITOR_SHOW_SIMULATED_RUN_MODAL` if you want to show it while debugging/testing/styling
    if (!EDITOR_SHOW_SIMULATED_RUN_MODAL) {
      startRun(false)
    } else {
      showConfirmRunModal()
    }
  }

  const onCancelRun = () => {
    console.log('ProjectEditorView - onCancelRun')
    cancelRun()
  }

  // -------

  const showSettingsModal = () => {
    setShowSettingsModal(true)
  }
  const hideSettingsModal = () => {
    setShowSettingsModal(false)
  }

  const renderSettingsModal = () => {
    return (
      <Modal
        open={_showSettingsModal}
        onClose={hideSettingsModal}
        className={styles.settingsModal}
      >
        <div className={styles.content}>
          <Header title={<>PROJECT RUN SETTINGS</>}></Header>
          <div className={styles.settings}>
            <ProjectEditorSettingsForm
              project={project}
              onClose={() => { hideSettingsModal() }}
              onCancel={() => { hideSettingsModal() }}
            />
          </div>
          {/* <div className={styles.buttons}>
            <Button className={styles.cancelBtn} onClick={hideSettingsModal}>CANCEL</Button>
            <Button className={styles.startConfirmBtn} onClick={() => {
              // TODO:
              hideSettingsModal()
            }}>SAVE</Button>
          </div> */}
        </div>
      </Modal>
    )
  }

  // -------

  const showConfirmRunModal = () => {
    setShowConfirmRunModal(true)
  }
  const hideConfirmRunModal = () => {
    setShowConfirmRunModal(false)
  }

  const renderRunConfirmModal = () => {
    let parentNoOutputCount = 0
    const startNode = nodes.find((n) => n.data.start)
    if (startNode) {
      parentNoOutputCount = checkRunNodeParentHasOutput(startNode)
      // console.log('ProjectEditorView - renderRunConfirmModal - parentNoOutputCount:', parentNoOutputCount)
    }
    return (
      <Modal
        open={_showConfirmRunModal}
        onClose={hideConfirmRunModal}
        className={styles.runModal}
      >
        <div className={styles.content}>
          <Header title={<>START NODE RUN?</>}></Header>
          <div className={styles.msg}>
            <div className={styles.simulatedOption}>
              <label htmlFor="simulated" className={styles.checkbox}>Simulated Mode:</label>
              <div className={styles.checkbox}>
                <input type="checkbox" name="simulated" checked={isSimulated} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsSimulated(event.target?.checked ?? false)
                }} />
              </div>
            </div>
            {(parentNoOutputCount > 0 && (
              <div className={styles.warning}>
                WARNING: {parentNoOutputCount} previous node{parentNoOutputCount !== 1 && 's'} {parentNoOutputCount !== 1 ? 'have' : 'has'} no output set. Continue?
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            <Button className={styles.cancelBtn} onClick={hideConfirmRunModal}>CANCEL</Button>
            <Button className={styles.startConfirmBtn} onClick={() => {
              startRun(isSimulated)
              hideConfirmRunModal()
            }}>START</Button>
          </div>
        </div>
      </Modal>
    )
  }

  // -------

  return (
    <div className={styles.view}>
      <div className={styles.nodeContent}>
        <div className={styles.nodeTopBar}>
          <h1>Project: {project.title}</h1>
          <div className={styles.editorOptions}>
            {AI_DIRECT_MODE_ENABLED && (
              <div className={styles.field}>
                <label htmlFor="apiMode">API Mode:</label>
                <div className={styles.input}>
                  <Select
                    options={[{ value: APIMode.internal, label: 'Internal' }, { value: APIMode.direct, label: 'Direct' }]}
                    defaultValue={apiMode}
                    onChange={(newValue?: string | number) => {
                      console.log('ProjectEditorView - apiMode - onChange - newValue:', newValue)
                      setApiMode(newValue as APIMode)
                    }}
                    disabled={isRunning}
                    className={styles.apiModeSelect}
                    slimline
                  >
                  </Select>
                </div>
              </div>
            )}
            <Button onClick={addNode} disabled={isRunning} slim>ADD</Button>
            {/* <Button onClick={onCopy} disabled={!selectedNode}>COPY</Button>
            <Button onClick={onPaste} disabled={!copiedNode}>PASTE</Button> */}
            <Button className={styles.saveBtn} disabled={isRunning} onClick={onSave} slim>SAVE</Button>
            <Button onClick={onShowSettings} disabled={isRunning} slim>SETTINGS</Button>
          </div>
        </div>
        <div className={styles.nodeCanvas}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            onConnect={onConnect}
            // onNodeClick={onNodeClick}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            defaultViewport={defaultViewport}
            fitView
            fitViewOptions={fitViewOptions}
            proOptions={proOptions}
            /* WORK-AROUND: flow-wide validation callback to add validation to edge updates - ref: https://github.com/wbkd/react-flow/issues/1034#issuecomment-1528971529 */
            isValidConnection={isValidConnection}
          >
            <Background color="#99b3ec" variant={variant} />
            <Controls />
            {/* <Panel position={'top-left'}></Panel> */}
            <Panel position={'top-center'}>
              {(isRunning || runError) && (
                <div className={styles.runStatus + (runError ? ' ' + styles.runStatusError : '')}>
                  {isRunning && (
                    <>
                      <div><span className={styles.runStatusLbl}>STATUS:</span> RUNNING{apiMode === APIMode.direct ? <> (DIRECT MODE)</> : null}{isSimulated ? <> (SIMULATED)</> : null}</div>
                      <div><span className={styles.runStatusLbl}>STEP:</span> {currentRunNodeCount} / {totalRunNodeCount}</div>
                      {runningNode && (
                        <>
                          <div><span className={styles.runStatusLbl}>NODE:</span> {runningNode.id}</div>
                          <div><span className={styles.runStatusLbl}>INPUT:</span> {runningNode.data.input && runningNode.data.input.length > 50 ? runningNode.data.input.substring(0, 50) + '...' : runningNode.data.input}</div>
                        </>
                      )}
                    </>
                  )}
                  {runError !== undefined && (
                    <>
                      <div className={styles.runError}>ERROR: {runError.message}</div>
                    </>
                  )}
                </div>
              )}
            </Panel>
            {/* <Panel position={'top-right'} className={styles.panelOptions}></Panel> */}
            {/* <Panel position={'bottom-center'}>
              <div>background:</div>
              <button onClick={() => setVariant(BackgroundVariant.Dots)}>dots</button>
              <button onClick={() => setVariant(BackgroundVariant.Lines)}>lines</button>
              <button onClick={() => setVariant(BackgroundVariant.Cross)}>cross</button>
            </Panel> */}
            {/* <Panel position={'bottom-center'}>
              <Button slim onClick={() => calcNodesRunOrder()}>DBG: CALC RUN ORDER</Button>
            </Panel> */}
            <Panel position={'bottom-right'}>
              <div className={styles.actionButtons}>
                {/* <Button className={styles.saveBtn} disabled={isRunning} onClick={onSave}>SAVE</Button> */}
                {!isRunning && (<Button className={styles.runBtn + ' ' + styles.start + (EDITOR_SHOW_SIMULATED_RUN_MODAL ? ' ' + styles.runBtnWithConfirm : '')} onClick={onRun}>RUN</Button>)}
                {isRunning && (<Button className={styles.runBtn + ' ' + styles.cancel} onClick={onCancelRun}>CANCEL RUN</Button>)}
              </div>
            </Panel>
          </ReactFlow>
        </div>
      </div>
      <div className={styles.nodeSidebar}>
        <div className={styles.content}>
          <UserInputSidebar
            node={selectedNode}
            parentNodes={selectedNodeParentNodes}
            userPrompt={selectedNodePrompt}
            systemPrompt={project.runConfig?.systemMsg}
            onInputTextChange={onInputTextChange}
            onOutputTextChange={onOutputTextChange}
            onStartNodeChange={onStartNodeChange}
            onStoptNodeChange={onStopNodeChange}
          />
        </div>
      </div>
      {renderSettingsModal()}
      {renderRunConfirmModal()}
    </div>
  )
}

export default ProjectEditorView
