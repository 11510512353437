import React, { useContext, useEffect, useState } from 'react'

import { User } from '../../../models'
import { ServerConfigContext } from '../../../providers'

import Button from '../../../components/Button'

import styles from './AdminUserRolesView.module.css'

export type AdminUserRolesUpdateCallback = (roles: Array<string>) => void

export interface AdminUserRolesViewProps {
  user: User
  onUpdateUserRoles: AdminUserRolesUpdateCallback
  onCancel?: Function
  updating?: boolean
  updated?: boolean
  error?: Error
}

const AdminUserRolesView = (props: AdminUserRolesViewProps) => {
  const { user, onUpdateUserRoles, onCancel: onDidCancel, updating, updated, error } = props

  const serverConfigContext = useContext(ServerConfigContext)

  const [userId, setUserId] = useState<string | undefined>(undefined)
  const [roles, setRoles] = useState<Array<string>>([])
  const [hasChanges, setHasChanges] = useState<boolean>(false)

  const [showEditForm, setShowEditForm] = useState<boolean>(false)
  //const [submitting, setSubmitting] = useState<boolean>(false)

  useEffect(() => {
    if (userId !== user.id) {
      // reset the component state
      setShowEditForm(false)
      //setSubmitting(false)
      setRoles(user.roles ?? [])
      setHasChanges(false)
    }
    setUserId(user.id)
  }, [user, userId])

  useEffect(() => {
    // TESTING: check if any changes have been made (& so should offer to save them)
    const userRoles = user.roles ?? []
    const _hasChanges = !(userRoles.length === roles.length && userRoles.every(role => roles.includes(role)))
    console.log('AdminUserRolesView - onChange - userRoles:', userRoles.length, ' = ', userRoles, ' roles: ', roles.length, ' = ', roles)
    console.log('AdminUserRolesView - onChange - hasChanges:', hasChanges, ' _hasChanges:', _hasChanges, ' length ===:', (userRoles.length === roles.length), ' every:', (userRoles.every(role => roles.includes(role))))
    if (hasChanges !== _hasChanges) setHasChanges(_hasChanges)
  }, 
  // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [user, roles, updated]) // NB: we don't need/want to listen for `hasChanges` changes here as we update it (we do listen for `updated` so we update the button state after successfully saving)
  
  const onEditUserRoles = () => {
    setShowEditForm(true)
  }

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const role = event.target.value
    const checked = event.target.checked
    console.log('AdminUserRolesView - onChange - role:', role, ' checked:', checked)
    if (checked) {
      setRoles([...roles, ...[role]].sort())
    } else {
      setRoles(roles.filter(r => r !== role))
    }
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('AdminUserRolesView - onSubmit - roles:', roles)
    // TODO: only trigger the callback if the roles have changes (sort before comparing incase the order has changed when editing & reverting those edits/changes)
    onUpdateUserRoles(roles)
  }

  const onCancel = () => {
    setShowEditForm(false)
    if (onDidCancel) onDidCancel()
  }

  const validRoles = serverConfigContext.store.serverConfig?.authConfig.roles ?? []
  console.log('AdminUserRolesView - validRoles:', validRoles)

  return (
    <>
      {!showEditForm && (<Button fluid className={styles.deleteBtn} onClick={onEditUserRoles}>EDIT USER ROLES</Button>)}
      {showEditForm && (
        <form className={styles.form + (updating ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
          <h1>User Roles</h1>
          {error && (
            <div className={styles.errMsg}>ERROR: {error.message}</div>
          )}
          {updated && (
            <div className={styles.successMsg}>User Roles Updated!</div>
          )}
          <div className={styles.field}>
            {/* <label htmlFor="roles">Roles:</label> */}
            <div className={styles.input + ' ' + styles.checkboxes}>
              {/* <input type="text" value={email} id="email" name="email" autoComplete="email" onChange={(event) => { setEmail(event.target.value) }} /> */}
              {/* <fieldset> */}
                {/* <legend></legend> */}
                {validRoles.map((role) => {
                  const checked = user.roles?.includes(role) ?? false
                  return (
                    <div key={'role_' + role} className={styles.checkbox}>
                      <input type="checkbox" name="role" value={role} defaultChecked={checked} onChange={onChange} />
                      <span className={styles.checkboxTitle}>{role}</span>
                    </div>
                  )
                })}
              {/* </fieldset> */}
            </div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.field + ' ' + styles.button}>
              <input type="button" value="Cancel" disabled={updating} onClick={onCancel} />
            </div>
            <div className={styles.field + ' ' + styles.button}>
              <input type="submit" value="Save" disabled={!hasChanges || updating} />
              {updating && (<>UPDATING...</>)}
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default AdminUserRolesView
