import React, { useEffect, useState } from 'react'
import {
  Node
} from 'reactflow'

import Panel from '../../../components/Panel'

import styles from './UserInputSidebar.module.css'

export interface UserInputSidebarProps {
  node?: Node
  onInputTextChange?: (node: Node, input: string) => void
}

const UserInputSidebar = (props: UserInputSidebarProps) => {
  const { node, onInputTextChange: _onInputTextChange } = props
  const [nodeId, setNodeId] = useState<string | undefined>()
  const [textValue, setTextValue] = useState<string>('')
  
  useEffect(() => {
    console.log('UserInputSidebar - useEffect - node:', node)
    if (node && node.type === 'userInputNode') {
      if (!nodeId || nodeId !== node.id) {
        setNodeId(node.id)
        setTextValue(node.data.input ?? '')
      }
    } else {
      setNodeId(undefined)
      setTextValue('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])
  
  const onInputTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log('UserInputSidebar - onInputTextChange - event.target?.value:', event.target?.value)
    setTextValue(event.target?.value ?? '')
    if (node) node.data.input = event.target?.value ?? ''
    console.log('UserInputSidebar - onInputTextChange - node.data.input(AFTER):', node?.data.input)
    if (node && _onInputTextChange) _onInputTextChange(node, event.target?.value ?? '')
  }


  return (
    <>
      <Panel
        className={styles.panel}
        title={'User Input'}
        // headerRight={}
      >
        {node && nodeId && ( // NB: don't show the sidebar contents if its not an expected node type (nodeId === undefined if it was an invalid type)
          <>
            <Panel.PropertyRow title='Id:' value={node?.id ?? '-'} />
            <Panel.PropertyRow>
              {/* <Panel.PropertyRowTitle>Input:</Panel.PropertyRowTitle> */}
              <Panel.PropertyRowContent>
                <div>Input:</div>
                <textarea
                  id={'input'}
                  name={'input'}
                  cols={28}
                  rows={10}
                  value={textValue}
                  onChange={onInputTextChange}
                ></textarea>
              </Panel.PropertyRowContent>
            </Panel.PropertyRow>
        </>)}
      </Panel>
    </>
  )
}

export default UserInputSidebar
