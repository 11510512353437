import React from 'react'

import styles from './Modal.module.css'

export interface ModalProps {
  open: boolean
  onClose?: Function
  header?: React.ReactNode
  children?: React.ReactNode
  className?: string
  contentClassName?: string
  wrapperClassName?: string
  size?: 'small' | 'medium' | 'large' | 'fullscreen'
  closeOnEscapeKey?: boolean // close the modal when the escape key is pressed - NB: currently defaults to off (should it be flipped to on?)
  // TODO: implement:
  // closeOnClickOutside?: boolean // close the modal when the modal wrapper surround area is clicked (outside the modal)
}

const Modal = (props: ModalProps) => {
  const { open, onClose, header, children, className, contentClassName, wrapperClassName, size = 'small', closeOnEscapeKey } = props
  if (!open) return null
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape' && closeOnEscapeKey) {
      if (onClose) onClose()
    }
  }
  return (
    <div tabIndex={0} onKeyDown={onKeyDown} className={styles.modalWrapper + (wrapperClassName ? ' ' + wrapperClassName : '')}>
      <div className={styles.modal + (className ? ' ' + className : '') + ' ' + size}>
        {header && (
          <div className={styles.header}>
            {header}
          </div>
        )}
        <div className={styles.content + (contentClassName ? ' ' + contentClassName : '')}>
          {children}
        </div>
        <div className={styles.closeBtn} onClick={() => { if (onClose) onClose() }}>X</div>
      </div>
    </div>
  )
}

export default Modal
