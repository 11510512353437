export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL ?? ''
export const WEB_SERVER_URL = process.env.REACT_APP_WEB_SERVER_URL ?? ''

// the list of model id's we want to make available within the app (currently just in the debug mode, but may also use this in the main node to trial different responses/features)
export const AI_MODELS = [
  { id: 'text-davinci-003', inputMode: 'text', minTokens: 100, maxTokens: 4000 },
  { id: 'gpt-3.5-turbo', inputMode: 'chat', minTokens: 100, maxTokens: 4096 },
  { id: 'gpt-3.5-turbo-0301', inputMode: 'chat', minTokens: 100, maxTokens: 4096 },
  { id: 'gpt-4-0314', inputMode: 'chat', minTokens: 100, maxTokens: 4096 },
]
export const AI_MODEL_DEFAULT_DEBUG_MODE = 'text-davinci-003' // NB: this MUST also be listed in the `AI_MODELS` above
export const AI_MODEL_DEFAULT_PROJECT_MODE = 'gpt-3.5-turbo-0301' // NB: this MUST also be listed in the `AI_MODELS` above
export const AI_MODEL_TEMPERATURE_DEFAULT = 0.95 // NB: the default temperature arg to use in the range `0.0 - 1.0`
export const AI_MODEL_TOP_P_DEFAULT = 1.0 // NB: the default top_p arg to use in the range `0.0 - 1.0`
// export const AI_MODEL_MAX_TOKENS_DEFAULT = 3500 // NB: the default max_tokens to use if the AI_MODELS entry doesn't specify one (only used as a fallback)
export const AI_RUN_ORDER_STRICT_Y_DEFAULT = true // NB: the default stict Y ordering flag

export const getAIModel = (id: string) => { return AI_MODELS.find(m => m.id === id )}

export const AI_DIRECT_MODE_ENABLED = true // allow admin users to optionally flip from using our api proxy to calling the openai api directly from the client app (the api server sends us the api key so we can make the calls directly)
export const AI_DIRECT_API_URL = 'https://api.openai.com/v1'

export const EDITOR_SHOW_SIMULATED_RUN_MODAL = false // rls = false - whether to show the debug 'simulated run' modal when the main 'run' button is clicked (if disabled it will always do a real/full run)
