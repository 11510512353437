import fs from 'fs'

import ServerAPIClient from './ServerAPIClient'

import { Group } from '../models'

class ServerAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // -------

  // -------
}

export default ServerAPI
