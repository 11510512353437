import { ReactFlowJsonObject } from "reactflow"
import NodeRunConfig from "./NodeRunConfig"

class Project {
  constructor(
    public key: string,
    public userId: string,
    public projectId: number,
    public title: string,
    public data?: ReactFlowJsonObject<any, any>,
    public runConfig?: NodeRunConfig,
    public createdAt?: Date,
    public updatedAt?: Date
  ) {}

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : Project | null {
    if (!json) return null
    console.log('Project - fromJSON - json:', json)
    const runConfig: NodeRunConfig | undefined = json.runConfig ? NodeRunConfig.fromJSON(json.runConfig) ?? undefined : undefined
    const project = new Project(
      json.key,
      json.userId,
      json.projectId,
      json.title,
      json.data,
      runConfig,
      json.createdAt ? new Date(json.createdAt) : undefined,
      json.updatedAt ? new Date(json.updatedAt) : undefined,
    )
    console.log('Project - fromJSON - project:', project)
    return project
  }
}

export default Project
