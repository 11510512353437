import React, { useEffect, useState } from 'react'
import {
  Node
} from 'reactflow'

import Panel from '../../../components/Panel'

import styles from './UserInputSidebar.module.css'

export interface UserInputSidebarProps {
  node?: Node
  parentNodes?: Array<Node>
  userPrompt?: string
  systemPrompt?: string
  onInputTextChange?: (node: Node, input: string) => void
  onOutputTextChange?: (node: Node, input: string) => void
  onStartNodeChange?: (node: Node, start: boolean) => void
  onStoptNodeChange?: (node: Node, stop: boolean) => void
}

const UserInputSidebar = (props: UserInputSidebarProps) => {
  const {
    node,
    // parentNodes,
    userPrompt,
    systemPrompt,
    onInputTextChange: _onInputTextChange,
    onStartNodeChange: _onStartNodeChange,
    onStoptNodeChange: _onStopNodeChange,
    onOutputTextChange: _onOutputTextChange
  } = props
  const [nodeId, setNodeId] = useState<string | undefined>(node?.id)
  const [inputTextValue, setInputTextValue] = useState<string>(node?.data.input ?? '')
  const [outputTextValue, setOutputTextValue] = useState<string | undefined>(node?.data.output)
  const [startValue, setStartValue] = useState<boolean>(node?.data.start ?? false)
  const [stopValue, setStopValue] = useState<boolean>(node?.data.stop ?? false)
  const [showPromptPreview, setShowPromptPreview] = useState<boolean>(false)
  
  useEffect(() => {
    console.log('UserInputSidebar - useEffect - node:', node)
    if (node && node.type === 'userInputNode') {
      if (!nodeId || nodeId !== node.id) {
        setNodeId(node.id)
        setInputTextValue(node.data.input ?? '')
        setStartValue(node.data.start ?? false)
        setStopValue(node.data.stop ?? false)
        setOutputTextValue(node.data.output)
      } else {
        console.log('UserInputSidebar - useEffect - node - UPDATE?...')
        // TESTING: allow updates from the node prop changing (e.g. output being updated)
        setInputTextValue(node.data.input ?? '')
        setStartValue(node.data.start ?? false)
        setStopValue(node.data.stop ?? false)
        setOutputTextValue(node.data.output)
      }
    } else {
      setNodeId(undefined)
      setInputTextValue('')
      setStartValue(false)
      setStopValue(false)
      setOutputTextValue(undefined)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])
  
  const onInputTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log('UserInputSidebar - onInputTextChange - event.target?.value:', event.target?.value)
    setInputTextValue(event.target?.value ?? '')
    if (node) node.data.input = event.target?.value ?? ''
    console.log('UserInputSidebar - onInputTextChange - node.data.input(AFTER):', node?.data.input)
    if (node && _onInputTextChange) _onInputTextChange(node, event.target?.value ?? '')
  }

  const onOuputTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log('UserInputSidebar - onOuputTextChange - event.target?.value:', event.target?.value)
    setOutputTextValue(event.target?.value ?? '')
    if (node) node.data.output = event.target?.value ?? ''
    console.log('UserInputSidebar - onInputTextChange - node.data.input(AFTER):', node?.data.input)
    if (node && _onOutputTextChange) _onOutputTextChange(node, event.target?.value ?? '')
  }

  const onStartNodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('UserInputSidebar - onStartChange - event.target?.checked:', event.target?.checked)
    setStartValue(event.target?.checked)
    if (node && _onStartNodeChange) _onStartNodeChange(node, event.target?.checked)
  }

  const onStopNodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('UserInputSidebar - onStopNodeChange - event.target?.checked:', event.target?.checked)
    setStopValue(event.target?.checked)
    if (node && _onStopNodeChange) _onStopNodeChange(node, event.target?.checked)
  }

  const togglePromptPreviewVisible = () => {
    console.log('UserInputSidebar - togglePromptPreviewVisible')
    setShowPromptPreview(!showPromptPreview)
  }

  const prompt = (systemPrompt ? 'SYSTEM\n' + systemPrompt + '\n\n' : '') + ('USER\n' + userPrompt)

  // console.log('UserInputSidebar - render - node:', node)
  return (
    <>
      <Panel
        className={styles.panel}
        contentClassName={styles.panelContent + (showPromptPreview ? ' ' + styles.panelPromptShowing : '')}
        // title={'User Input'}
        // headerRight={}
      >
        {node && nodeId && ( // NB: don't show the sidebar contents if its not an expected node type (nodeId === undefined if it was an invalid type)
          <>
            <div className={styles.header}>
              <div className={styles.nodeId}>
                {node?.id ? ('#' + node?.id) : '-'}
              </div>
              <div className={styles.properties}>
                <div className={styles.property}>
                  <span className={styles.propertyTitle}>Start:</span>
                  <span className={styles.checkbox}>
                    <input type="checkbox" name="start" checked={startValue} /*defaultChecked={startValue}*/ onChange={onStartNodeChange} />
                  </span>
                </div>
                <div className={styles.property}>
                  <span className={styles.propertyTitle}>Stop:</span>
                  <span className={styles.checkbox}>
                    <input type="checkbox" name="stop" checked={stopValue} /*defaultChecked={stopValue}*/ onChange={onStopNodeChange} />
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.property + ' ' + styles.propertyInput}>
              <div className={styles.propertyTitle}>Instruction:</div>
              <textarea
                id={'input'}
                name={'input'}
                cols={28}
                // rows={10}
                value={inputTextValue}
                onChange={onInputTextChange}
              ></textarea>
            </div>
            <div className={styles.property + ' ' + styles.propertyPromptPreview}>
              <div className={styles.propertyTitle} onClick={() => togglePromptPreviewVisible()}>Prompt Preview{showPromptPreview ? ':' : '...'}</div>
              {showPromptPreview && (
                <textarea
                  id={'prompt'}
                  name={'prompt'}
                  cols={28}
                  // rows={10}
                  value={prompt ?? ''}
                  readOnly
                ></textarea>
              )}
            </div>
            <div className={styles.property + ' ' + styles.propertyOutput}>
              <div className={styles.propertyTitle}>Output:</div>
              <textarea
                id={'output'}
                name={'output'}
                cols={28}
                // rows={10}
                value={outputTextValue ?? ''}
                // readOnly
                onChange={onOuputTextChange} // NB: not currently used in `readOnly` mode but left in place incase we decide to allow editing of the output directly (although that would be better handled with some sort of alter/replace node/feature so re-runs wouldn't lose the manual edit)
              ></textarea>
            </div>
          </>
        )}
      </Panel>
    </>
  )
}

export default UserInputSidebar
