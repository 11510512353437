import React, { createContext, ReactNode, useState } from 'react'
import { useMountEffect, useUnmountEffect } from '../hooks'
import { Node, Edge } from 'reactflow'

// import { Project } from '../models'

export const ProjectEditorContext = createContext<IProjectEditorContext>({} as IProjectEditorContext)

interface IProjectEditorStore {
  nodes: Array<Node>
  edges: Array<Edge>
  dupeNodeId?: string
}

interface IProjectEditorActions {
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>
  setDupeNode: (id?: string) => void
}

interface IProjectEditorContext {
  actions: IProjectEditorActions
  store: IProjectEditorStore
}

interface ProjectEditorProviderProps {
  children: ReactNode
}

const ProjectEditorProvider = (props: ProjectEditorProviderProps) => {
  const { children } = props
  const [dupeNodeId, _setDupeNodeId] = useState<string | undefined>()

  const [nodes, setNodes] = useState<Array<Node>>([])
  const [edges, setEdges] = useState<Array<Edge>>([])

  // -------

  const setDupeNode = (id?: string) => {
    _setDupeNodeId(id)
  }

  // -------
  
  useMountEffect(() => {
    console.log('ProjectEditorProvider - useMountEffect')
  })

  useUnmountEffect(() => {
    console.log('ProjectEditorProvider - useUnmountEffect')
  })

  // -------

  // -------

  const actions: IProjectEditorActions = {
    setNodes,
    setEdges,
    setDupeNode
  }

  const store: IProjectEditorStore = {
    nodes,
    edges,
    dupeNodeId
  }

  return (
    <ProjectEditorContext.Provider value={{ actions, store }}>
      {children}
    </ProjectEditorContext.Provider>
  )
}

export default ProjectEditorProvider
