import React, { useContext, useState } from 'react'

import { AuthContext } from '../../../providers'

const VerifyEmailForm = () => {

  const authContext = useContext(AuthContext)
  
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<Error | undefined>(undefined)
  
  const resendVerifyEmail = async () => {
    if (isSubmitting) return
    setEmailSent(false)
    setEmailError(undefined)
    setIsSubmitting(true)
    try {
      await authContext.actions.resendVerifyEmail()
      setEmailSent(true)
      setIsSubmitting(false)
    } catch (error: any) {
      setEmailSent(false)
      setIsSubmitting(false)
      setEmailError(error)
    }
  }

  return (
    <div>
      
      <h2>Check Your Email</h2>

      {emailSent && (
        <div>{/* TODO: positive message */}
          <h3>Email Sent</h3>
          <p>A verify link has been sent to your email address.</p>
          <p>Please follow the link in the email to complete your verification.</p>
        </div>
      )}

      {emailError && (
        <div>{/* TODO: negative message */}
          <h3>Email Error</h3>
          <p>There was a problem re-sending a verify link to your email address.</p>
          <p>Please try again or contact us if the problem persists.</p>
        </div>
      )}

      {!emailSent && (
        <div>
          <p>We sent a verification email when you registered...</p>
          <p>Please follow the link in the verification email sent to you during sign-up.</p>
          <hr />
          <p>If you can&apos;t find the email, click below to re-send it.</p>
          <button onClick={resendVerifyEmail} disabled={isSubmitting}>
            Re-Send Verify Email
          </button>
        </div>
      )}

    </div>
  )
}

export default VerifyEmailForm
