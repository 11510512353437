import React, { createContext, ReactNode, useState } from 'react'
import { useMountEffect, useUnmountEffect } from '../hooks'
import { Model } from '../models'
import { ChatMessage } from '../models/ChatMessage'
import { ServerAPIClient } from '../services'
import OpenAIAPIClient from 'src/services/OpenAIAPIClient'
const { encode /*, decode */ } = require('@nem035/gpt-3-encoder')

export enum APIMode {
  internal, direct
}

export interface ChatCompletionResultChoice {
  finish_reason: string
  index: number
  message: {
    role: string
    content: string
  }
}
export interface ChatCompletionResult {
  id: string
  model: string
  choices: Array<ChatCompletionResultChoice>
  created: number
  object: string // e.g: "chat.completion"
  usage: { prompt_tokens: number, completion_tokens: number, total_tokens: number }
}

export interface TextCompletionResultChoice {
  finish_reason: string
  index: number
  text: string
  logprobs: string // TODO: check type?
}
export interface TextCompletionResult {
  id: string
  model: string
  choices: Array<TextCompletionResultChoice>
  created: number
  object: string // e.g: "text_completion"
  usage: { prompt_tokens: number, completion_tokens: number, total_tokens: number }
}

export const ChatContext = createContext<IChatContext>({} as IChatContext)

interface IChatStore {
  loadingModels: boolean
  models?: Array<Model>
}

interface IChatActions {
  loadModels: () => Promise<Array<Model>>
  createCompletion: (model: string, prompt: string, temperature?: number, topP?: number, maxTokens?: number) => Promise<TextCompletionResult>
  createCompletionDirect: (model: string, prompt: string, temperature?: number, topP?: number, maxTokens?: number) => Promise<TextCompletionResult>
  createChatCompletion: (model: string, messages: Array<ChatMessage>, temperature?: number, topP?: number, maxTokens?: number, apiMode?: APIMode) => Promise<ChatCompletionResult>
  createChatCompletionDirect: (model: string, messages: Array<ChatMessage>, temperature?: number, topP?: number, maxTokens?: number, apiMode?: APIMode) => Promise<ChatCompletionResult>
  cancelCompletion: () => Promise<void>
  cancelCompletionDirect: () => Promise<void>
  cancelChatCompletion: () => Promise<void>
  cancelChatCompletionDirect: () => Promise<void>
  calcPromptTokens: (prompt: string) => number
  calcMessageTokens: (messages: Array<ChatMessage>) => number
}

interface IChatContext {
  actions: IChatActions
  store: IChatStore
}

interface ChatProviderProps {
  apiClient: ServerAPIClient
  openAIApiClient?: OpenAIAPIClient
  children: ReactNode
}

const ChatProvider = (props: ChatProviderProps) => {
  const { apiClient, openAIApiClient, children } = props

  const [loadingModels, setLoadingModels] = useState<boolean>(false)
  const [models, setModels] = useState<Array<Model> | undefined>()

  // -------
  
  useMountEffect(() => {
    console.log('ChatProvider - useMountEffect')
    // loadModels() // NB: no longer auto loading on init/mount as we don't always need the list of available models anymore
  })

  useUnmountEffect(() => {
    console.log('ChatProvider - useUnmountEffect')
  })

  // -------

  const loadModels = async (): Promise<Array<Model>> => {
    console.log('ChatProvider - loadModels')
    try {
      setLoadingModels(true)
      const response = await apiClient.apiGet('/query/models')
      console.log('ChatProvider - loadModels - response: ', response)
      if (response.data && response.data.models) {
        const modelsData = response.data.models
        console.log('ChatProvider - loadModels - modelsData: ', modelsData)
        const _models: Array<Model> = []
        for (const modelData of modelsData) {
          const model = Model.fromJSON(modelData)
          if (model) _models.push(model)
        }
        setLoadingModels(false)
        setModels(_models)
        return _models
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ChatProvider - loadModels - error: ', error)
      setLoadingModels(false)
      throw error
    }
  }

  const calcPromptTokens = (prompt: string): number => {
    console.log('ChatProvider - calcPromptTokens - prompt:', prompt)
    const encoded = encode(prompt)
    return encoded.length
  }
  const calcMessageTokens = (messages: Array<ChatMessage>): number => {
    console.log('ChatProvider - calcPromptTokens - messages:', messages)
    let prompt = ''
    for (const chatMessage of messages) {
      prompt += chatMessage.content.trim() + "\n\n" // TODO: should we add new lines?
    }
    return calcPromptTokens(prompt)
  }

  const createCompletion = async (model: string, prompt: string, temperature?: number, topP?: number, maxTokens?: number): Promise<TextCompletionResult> => {
    console.log('ChatProvider - createCompletion - model:', model, ' prompt:', prompt, ' temperature:', temperature, ' topP:', topP, ' maxTokens:', maxTokens)
    try {
      const data: {[key: string]: any} = {
        model,
        prompt
      }
      if (temperature !== undefined) data['temperature'] = temperature
      if (topP !== undefined) data['topP'] = topP
      if (maxTokens  !== undefined) data['maxTokens'] = maxTokens
      console.log('ChatProvider - createCompletion - data: ', data)
      const response = await apiClient.apiPost('/query/completions', data)
      console.log('ChatProvider - createCompletion - response: ', response)
      if (response.data && response.data.result) {
        return response.data.result as TextCompletionResult
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ChatProvider - createCompletion - error: ', error)
      throw error
    }
  }

  const createCompletionDirect = async (model: string, prompt: string, temperature?: number, topP?: number, maxTokens?: number): Promise<TextCompletionResult> => {
    console.log('ChatProvider - createCompletionDirect - model:', model, ' prompt:', prompt, ' temperature:', temperature, ' topP:', topP, ' maxTokens:', maxTokens)
    try {
      const data: {[key: string]: any} = {
        model,
        prompt
      }
      if (temperature !== undefined) data['temperature'] = temperature
      if (topP !== undefined) data['top_p'] = topP
      if (maxTokens  !== undefined) data['max_tokens'] = maxTokens
      // 'frequency_penalty': 0.0,
      // 'presence_penalty': 0.0,
      console.log('ChatProvider - createCompletionDirect - data: ', data)
      const _apiClient = openAIApiClient
      if (!_apiClient) throw new Error('API Client not avilable')
      const response = await _apiClient.apiPost('/completions', data)
      console.log('ChatProvider - createCompletionDirect - response: ', response)
      if (response.data) {
        return response.data as TextCompletionResult
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ChatProvider - createCompletionDirect - error: ', error)
      throw error
    }
  }

  const createChatCompletion = async (model: string, messages: Array<ChatMessage>, temperature?: number, topP?: number, maxTokens?: number, apiMode?: APIMode): Promise<ChatCompletionResult> => {
    console.log('ChatProvider - createChatCompletion - model:', model, ' messages:', messages, ' temperature:', temperature, ' topP:', topP, ' maxTokens:', maxTokens, ' apiMode:', apiMode)
    // TESTING: 
    let responseMaxTokens = maxTokens
    if (maxTokens !== undefined) {
      const tokenCount = calcMessageTokens(messages)
      console.log('ChatProvider - createChatCompletion - tokenCount:', tokenCount)
      if (maxTokens && typeof tokenCount === 'number') {
        responseMaxTokens = maxTokens - tokenCount - 10
      }
    }
    console.log('ChatProvider - createChatCompletion - responseMaxTokens:', responseMaxTokens)
    try {
      const data: {[key: string]: any} = {
        model,
        messages,
        temperature
      }
      if (responseMaxTokens !== undefined) data.maxTokens = responseMaxTokens
      const response = await apiClient.apiPost('/query/chat/completions', data)
      console.log('ChatProvider - createChatCompletion - response: ', response)
      if (response.data && response.data.result) {
        return response.data.result as ChatCompletionResult
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ChatProvider - createChatCompletion - error: ', error)
      throw error
    }
  }

  const createChatCompletionDirect = async (model: string, messages: Array<ChatMessage>, temperature?: number, topP?: number, maxTokens?: number, apiMode?: APIMode): Promise<ChatCompletionResult> => {
    console.log('ChatProvider - createChatCompletionDirect - model:', model, ' messages:', messages, ' temperature:', temperature, ' topP:', topP, ' maxTokens:', maxTokens, ' apiMode:', apiMode)
    // TESTING: 
    let responseMaxTokens = maxTokens
    if (maxTokens !== undefined) {
      const tokenCount = calcMessageTokens(messages)
      console.log('ChatProvider - createChatCompletionDirect - tokenCount:', tokenCount)
      if (maxTokens && typeof tokenCount === 'number') {
        responseMaxTokens = maxTokens - tokenCount - 10
      }
    }
    console.log('ChatProvider - createChatCompletionDirect - responseMaxTokens:', responseMaxTokens)
    try {
      const data: {[key: string]: any} = {
        model,
        messages,
        temperature
      }
      if (responseMaxTokens !== undefined) data.max_tokens = responseMaxTokens
      if (topP) data.top_p = topP
      const _apiClient = openAIApiClient
      if (!_apiClient) throw new Error('API Client not avilable')
      const response = await _apiClient.apiPost('/chat/completions', data)
      console.log('ChatProvider - createChatCompletionDirect - response: ', response)
      if (response.data && response.data) {
        return response.data as ChatCompletionResult
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ChatProvider - createChatCompletionDirect - error: ', error)
      throw error
    }
  }

  // -------

  const cancelCompletion = async () => {
    await apiClient.apiAbort('/query/completions', 'post') // WARNING: will currently cancel any & all current api requests not a specific one!
  }

  const cancelCompletionDirect = async () => {
    if (openAIApiClient) await openAIApiClient.apiAbort('/completions', 'post') // WARNING: will currently cancel any & all current api requests not a specific one!
  }

  const cancelChatCompletion = async () => {
    await apiClient.apiAbort('/query/chat/completions', 'post') // WARNING: will currently cancel any & all current api requests not a specific one!
  }

  const cancelChatCompletionDirect = async () => {
    if (openAIApiClient) await openAIApiClient.apiAbort('/chat/completions', 'post') // WARNING: will currently cancel any & all current api requests not a specific one!
  }

  // -------

  const actions: IChatActions = {
    loadModels,
    createCompletion,
    createCompletionDirect,
    createChatCompletion,
    createChatCompletionDirect,
    cancelCompletion,
    cancelCompletionDirect,
    cancelChatCompletion,
    cancelChatCompletionDirect,
    calcPromptTokens,
    calcMessageTokens
  }

  const store: IChatStore = {
    loadingModels,
    models
  }

  return (
    <ChatContext.Provider value={{ actions, store }}>
      {children}
    </ChatContext.Provider>
  )
}

export default ChatProvider
