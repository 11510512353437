//import { EventEmitter } from 'events'

import { AxiosResponse } from "axios"
import { User, UserInviteLookup, UserResetPassLookup } from "../models"
import ServerAPIClient, { ServerAPIError } from "./ServerAPIClient"

export type ServerAuthAPIAuthChangedCalback = (user?: User) => void

class ServerAuthAPI { //extends EventEmitter {
  private _apiClient: ServerAPIClient
  private _delegates: Array<any>

  public authUser?: User
  public authToken?: string
  public authDeviceUUID?: string
  public loading: boolean = false
  public initalStateLoaded: boolean = false
  
  constructor (apiClient: ServerAPIClient) {
    // super()
    this._apiClient = apiClient
    this._delegates = []
    this._load()
  }

  private _load = async () => {
    this.loadDeviceUUID()
    console.log('ServerAuthAPI - _load - authDeviceUUID:', this.authDeviceUUID)
  }

  // -------

  public addDelegate(delegate: ServerAuthAPIAuthChangedCalback) {
    console.log('ServerAuthAPI - addDelegate')
    if (!this._delegates.includes(delegate)) this._delegates.push(delegate)
  }

  public removeDelegate(delegate: ServerAuthAPIAuthChangedCalback) {
    if (this._delegates.includes(delegate)) {
      const index = this._delegates.indexOf(delegate)
      if (index >= 0) this._delegates.splice(index, 1)
    }
  }

  private _emitOnAuthChange(user?: User) {
    for (const d of this._delegates) {
      d(user)
    }
  }

  // -------

  isLoggedIn = () => {
    return this.authToken !== undefined && this.authUser !== undefined
  }

  updateAuthToken (authToken?: string) {
    this._apiClient.authToken = authToken
    this.authToken = authToken
  }

  // -------

  // handles if a user was logged in & has auth details cached in localStoarge
  initLoggedInUser = async () => {
    console.log('ServerAuthAPI - initLoggedInUser')
    // check if an auth token is saved locally, if so use it to trigger a User update
    // TODO: move most/all of this within ServerAuthAPI? (now the user object is also moving inside it)
    const jsonData = localStorage.getItem('user')
    const userData = jsonData ? JSON.parse(jsonData) : undefined
    console.log('ServerAuthAPI - initLoggedInUser - userData: ', userData)
    if (userData) {
      this.updateAuthToken(userData.authToken)
      // load the user data as a way to test the auth token (& trigger a token refresh attempt if its expired/invalid, logout if that fails)
      return await this.loadLoggedInUser()
    } else {
      this.initalStateLoaded = true // not logged in so flag this as true
      return null
    }
  }

  // calls /auth [GET] to get the auth user object
  // can also be used as a way to validate the current auth token on page (re)loads etc.
  loadLoggedInUser = async (): Promise<User | undefined> => {
    // TODO: halt if not logged in (no auth token)?
    try {
      this.loading = true
      // await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
      const response = await this._apiClient.apiGet('/auth')
      let user: User | undefined
      if (response.data) {
        //if (response.data.result) {
          if (response.data.user && response.data.user.id) {
            user = User.fromJSON(response.data.user) ?? undefined
            this.authUser = user
          }
          this.saveAuthUserCache()
        //}
      }
      console.log('ServerAuthAPI - loadLoggedInUser - user: ', user)
      this.loading = false
      this.initalStateLoaded = true
      this._emitOnAuthChange(user) //this.emit(ServerEventTypeAuth, user)
      return user
    } catch (error) {
      console.error('ServerAuthAPI - loadLoggedInUser - error: ', error)

      this.loading = false
      this.initalStateLoaded = true

      // NB: the ServerAPIClient (apiGet function call) now handles expired with tokens
      // NB: it attempts to renew the auth token with the refresh token & resumes the original api call on success
      // NB: & triggers a logout if the renwal fails (the refresh token has also expired)

      // if the error isn't an auth token expired one, trigger a logout if we reach here (auth token expiry already calls logout)
//      if (!(error instanceof ServerAuthTokenExpiredError)) {
//        this.logout()
//      }

      throw error
    }
  }

  // -------

  registerUserWithEmailAndPassword = async (email: string, password: string, name?: string) => {
    this.authUser = undefined // TODO: don't just wipe, if its not null halt the login attempt?
    this.updateAuthToken(undefined)

    // await new Promise(resolve => setTimeout(resolve, 1000))
    // throw new ServerError('DUMMY ERROR') // DEBUG ONLY <<<
    // return

    try {
      const headers = this.getDeviceAuthHeaders()
      const data: any = { email: email, password: password }
      if (name) data.name = name
      const response = await this._apiClient.apiPost('/auth/register', data, headers)

      let user: User | undefined
      if (response.status === 201) {
        if (response.data) {
          //if (response.data.result) {
            // TESTING: re-use the login response processing as the register response is now the same
            user = this._processLoginSuccessResponse(response)
          //}
        }
      } else {
        console.log('ServerAuthAPI - registerUserWithEmailAndPassword - WARNING: UNEXPECTED STATUS CODE: ', response.status)
        // TODO: throw an error?
        throw new ServerAPIError('Invalid response')
      }
      //this.emit(ServerEventTypeAuth, user) // TODO: no need to re-call this? the _processLoginSuccessResponse() call above does already??
      return user
    } catch (error) {
      console.error('ServerAuthAPI - registerUserWithEmailAndPassword - error: ', error)

      // TODO: PORT/support...
      // if (error && error instanceof ServerAPIError && error.data && error.data.error_code) {
      //   const errorCode = error.data.error_code
      //   if (errorCode === ServerErrorCodes.passwordPolicy && error.data.cause) {
      //     console.error('ServerAuthAPI - registerUserWithEmailAndPassword - PASSWORD POLICY ERROR - error.data: ', error.data)
      //     throw new ServerAuthPasswordPolicyError(error.message, error.data.cause.policyRules, error.data.cause.policyViolations)
      //   }
      // }

      // this.emit(ServerEventTypeAuth, null) // TESTING
      throw error
    }
  }

  loginUserWithEmailAndPassword = async (email: string, password: string) => {
    this.authUser = undefined // TODO: don't just wipe, if its not null halt the login attempt?
    this.updateAuthToken(undefined)
    
    // await new Promise(resolve => setTimeout(resolve, 1000))
    // throw new ServerError('DUMMY ERROR') // DEBUG ONLY <<<
    // return

    try {
      const headers = this.getDeviceAuthHeaders()
      console.log('ServerAuthAPI - loginUserWithEmailAndPassword - headers: ', headers)
      const data: any = { email: email, password: password }
      const response = await this._apiClient.apiPost('/auth/login', data, headers)
      console.log('ServerAuthAPI - loginUserWithEmailAndPassword - response: ', response)
      let user: User | undefined

      if (response.data) {
        //if (response.data.result) {
          user = this._processLoginSuccessResponse(response)
        //}
      }
      return user
    } catch (error: any) {
      console.error('ServerAuthAPI - loginUserWithEmailAndPassword - error: ', error)

      // TODO: just moved here from the higher Server wrapper - finish porting/implementing auth errors here & then all the other auth api calls...
      if (error && error.response) {
        console.error('Server - loginUserWithEmailAndPassword - error.response: ', error.response)
        // Unauthorized error
        if (error.response.status === 401) {
          // TODO: check for email not verified response
          // TODO: check for 2fa requirement?

          // generic error message handling
          if (error.response.data && error.response.data.status && error.response.data.status === 'Error' && error.response.data.error) {
            // TODO: add a more specific error type?
//            throw new ServerError('Error: ' + error.response.data.error, error.response.data.status)
          }
        }
        // TODO: handle other error types...
      }
      throw error
    }
  }

  logout = () => {
    this.authUser = undefined
    this.updateAuthToken(undefined)
    // this.authEmail = undefined
    // this.authName = undefined
    // // NB: we don't clear the authDeviceUUID/deviceUUID on logout
    // // TODO: clear cached versions of the token(s) in other classes?
    // this._authLoadRetryCount = 0
    // // localStorage.removeItem('user')
    this.clearAuthUserCache()
    this._emitOnAuthChange(undefined)
  }

  // -------

  changePassword = async (oldPass: string, newPass: string): Promise<void> => {
    try {
      const data: any = { oldPass: oldPass, newPass: newPass }
      const response = await this._apiClient.apiPut('/auth/change-password', data) //, headers) // TODO: implement this api endpoint server side <<<<
      console.log('ServerAuthAPI - changePassword - response: ', response)
      // TODO: return success indicator? (or rely on thrown errors?)
    } catch (error: any) {
      console.error('ServerAuthAPI - changePassword - error: ', error)
      throw error
    }
  }

  // -------

  resendVerifyEmail = async () => {
    // TODO: only allow this call if the user is flagged as unverified?
    const email = this.authUser?.email
    if (!email) {
      console.error('ServerAuthAPI - resendVerifyEmail - ERROR: authUser has no email set')
      throw new ServerAPIError('Email not set')
    }
    try {
      const response = await this._apiClient.apiPost('/auth/verify/resend', { email: email })
      if (response.status === 200) {
        return true
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - resendVerifyEmail - error: ', error)
      throw error
    }
  }

  verifyEmailToken = async (verifyToken: string) => {
    if (!verifyToken || verifyToken.length === 0) {
      throw new ServerAPIError('Invalid verify token')
    }
    try {
      const response = await this._apiClient.apiPost('/auth/verify/', { verifyToken })
      if (response.status === 200 || response.status === 201) {
        const user = await this.loadLoggedInUser()
        //this.loading = false
        this.authUser = user
        this._emitOnAuthChange(user)
        return true
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - verifyEmailToken - error: ', error)
      throw error
    }
  }

  // -------

  forgotEmailPassword = async (email: string) => {
    const data: {[key: string]: any} = {
      email: email
    }
    try {
      const response = await this._apiClient.apiPost('/auth/forgot-password/', data)
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - forgotEmailPassword - error: ', error)
      throw error /// / NB: for now we just return a simple success/error bool result
    }
  }

  // -------
  
  checkForgotPassToken = async (resetToken: string) => {
    if (!resetToken || resetToken.length === 0) {
      throw new ServerAPIError('Invalid reset password token')
    }
    try {
      const response = await this._apiClient.apiGet('/auth/forgot-password/verify/' + resetToken)
      if (response.status === 200) {
        if (response.data && response.data.tokenData) {
          const userResetPassLookup = UserResetPassLookup.fromJSON(response.data.tokenData)
          if (userResetPassLookup) return userResetPassLookup
        }
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - checkForgotPassToken - error: ', error)
      throw error
    }
  }

  resetEmailPassword = async (resetToken: string, newPassword: string) => {
    const data: {[key: string]: any} = {
      forgotPassToken: resetToken,
      newPass: newPassword
    }
    try {
      const response = await this._apiClient.apiPost('/auth/forgot-password/verify/', data)
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerAuthAPI - resetEmailPassword - error: ', error)
      throw error /// / NB: for now we just return a simple success/error bool result
    }
  }

  // -------

  checkInviteToken = async (inviteToken: string) => {
    if (!inviteToken || inviteToken.length === 0) {
      throw new ServerAPIError('Invalid invite token')
    }
    try {
      const response = await this._apiClient.apiGet('/auth/invite/' + inviteToken)
      if (response.status === 200) {
        if (response.data && response.data.invite) {
          const userInviteLookup = UserInviteLookup.fromJSON(response.data.invite)
          if (userInviteLookup) return userInviteLookup
        }
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - acceptInvite - error: ', error)
      throw error
    }
  }

  // NB: this acts as an alternative to registration
  acceptInvite = async (inviteToken: string, password: string, name?: string) => {
    this.authUser = undefined // TODO: don't just wipe, if its not null halt the login attempt?
    this.updateAuthToken(undefined)

    if (!inviteToken || inviteToken.length === 0) {
      throw new ServerAPIError('Invalid invite token')
    }
    try {
      const headers = this.getDeviceAuthHeaders()
      const response = await this._apiClient.apiPost('/auth/invite/', { inviteToken, pass: password, name }, headers)
      let user: User | undefined
      if (response.status === 201) {
        if (response.data) {
          //if (response.data.result) {
            // TESTING: re-use the login response processing as the register response is now the same
            user = this._processLoginSuccessResponse(response)
          //}
        }
      } else {
        console.log('ServerAuthAPI - registerUserWithEmailAndPassword - WARNING: UNEXPECTED STATUS CODE: ', response.status)
        // TODO: throw an error?
        throw new ServerAPIError('Invalid response')
      }
      //this.emit(ServerEventTypeAuth, user) // TODO: no need to re-call this? the _processLoginSuccessResponse() call above does already??
      return user
    } catch (error) {
      console.error('ServerAuthAPI - acceptInvite - error: ', error)
      throw error
    }
  }

  // -------

  _processLoginSuccessResponse = (response: AxiosResponse<any>): User | undefined => {
    let user: User | undefined

    if (response.data.authToken) {
      this.updateAuthToken(response.data.authToken)
    }

    if (response.data.user) {
      user = User.fromJSON(response.data.user) ?? undefined
      this.authUser = user
    }

    // TODO: handle if we have a token but no user? consider all invalid & force re-login?
    this.saveAuthUserCache()

    console.log('ServerAuthAPI - _processLoginSuccessResponse - emit auth - this.authToken: ', this.authToken)
    this._emitOnAuthChange(user)

    // clear temp vars only used during registration/login
//    this.authEmail = undefined
//    this.authName = undefined

    return user
  }

  // -------

  // -------

  // returns an object with the user/client device headers used to identify this login auth session
  getDeviceAuthHeaders = () => {
// TODO: PORT/IMPLEMENT...
/*
    // load the main device details (from the browser user agent)
    const deviceData = deviceDetect(undefined)
    // console.log('ServerAuthAPI - getDeviceHeaders - deviceData: ', deviceData, ' deviceType: ', deviceType, ' deviceData.osName: ', deviceData.osName, ' osName: ', osName, 'osVersion: ', osVersion, ' deviceData.browserName: ', deviceData.browserName, ' browserName: ', browserName)
    // construct the user friendly display name (other fields are mainly for identifying a particular session)
    let deviceName = osName + ' ' + browserName + ' ' + 'Web Browser'
    if (deviceType === 'mobile' || deviceType === 'tablet') {
      deviceName = mobileModel + ' ' + browserName // + deviceData.osName +
    }
    // contruct the header object with all device/client fields ready to append to the api call headers that uses them (currently registration & login endpoints)
    const headers: any = {
      'device-name': deviceName,
      'device-os': osName.toLowerCase() + '~' + osVersion.toLowerCase(), // e.g. 'mac os~10.x.x'
      // WARNING: the api doesn't not support/use these yet - added as an example for hopeful addition soon
      // TODO: check back once the api supports these (or similar) & make sure all field names are correct, adapt as needed...
      'device-type': deviceType, // desktop/mobile/tablet (NB: currently returns 'browser' for desktop chrome, think desktop will always be 'browser' for this field?)
      client: browserName.toLowerCase() + '~' + (deviceData?.browserMajorVersion ?? browserVersion).toLowerCase(), // NB: using browserVersion as a fallback if deviceData?.browserMajorVersion isn't available (iOS?) - TODO: drop any point versions from the fallback full version?
      platform: 'web'
    }
*/
    // TMP:
    const headers: any = {
      platform: 'web'
    }
    console.log('ServerAuthAPI - getDeviceHeaders - headers: ', headers)
    return headers
  }

  // -------

  saveAuthUserCache = () => {
    localStorage.setItem('user', JSON.stringify({
      ...(this.authUser ? { id: this.authUser ? this.authUser.id : 0 } : {}),
      ...(this.authToken ? { authToken: this.authToken } : {})
    }))
  }

  clearAuthUserCache = () => {
    localStorage.removeItem('user')
  }

  // -------

  // checks if a uuid has been saved for this user (browser & possibly session specific)
  // loads it if so, creates & saves one to the localStorage if not
  loadDeviceUUID = async () => {
    const jsonData = localStorage.getItem('device')
    const deviceData = jsonData ? JSON.parse(jsonData) : null
    if (deviceData && deviceData.uuid) {
      this.authDeviceUUID = deviceData.uuid
    } else {
      const deviceUUID = this.generateUUID()
      localStorage.setItem('device', JSON.stringify({
        uuid: deviceUUID
      }))
      this.authDeviceUUID = deviceUUID
    }
    this._apiClient.authDeviceUUID = this.authDeviceUUID // update the client so it gets added to all requests
  }

  // ref: https://stackoverflow.com/a/8809472
  generateUUID = () => {
    let d = new Date().getTime() // Timestamp
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0 // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 // random number between 0 and 16
      if (d > 0) { // Use timestamp until depleted
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else { // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      // return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16) // TODO: testing wrapping 'r & 0x3' in brackets to fix the a 'Unexpected mix of '&' and '|'' warning, is that the correct way to apply brackets to this??
    })
  }

}

export default ServerAuthAPI
