/**
 * Select
 * 
 * - NB: currently trialing: react-select.com as a more feature complete & customisable select dropdown element than a css only styled default one
 * - NB: might also want to consider `react-dropdown-select` if `react-select` proves to limited for current needs - ref: https://github.com/sanusart/react-dropdown-select
 * 
 * React Select refs:
 *  - https://react-select.com/styles
 *  - https://react-select.com/styles#the-classnames-prop
 *  - https://react-select.com/styles#inner-components # NB: see these for a list of the inner component keys that can be used for the `classNames` overrides
 */
import React, { useEffect, useRef, useState } from 'react'
import ReactSelect, { ActionMeta, SingleValue } from 'react-select' // NB: aliasing the default `Select` import as `ReactSelect` so it doesn't clash with our own component declaration

import styles from './Select.module.css'

interface SelectOption {
  value?: string | number
  label?: string | React.ReactNode
  className?: string
}
// const SelectOption = (props: SelectOptionProps) => {
//   const { value, children, className } = props
//   return <option className={`${styles.option} ${className}`} value={value}>{children}</option>
// }

interface SelectProps {
  options?: Array<SelectOption>
  defaultValue?: string | number
  placeholder?: string | React.ReactNode
  disabled?: boolean
  isLoading?: boolean
  onChange?: (newValue?: string | number) => void
  className?: string
  children?: React.ReactNode // TODO: DEPRECIATE - force use of `options` prop instead?
  //ref?: React.RefAttributes<ReactSelect<SelectOption, false, GroupBase<SelectOption>>>
  slimline?: boolean
}
const Select = (props: SelectProps) => {
  const { options, defaultValue, placeholder, disabled, isLoading, onChange: _onChange, className, slimline } = props
  
  const formatOptionLabel = ({ value, label, className }: SelectOption) => (
    <div className={styles.optionLabel + (className ? ' ' + className : '')}>
      <div>
        {label} 
      </div>
      {/* <div>&nbsp;({value})</div> */}
    </div>
  )
  
  const defaultValueRef = useRef<SelectOption | undefined>(undefined)
  //const currentValueRef = useRef<SelectOption | undefined>(undefined)
  const [currentValue, setCurrentValue] = useState<SelectOption | undefined>(undefined)
  // defaultValueRef.current = options?.find(o => o.value === defaultValue) // map the `value` string | number to an actual option entry to pass into react-select (as it expects the whole option, not just the value)
  useEffect(() => {
    const prevDefaultValue = defaultValueRef.current
    const newDefaultValue = options?.find(o => o.value === defaultValue)
    // console.log('Select - useEffect - defaultValue:', defaultValue, ' prevDefaultValue:', prevDefaultValue, ' newDefaultValue:', newDefaultValue, ' defaultValueRef.current:', defaultValueRef.current)
    if (!prevDefaultValue || prevDefaultValue.value !== newDefaultValue?.value) {
      defaultValueRef.current = newDefaultValue
      // if (currentValue === undefined) setCurrentValue(newDefaultValue) // TESTING: also set the current value to the default if its not been set yet
      setCurrentValue(newDefaultValue) // UPDATE: TESTING allow the default value to always update the local - TODO: add a `value` prop instead that can optionally be used like this & revert the `defaultValue` to work as it did before
    }
  }, [defaultValue, options])

  const onChange = (newValue: SingleValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
    console.log('Select - onChange - newValue:', newValue)
    // TODO: only trigger this if the value actually changes from its previous one (currently fires each time its clicked regardless)
    // NB: skipping the previous `&& newValue.value !== undefined` check & changed the `onChange` prop to accept an optional value so we can set/pass `undefined` if its used as a key (e.g. for a default/no value entry)
    if (_onChange && newValue) _onChange(newValue.value)
    setCurrentValue(newValue ?? undefined)
  }

  return (
    <div className={`select ${styles.select}${className ? ' ' + className : ''}${slimline ? ' ' + styles.slimline : ''}${disabled ? ' ' + styles.disabled : ''}`}>
      <ReactSelect
        className={styles.select}
        classNames={{
          //control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300',
          container: (state) => state.isFocused ? styles.container + ' ' + styles.containerFocused : styles.container,
          control: (state) => styles.control,
          menu: (state) => styles.menu,
          option: (state) => styles.option,
          indicatorsContainer: (state) => styles.indicatorsContainer,
          dropdownIndicator: (state) => styles.dropdownIndicator,
          indicatorSeparator: (state) => styles.indicatorSeparator,
          input: (state) => styles.input,
          placeholder: (state) => styles.placeholder,
          valueContainer: (state) => styles.valueContainer,
          singleValue: (state) => styles.singleValue,
        }}
        formatOptionLabel={formatOptionLabel}
        options={options} //[{ value: 'value01', label: <>Value 01</> }, { value: 'value02', label: <>Value 02</> }]}
        placeholder={placeholder}
        //defaultValue={defaultValueRef.current} // NB: don't seem to be able to change it after init (which makes sense), but doesn't work if we're loading the options & showing this with `isLoading` first, so now using the `value` prop & tracking it here instead :/
        value={currentValue ?? defaultValueRef.current}
        onChange={onChange}
        isLoading={isLoading}
        // ref={ref}
        isDisabled={disabled}
      />
      <span className={styles.focus}></span>
    </div>
  )
}

// Select.Option = SelectOption // TODO: DEPRECIATE THIS USAGE? now requires an array of `options` to be passed in as a dedicated prop <<<

export default Select
