import React from 'react'

import BasePage from '../../components/BasePage/BasePage'
import LoaderView from '../../components/LoaderView/LoaderView'

const LoadingPage = () => {
  return (
    <BasePage>
      <LoaderView />
    </BasePage>
  )
}

export default LoadingPage
