import React from 'react'

import { ChatMessage, ChatMessageRole } from '../../models/ChatMessage'

import Select from '../../components/Select'
import ChatMessageTextArea from './ChatMessageTextArea'

import styles from './ChatMessagesField.module.css'


export interface ChatMessagesFieldProps {
  messages?: Array<ChatMessage>
  onAddMessage:  () => void
  onUpdateMessageRole: (index: number, role: ChatMessageRole) => void
  onUpdateMessageContent: (index: number, content: string) => void
  onDeleteMessage: (index: number) => void
}

const ChatMessagesField = (props: ChatMessagesFieldProps) => {
  const {
    messages,
    onAddMessage: _onAddMessage,
    onUpdateMessageRole: _onUpdateMessageRole,
    onUpdateMessageContent: _onUpdateMessageContent,
    onDeleteMessage: _onDeleteMessage
  } = props

  const onAddMessage = () => {
    // console.log('ChatMessagesField - onAddMessage')
    if (_onAddMessage) _onAddMessage()
  }

  const onUpdateMessageRole = (index: number, role: ChatMessageRole) => {
    // console.log('ChatMessagesField - onUpdateMessageRole - index:', index, ' role:', role)
    if (_onUpdateMessageRole) _onUpdateMessageRole(index, role)
  }

  const onUpdateMessageContent = (index: number, content: string) => {
    console.log('ChatMessagesField - onUpdateMessageContent - index:', index, ' content:', content)
    if (_onUpdateMessageContent) _onUpdateMessageContent(index, content)
  }

  const onDeleteMessage = (index: number) => {
    // console.log('ChatMessagesField - onDeleteMessage - index:', index)
    if (_onDeleteMessage) _onDeleteMessage(index)
  }

  return (
    <div className={styles.messagesField}>
      <label htmlFor="messages">Messages:</label>
      {messages && messages.length > 0 && messages.map((message, index) => {
        return (
          <div className={styles.message} key={'message_' + messages.length + '_' + index}>{/* NB: added in the array length to the key so deletes cause a full re-render */}
            <div className={`${styles.field} ${styles.messageRole}`}>
              {/* <label htmlFor="messageRole">Role:</label> */}
              <div className={styles.input}>
                <Select
                  options={[{ value: ChatMessageRole.system, label: 'System' }, { value: ChatMessageRole.user, label: 'User' }, { value: ChatMessageRole.assistant, label: 'Assistant' }]}
                  defaultValue={message.role}
                  onChange={(newValue?: string | number) => {
                    // console.log('ChatMessagesField - message - role - onChange - newValue:', newValue)
                    onUpdateMessageRole(index, newValue as ChatMessageRole)
                  }}
                  className={styles.messageRole}
                >
                </Select>
              </div>
            </div>
            <div className={`${styles.field} ${styles.messageContent}`}>
              {/* <label htmlFor="messageContent">Content:</label> */}
              <div className={styles.input}>
                <ChatMessageTextArea
                  id="messageContent"
                  name="messageContent"
                  // required
                  rows={2}
                  cols={80}
                  onChange={(event) => {
                    onUpdateMessageContent(index, event.target.value)
                  }}
                  defaultValue={message.content}
                  // value={message.content}
                  minHeight={36}
                ></ChatMessageTextArea>
              </div>
            </div>
            <div className={`${styles.field} ${styles.button} ${styles.messageDelete}`}>
            <input type="button" value="x" onClick={() => onDeleteMessage(index)} />
            </div>
          </div>
        )
      })}
      <div className={`${styles.field} ${styles.button} ${styles.messageAdd}`}>
        <input type="button" value="+ Add Message" onClick={onAddMessage} />
      </div>
    </div>
  )
}

export default ChatMessagesField
