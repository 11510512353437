import React, { useContext, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'

import { ProjectEditorContext } from 'src/providers'

import Button from 'src/components/Button'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader/Loader'

import styles from './UserInputNode.module.css'

// NB: MAKE SURE this is updated with all fields that should be saved & loaded for this custom node type!
export const UserInputNodeDataKeys = [
  'start',
  'stop',
  'input',
  'output'
]

export interface UserInputNodeProps {
  // saved fields:
  start?: boolean
  input: string
  output?: string
  // runtime only fields (not saved):
  nodeOrder?: number
  isRunning?: boolean
  error?: Error
  // callbacks/other (not saved):
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onDupe?: (id: string) => void // TODO: WARNING: careful using these callbacks with state vars - seems to only be able to access the state data when the callback was set, not when it was called
}

const UserInputNode = (props: NodeProps<UserInputNodeProps>) => {
  const { isConnectable, data } = props

  const projectEditorContext = useContext(ProjectEditorContext)
  
  const maxLength = 100
  const inputText = data.input && data.input.length > maxLength ? data.input.substring(0, maxLength) + '...' : data.input
  // const outputText = data.output && data.output.length > maxLength ? data.output.substring(0, maxLength) + '...' : data.output
  const startNode = data.start ?? false
  const nodeOrder = data.nodeOrder ?? undefined
  const isRunning = data.isRunning ?? false
  const error = data.error ?? undefined
  const [showError, setShowError] = useState<boolean>(false)

  // const canConnectSourceToTarget = (sourceId: string, targetId: string, targetHandle: string) => {
  //   // console.log('UserInputNode - canConnectSourceToTarget - sourceId:', sourceId, ' targetId:', targetId, ' targetHandle:', targetHandle)
  //   const edges = projectEditorContext.store.edges
  //   // console.log('UserInputNode - canConnectSourceToTarget - edges:', edges)
  //   // check if the source node already links to the target node
  //   const sourceToTargetEdge = edges.find((e) => e.source === sourceId && e.target === targetId)
  //   // check if the target handle already has an edge attached/assigned
  //   const targetHandleEdge = edges.find((e) => e.target === targetId && e.targetHandle === targetHandle)
  //   // halt if either are true/assigned
  //   return (sourceToTargetEdge === undefined && targetHandleEdge === undefined)
  // }

  const handleXPos = (index: number) => {
    return 22 + (15 * index)
  }

  // refs:
  //  https://reactflow.dev/docs/guides/custom-nodes/#using-multiple-handles
  const addTargetHandle = (index: number) => {
    const _id = '' + (index + 1)
    return (
      <Handle
        type="target"
        id={_id}
        position={Position.Top}
        style={{ background: '#1a192b', left: handleXPos(index) }}
        // UPDATE: moved to the editor/flow wide handling so all scenarios are caught & handled in one (including moving/updating edges which don't trigger the handle check here)
        // isValidConnection={(connection) => {
        //   console.log('UserInputNode - TargetHandle - isValidConnection', connection)
        //   return true // connection.source === 'some-id' // TODO:
        // }}
        onConnect={(params) => {
          console.log('UserInputNode - TargetHandle - onConnect', params)
        }}
        isConnectable={isConnectable}
      />
    )
  }

  const addSourceHandle = () => {
    return (
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ /* left: 0, */ background: '#1a192b' }}
        // UPDATE: moved to the editor/flow wide handling so all scenarios are caught & handled in one (including moving/updating edges which don't trigger the handle check here)
        // isValidConnection={(connection) => {
        //   console.log('UserInputNode - SourceHandle - isValidConnection', connection)
        //   // return true // connection.source === 'some-id' // TODO:
        //   if (!connection.source || !connection.target || !connection.targetHandle) return false
        //   return canConnectSourceToTarget(connection.source, connection.target, connection.targetHandle)
        // }}
        onConnect={(params) => {
          console.log('UserInputNode - SourceHandle - onConnect', params)
          // DEBUG ONLY:
          // if (params.source && params.target && params.targetHandle) {
          //   canConnectSourceToTarget(params.source, params.target, params.targetHandle)
          // }
        }}
        isConnectable={isConnectable}
      />
    )
  }

  return (
    <div className={styles.node + (startNode ? ' ' + styles.startNode : '') + (isRunning ? ' ' + styles.isRunning : '')}>
      {addTargetHandle(0)}
      {addTargetHandle(1)}
      {addTargetHandle(2)}
      {addTargetHandle(3)}
      {addTargetHandle(4)}
      {addTargetHandle(5)}
      {addTargetHandle(6)}
      {addTargetHandle(7)}
      <div className={styles.content}>
        <div className={styles.nodeId}>#{props.id}</div>
        <div className={styles.input}>
          {/* <span className={styles.label}>INPUT:</span> */}{inputText}
        </div>
        {/* {outputText && (
          <div className={styles.output}>
            <span className={styles.label}>OUTPUT:</span>{outputText}
          </div>
        )} */}
      </div>
      <div className={styles.sidebar}>
        <div className={styles.nodeOrder}>
          {nodeOrder ?? '?'}
        </div>
        <div className={styles.nodeDupe}>
          <Button
            className={styles.nodeDupeBtn + ' nodrag'}
            iconName={'copy'}
            iconSize={9}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event.preventDefault()
              event.stopPropagation()
              // if (data.onDupe) data.onDupe(props.id)
              projectEditorContext.actions.setDupeNode(props.id)
            }}
          />
        </div>
        {isRunning && (
          <div className={styles.nodeRunning}><Loader size={12} /></div>
        )}
        {error && (
          <div className={styles.nodeError} onMouseEnter={() => setShowError(true)} onMouseLeave={() => setShowError(false)}>
            <Icon name={'error'} size={14} className={styles.errorIcon} />
            {showError && (<div className={styles.errorMsg}><h4>ERROR:</h4>{error.message}</div>)}
          </div>
        )}
      </div>
      {/* <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color} /> */}
      {addSourceHandle()}
      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        style={{ right: 0, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      /> */}
    </div>
  )
}

export default UserInputNode
