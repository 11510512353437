import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const NavContext = createContext<INavContext>({} as INavContext)

interface INavStore {
}

interface INavActions {
  goto: (path: string) => void
}

interface INavContext {
  actions: INavActions
  store: INavStore
}

interface NavProviderProps {
  children: ReactNode
}

const NavProvider = (props: NavProviderProps) => {
  const { children } = props

  const location = useLocation()
  const navigate = useNavigate()

  const [prevLocationPath, setPrevLocationPath] = useState<string | undefined>(undefined) // TODO: can this be done via an existing router hook/var instead of tracking manually?

  useEffect(() => {
    console.log('NavProvider - location changed from:', prevLocationPath, ' to:', location.pathname)
    setPrevLocationPath(location.pathname)
  }, [location]) // NB: don't add prevLocationPath to the deps array of it, as it can cause a 2nd (re)trigger when its updated

  // -------

  const goto = (path: string) => {
    console.log('NavProvider - goto - path: ', path)
    navigate(path)
  }

  // -------

  const actions: INavActions = {
    goto
  }

  const store: INavStore = {
  }

  return (
    <NavContext.Provider value={{ actions, store }}>
      {children}
    </NavContext.Provider>
  )
}

export default NavProvider
