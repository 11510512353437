import React, { useContext } from 'react'
import { Link  } from 'react-router-dom'

import { UserContext } from '../../providers'

import Header from '../../components/Header'

import * as ROUTES from '../../constants/routes'

const HomePageLoggedInView = () => {
  const userContext = useContext(UserContext)
  const userIsAdmin = userContext.store.user?.roles && userContext.store.user?.roles.includes('admin')

  return (
    <div>
      <Header title={'Welcome Back '} desc={'Your last login was: ...'}/>
      <ul>
        <li><Link to={ROUTES.PROJECTS}>Projects</Link></li>
        <li><Link to={ROUTES.CHAT_DEBUG}>Chat Debug</Link></li>
        <li><Link to={ROUTES.MODELS}>AI Models</Link></li>
        <li><Link to={ROUTES.NODE_PLAYGROUND}>Node Playground</Link></li>
      </ul>
      {userIsAdmin && (
        <ul>
          <li><Link to={ROUTES.ADMIN}>Admin</Link></li>
        </ul>
      )}
    </div>
  )
}

export default HomePageLoggedInView
