import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Project } from 'src/models'
import { ProjectContext } from 'src/providers'

import Message from 'src/components/Message'
import Button from 'src/components/Button'

import styles from './ProjectAddEditForm.module.css'
import formStyles from 'src/components/Form/Form.module.css'

interface ProjectAddEditFormProps {
  project?: Project
  onClose: Function
  onCancel?: Function
}

const ProjectSaveAsForm = (props: ProjectAddEditFormProps) => {
  const { project, onClose, onCancel } = props
  const projectContext = useContext(ProjectContext)
  
  /**
   * react-hook-form refs:
   *  https://blog.logrocket.com/react-hook-form-complete-guide/
   */
  interface IFormData {
    title: string
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormData>()

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // console.log('ProjectAddEditForm - watch - firstName:', watch('firstName'))

  const onSubmit = async (data: IFormData) => {
    console.log('ProjectSaveAsForm - onSubmit - data:', data)
    if (submitting) return
    setSubmitting(true)
    setSaved(false)
    setError(undefined)
    // await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
    try {
      if (project) {
        await projectContext.actions.saveProjectAs(project, data.title)
      } else {
        throw new Error('Invalid project data')
      }
      setSaved(true)
    } catch (error: any) {
      console.error('ProjectSaveAsForm - onSubmit - error:', error)
      setError(error)
    }
    setSubmitting(false)
  }

  const onError = (errors: any) => { // TOOD: type <<<<
    console.log('ProjectSaveAsForm - onError - errors:', errors)
  }

  // TESTING: submit on enter - ref: https://github.com/react-hook-form/react-hook-form/issues/936
  const onKeyDownInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    console.log('ProjectSaveAsForm - onKeyDownInput - event:', event)
    if (event.key === "Enter" && !event.shiftKey) {
      console.log('ProjectSaveAsForm - onKeyDownInput - OK - SUBMIT...')
      event.preventDefault()
      handleSubmit(onSubmit)()
    }
  }
  // const onKeyDownButton = (event: React.KeyboardEvent<HTMLButtonElement>) => {
  //   console.log('ProjectAddEditForm - onKeyDownButton - event:', event)
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     console.log('ProjectAddEditForm - onKeyDownButton - ENTER - SUBMIT...')
  //     event.preventDefault()
  //   }
  // }

  return (
    <>
      {saved && (
        <div className={styles.successMsg}>
          <Message positive>
            <p>Project copied</p>
          </Message>
          <Button secondary autoFocus onClick={() => { onClose() }} className={styles.successMsgBtn} /* onKeyDown={onKeyDownButton} */>OK</Button>
        </div>
      )}
      {error && (
        <div className={styles.errorMsg}>
          <Message error>
            <p>Error: {error.message}</p>
          </Message>
          {/* <Button secondary onClick={() => { onClose() }} className={styles.errorMsgBtn}>OK</Button> */}
        </div>
      )}
      {!saved && (
        <form onSubmit={handleSubmit(onSubmit, onError)} className={formStyles.form}>
          
          <div className={formStyles.field}>
            <label htmlFor='title'>New Project Title</label>
            <input
              type='text'
              placeholder='New Project Title'
              defaultValue={project ? project.title : undefined}
              size={30}
              autoFocus
              {...register('title', { required: 'Project Title is required' })}
              onKeyDown={onKeyDownInput}
            />
            {errors.title && <div className={formStyles.fieldError}>{errors.title.message}</div>}
          </div>

          <div className={formStyles.fieldGroup + ' spaceEqual padTop'}>
            <div className={formStyles.field}>
              {/* <input type="button" value="Cancel" onClick={() => { if (onCancel) onCancel() }} /> */}
              <Button secondary disabled={submitting} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.preventDefault() // don't submit
                if (onCancel) onCancel()
              }}>Cancel</Button>
            </div>
            <div className={formStyles.field}>
              {/* <input type="submit" value="Save" /> */}
              <Button type='submit' primary loading={submitting} disabled={submitting}>Save</Button>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default ProjectSaveAsForm
