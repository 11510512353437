// public
export const HOME = '/'
export const REGISTER = '/register'
export const LOGIN = '/login'
export const LOGIN_PASSWORD_FORGOT = '/login/forgot-password'
export const LOGIN_PASSWORD_RESET = '/login/reset-password'
export const LOGIN_PASSWORD_RESET_TOKEN = '/login/reset-password/:resetToken'
export const USER_VERIFY = '/user/verify'
export const USER_VERIFY_TOKEN = '/user/verify/:verifyToken'
export const USER_INVITE_ACCEPT = '/invite'
export const USER_INVITE_ACCEPT_TOKEN = '/invite/:inviteToken'

export const ACCOUNT = '/account'
export const ACCOUNT_DETAILS = '/account/details'
export const ACCOUNT_PASSWORD = '/account/password'

export const PROJECTS = '/projects'
export const PROJECT_EDITOR = '/projects/:projectId'

export const CHAT_DEBUG = '/chat/debug'
export const MODELS = '/models'
export const NODE_PLAYGROUND = '/node-playground'

export const ADMIN_ROUTER = '/admin/*'
export const ADMIN = '/admin'
export const ADMIN_USERS = '/admin/users'
export const ADMIN_USERS_INVITE = '/admin/users/invite'

// used to control when to auto redirect away once logged in (if your on one of these auth routes/pages)
export const AUTH_ROUTES = [REGISTER, LOGIN, LOGIN_PASSWORD_FORGOT, LOGIN_PASSWORD_RESET, USER_INVITE_ACCEPT, USER_INVITE_ACCEPT_TOKEN]

