import React from 'react'
import BasePage from '../../../components/BasePage/BasePage'
import CenterLayout from '../../../components/CenterLayout'
import VerifyEmailForm from './VerifyEmailForm'

const VerifyEmailWarningPage = () => {
  return (
    <BasePage>
      <CenterLayout>
        <VerifyEmailForm />
      </CenterLayout>
    </BasePage>
  )
}

export default VerifyEmailWarningPage
