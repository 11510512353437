import React from 'react'
import Loader from '../Loader/Loader'

import styles from './LoaderView.module.css'

const LoaderView = () => {
  return (
    <div className={styles.loaderView}>
      <Loader />
    </div>
  )
}

export default LoaderView
