import ServerAPIClient from './ServerAPIClient'
import { ServerConfig } from '../models/ServerConfig'

class ServerConfigAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  getServerConfig = async (): Promise<ServerConfig> => {
    try {
      const response = await this._apiClient.apiGet('/config')
      console.log('ServerConfigAPI - getServerConfig - response: ', response)
      if (response.data && response.data.config) {
        const configData = response.data.config
        console.log('ServerConfigAPI - getServerConfig - configData: ', configData)
        const serverConfig = ServerConfig.fromJSON(configData)
        if (serverConfig) return serverConfig
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerConfigAPI - getServerConfig - error: ', error)
      throw error
    }
  }

  getOpenAIKey = async (): Promise<string> => {
    try {
      const response = await this._apiClient.apiGet('/query/key')
      console.log('ServerConfigAPI - getOpenAIKey - response: ', response)
      if (response.data && response.data.key) {
        const openAIKey = response.data.key
        console.log('ServerConfigAPI - getOpenAIKey - openAIKey: ', openAIKey)
        if (openAIKey) return openAIKey
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerConfigAPI - getOpenAIKey - error: ', error)
      throw error
    }
  }
}

export default ServerConfigAPI
