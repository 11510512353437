import React, { useContext } from 'react'
import { ChatProvider, ServerContext } from '../../providers'

import PageContent from '../../components/PageContent'
import AIModelsListView from './AIModelsListView'

import styles from './AIModelsPages.module.css'

const AIModelsPages = () => {
  const serverContext = useContext(ServerContext)
  return (
    <PageContent>
      <div className={styles.page}>
        <ChatProvider apiClient={serverContext.store.apiClient} openAIApiClient={serverContext.store.openAIApiClient}>
          <AIModelsListView />
        </ChatProvider>
      </div>
    </PageContent>
  )
}

export default AIModelsPages
