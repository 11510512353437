import React, { useContext, useEffect } from 'react'

import { useMountEffect } from '../../hooks'
import { ChatContext } from '../../providers'

import styles from './AIModelsListView.module.css'

const AIModelsListView = () => {

  const chatContext = useContext(ChatContext)
  const { models } = chatContext.store
  
  // -------
  
  useMountEffect(() => {
    console.log('ChatProvider - useMountEffect')
    chatContext.actions.loadModels()
  })

  useEffect(() => {
    console.log('AIModelsListView - useEffect - models')
  }, [models])
  
  return (
    <ul className={styles.modelList}>
      {models?.map(model => {
        return <li key={'model_' + model.id}>{model.id}</li>
      })}
    </ul>
  )
}

export default AIModelsListView
