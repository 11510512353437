import React, { createContext, ReactNode, useState } from 'react'
import { useMountEffect, useUnmountEffect } from '../hooks'

import { Project } from '../models'

import { ServerAPIClient } from '../services'
import FileSaver from 'file-saver'

export const ProjectContext = createContext<IProjectContext>({} as IProjectContext)

interface IProjectStore {
  projects: Array<Project>
  projectsLoading: boolean
  projectsLoaded: boolean
}

interface IProjectActions {
  getProjectForKey: (key: string) => Promise<Project | undefined>
  getProjectForId: (projectId: number) => Promise<Project | undefined>
  createProject: (title: string) => Promise<Project>
  updateProject: (key: string, title: string) => Promise<Project>
  saveProject: (project: Project) => Promise<Project>
  saveProjectAs: (originalProject: Project, newTitle: string) => Promise<Project>
  deleteProject: (key: string) => Promise<boolean>
  importProject: (file: File) => Promise<Project>
  exportProject: (project: Project, filename: string) => Promise<boolean>
}

interface IProjectContext {
  actions: IProjectActions
  store: IProjectStore
}

interface ProjectProviderProps {
  apiClient: ServerAPIClient
  children: ReactNode
}

const ProjectProvider = (props: ProjectProviderProps) => {
  const { children } = props

  const [projects, setProjects] = useState<Array<Project>>([])
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false)
  const [projectsLoaded, setProjectsLoaded] = useState<boolean>(false)

  // -------

  const loadProjects = () => {
    console.log('ProjectProvider - loadProjects')
    if (projectsLoading) return
    try {
      setProjectsLoading(true)
      const jsonData = localStorage.getItem('projectsCache')
      const projectsData = jsonData ? JSON.parse(jsonData) : undefined
      console.log('ProjectProvider - loadProjects - projectsData:', projectsData)
      const _projects: Array<Project> = []
      if (projectsData) {
        for (const projectData of projectsData) {
          const _project = Project.fromJSON(projectData)
          if (_project) _projects.push(_project)
        }
      }
      setProjects(_projects)
      setProjectsLoaded(true)
    } catch (error: any) {
      console.error('ProjectProvider - loadProjects - error:', error)
    }
    setProjectsLoading(false)
  }

  const saveProjects = (_projects: Array<Project>) => {
    console.log('ProjectProvider - saveProjects - _projects:', _projects)
    const jsonData = JSON.stringify(_projects)
    console.log('ProjectProvider - saveProjects - jsonData:', jsonData)
    localStorage.setItem('projectsCache', jsonData)
  }

  // NB: debug only - careful using this, will wipe all saved projects!
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearProjects = () => {
    localStorage.removeItem('projectsCache')
  }

  // -------
  
  useMountEffect(() => {
    console.log('ProjectProvider - useMountEffect')
    loadProjects()
  })

  useUnmountEffect(() => {
    console.log('ProjectProvider - useUnmountEffect')
  })

  // -------

  const getProjectForKey = (key: string): Promise<Project | undefined> => {
    return new Promise((resolve) => resolve(projects.find(p => p.key === key)))
  }

  const getProjectForId = (projectId: number): Promise<Project | undefined> => {
    console.log('ProjectProvider - getProjectForId - projectId:', projectId, ' projectsLoading:', projectsLoading, ' projectsLoaded:', projectsLoaded, ' projects:', projects)
    // NB: assumes its for the current user only (which is all this localStorage cache version handles, but may need to specify the userId when extending this to be api backed in the future)
    return new Promise((resolve) => resolve(projects.find(p => p.projectId === projectId)))
  }

  const _newProjectIds = () => {
    const userId = '1' // NB: placeholder for now - only really needed once we move to api server side saving, at which point the api would handle setting the users id/key
    let projectId = 1
    for (const _project of projects) { if (_project.projectId >= projectId) projectId = _project.projectId + 1 } // find the next available project id
    console.log('ProjectProvider - createProject - projectId:', projectId)
    const key = 'u' + userId + '_p' + projectId
    return { userId, projectId, key }
  }
  const _createProjectModel = (title: string): Project => {
    console.log('ProjectProvider - _createProjectModel - title:', title)
    const { key, userId, projectId } = _newProjectIds()
    const project = new Project(
      key,
      userId,
      projectId,
      title,
      undefined,
      undefined,
      new Date()
    )
    return project
  }
  const createProject = async (title: string): Promise<Project> => {
    const project = _createProjectModel(title)
    const newProjects = [...projects, project]
    setProjects(newProjects)
    saveProjects(newProjects)
    return project
  }

  const updateProject = async (key: string, title: string): Promise<Project> => {
    console.log('ProjectProvider - updateProject - key:', key, ' title:', title)
    const project = await getProjectForKey(key)
    if (project) {
      const newProjects = [...projects]
      const index = newProjects.findIndex(p => p.key === key)
      if (index >= 0) {
        newProjects[index].title = title
        setProjects(newProjects)
        saveProjects(newProjects)
        return newProjects[index]
      }
    }
    throw new Error('Project not found')
  }

  const saveProject = async (project: Project): Promise<Project> => {
    console.log('ProjectProvider - saveProject - project:', project)
    const newProjects = [...projects]
    const index = newProjects.findIndex(p => p.key === project.key)
    if (index >= 0) {
      newProjects[index] = project
      setProjects(newProjects)
      saveProjects(newProjects)
      return newProjects[index]
    }
    throw new Error('Project not found')
  }

  const saveProjectAs = async (originalProject: Project, newTitle: string): Promise<Project> => {
    console.log('ProjectProvider - saveProjectAs - originalProject:', originalProject)
    // TODO: check a project with the same title doesn't already exist?
    // NB: this won't work with current useState based hooks in `createProject` & then `saveProject`, as the first won't have fired/completed yet so the 2nd `saveProject` won't have valid state to work with :(
    // const newProject = await createProject(newTitle)
    // newProject.data = originalProject.data
    // await saveProject(newProject)
    // TRY 2: doing it all manually & duping some code from createProject here for now...
    const newProject = _createProjectModel(newTitle)
    newProject.data = originalProject.data
    // replicate the saving portion of `createProject`...
    const newProjects = [...projects, newProject]
    setProjects(newProjects)
    saveProjects(newProjects)
    return newProject
  }

  const deleteProject = async (key: string): Promise<boolean> => {
    const index = projects.findIndex((p) => p.key === key)
    console.log('ProjectProvider - deleteProject - key:', key, ' index:', index)
    if (index >= 0 && index < projects.length) {
      const newProjects = [...projects]
      newProjects.splice(index, 1)
      console.log('ProjectProvider - deleteProject - newProjects:', newProjects)
      setProjects(newProjects)
      saveProjects(newProjects)
      return true
    }
    throw new Error('Project not found')
  }

  // ref: https://blog.shovonhasan.com/using-promises-with-filereader/
  const _readProjectImportFile = async (file: File): Promise<string /* | ArrayBuffer | null */> => {
    const temporaryFileReader = new FileReader()
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort()
        reject(new DOMException("Problem parsing input file."))
      }
      temporaryFileReader.onload = () => {
        if (temporaryFileReader.result) {
          resolve(temporaryFileReader.result as string)
        } else {
          throw new Error('Failed to parse')
        }
      }
      temporaryFileReader.readAsText(file)
    })
  }
  const importProject = async (file: File): Promise<Project> => {
    console.log('ProjectProvider - importProject - file:', file)
    try {
      const importStr = await _readProjectImportFile(file)
      console.log('ProjectProvider - importProject - importStr:', importStr)
      const projectData = JSON.parse(importStr)
      console.log('ProjectProvider - importProject - projectData:', projectData)
      if (projectData) {
        const project = Project.fromJSON(projectData)
        if (project) {
          // override imported project key & ids to make sure they are unique & valid within this set of projects
          const { key, userId, projectId } = _newProjectIds()
          project.key = key
          project.userId = userId
          project.projectId = projectId
          // append a unique suffix to the project title
          let uniqueTitle = false
          let importTitleCount = 1
          const projectTitleOriginal = project.title
          while (uniqueTitle === false || importTitleCount >= 100) {
            project.title = projectTitleOriginal + ' Imported-' + (importTitleCount < 10 ? '0' : '') + importTitleCount
            const projectTitleExists = projects.find((p) => p.title === project.title)
            if (!projectTitleExists) uniqueTitle =  true
            importTitleCount++
          }
          // add the imported project & save
          const newProjects = [...projects, project]
          setProjects(newProjects)
          saveProjects(newProjects)
          return project
        }
      }
      throw new Error('Failed to import project')
    } catch (error: any) {
      throw error
    }
    // throw new Error('Failed to import')
  }

  const exportProject = async (project: Project, filename: string): Promise<boolean> => {
    console.log('ProjectProvider - exportProject - project:', project, ' filename:', filename)
    try {
      const jsonData = JSON.stringify(project)
      console.log('ProjectProvider - exportProject - jsonData:', jsonData)
      const jsonBlob = new Blob([jsonData], { type: 'application/json' }) // text/plain
      
      // check if the browser supports the `showOpenFilePicker` function/feature (currently only chrome & some other newer browser versions do, safari doesn't yet)
      if ('showOpenFilePicker' in window) {
        console.log('ProjectProvider - exportProject - showOpenFilePicker == YES')
        // NB: `showSaveFilePicker` is NOT supported in Safari currently & is marked as experimental in other browsers (mid 2023)
        const pickerOptions = {
          suggestedName: `${filename}`,
          types: [
            {
              description: 'Project Export',
              accept: {
                //'text/plain': ['.json'],
                'application/json': ['.json'],
              },
            },
          ],
        }
        const fileHandle = await (window as any).showSaveFilePicker(pickerOptions)
        const writableFileStream = await fileHandle.createWritable()
        await writableFileStream.write(jsonBlob)
        await writableFileStream.close()
      } else {
        console.log('ProjectProvider - exportProject - showOpenFilePicker == NO')
        
        // fallback saving for browsers that don't yet support `showOpenFilePicker`
        // WARNING: this does NOT show a file save-as prompt, just auto downloads it to the users default download dir!
        // ref: https://stackoverflow.com/questions/27573508/filesaver-js-and-blob-js-change-directory

        // const tempLink = document.createElement("a")
        // tempLink.setAttribute('href', URL.createObjectURL(jsonBlob))
        // tempLink.setAttribute('download', `${filename}`)
        // tempLink.click()
      
        // ref: https://www.tutorialspoint.com/how-to-create-and-save-text-file-in-javascript
        // const link = document.createElement("a")
        // link.href = URL.createObjectURL(jsonBlob)
        // link.download = filename
        // link.click()
        // URL.revokeObjectURL(link.href)

        FileSaver.saveAs(jsonBlob, filename)
      }

      return true
    } catch (error: any) {
      throw error
    }
    // throw new Error('Failed to export')
  }

  // -------

  const actions: IProjectActions = {
    getProjectForKey,
    getProjectForId,
    createProject,
    updateProject,
    saveProject,
    saveProjectAs,
    deleteProject,
    importProject,
    exportProject
  }

  const store: IProjectStore = {
    projects,
    projectsLoading,
    projectsLoaded
  }

  return (
    <ProjectContext.Provider value={{ actions, store }}>
      {children}
    </ProjectContext.Provider>
  )
}

export default ProjectProvider
