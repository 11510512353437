import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { AuthContext, AuthStatus, ServerConfigContext, ServerContext } from '.'
import { User } from '../models'
import { ServerAPIClient, ServerAuthAPI } from '../services'
import * as CONFIG from '../constants/config'

// TODO: remove error status state so we always know if logged in/out or are loading, & add a specific error var to check for issues?
export enum UserStatus {
  init, loading, loggedIn, loggedOut, error
}

export const UserContext = createContext<IUserContext>({} as IUserContext)

interface IUserStore {
  userStatus: UserStatus
  user?: User
}

interface IUserActions {
}

interface IUserContext {
  actions: IUserActions
  store: IUserStore
}

interface UserProviderProps {
  apiClient: ServerAPIClient
  authApi: ServerAuthAPI
  children: ReactNode
}

const UserProvider = (props: UserProviderProps) => {
  const { authApi, children } = props

  const authContext = useContext(AuthContext)
  const { authStatus, authUpdated } = authContext.store

  const serverConfigContext = useContext(ServerConfigContext)

  const serverContext = useContext(ServerContext)

  // -------

  const loadServerconfig = async () => {
    // TESTING: load the server config regardless if the user is logged in or not (currently running it before we even check their login status)
    // TODO: run when reload === true or only false? (does it return if the user isn't verified yet etc. if so always load on reload? or trigger on certain events like 'just verified' (if we can detect that))
    await serverConfigContext.actions.loadServerConfig()
  }
  useEffect(() => {
    console.log('UserProvider - userEffect[userStatus] - userStatus:', userStatus)
    loadServerconfig()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // -------
  
  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.init)
  const [user, setUser] = useState<User | undefined>()

  const prevAuthStatusRef = useRef<AuthStatus>()
  const prevAuthUpdatedRef = useRef<Date>()
  useEffect(() => {
    const prevAuthStatus = prevAuthStatusRef.current
    const prevAuthUpdated = prevAuthUpdatedRef.current
    if (authStatus === AuthStatus.loggedIn && prevAuthStatus !== AuthStatus.loggedIn) {
      // logged in
      console.log('UserProvider - componentDidUpdate - USER LOGGED IN')
      loadUserData()
    } else if (authStatus === AuthStatus.loggedOut && prevAuthStatus !== AuthStatus.loggedOut) {
      // logged out
      console.log('UserProvider - componentDidUpdate - USER LOGGED OUT')
      clearUserData()
    } else if (authStatus !== prevAuthStatus) {
      console.log('UserProvider - componentDidUpdate - OTHER AUTH STATUS CHANGE - FROM: ', prevAuthStatus, ' TO: ', authStatus)
      // NB: shouldn't need to do anything here (should only fire when transitioning into the initial loading auth status)
    } else if (authUpdated !== prevAuthUpdated) {
      console.log('UserProvider - componentDidUpdate - OTHER USER AUTH/STATUS CHANGE - userStatus: ', userStatus)
      // if the auth was updated, re-grab the user object to grab whatever changes were made (e.g email verified, 2fa enabled/disabled etc.)
      // TESTING: re-run the full user data updates, incase the auth/status change effected access to any of the data
      // TODO: don't change the userStatus while doing it though?
      // TODO: call the newer reloadUserData? but don't trigger an /auth/me update as this would of just been from one??
      // TESTING: don't re-trigger while the userStatus is already loading
      if (userStatus !== UserStatus.loading) {
        loadUserData(true)
      }
    }
    prevAuthStatusRef.current = authStatus
    prevAuthUpdatedRef.current = authUpdated
  // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, authUpdated]) // NB: we don't need/want to listen for `userStatus` changed here I think, only the auth ones
  
  useEffect(() => {
    console.log('UserProvider - userEffect[userStatus] - userStatus:', userStatus)
  }, [userStatus])

  // -------

  // TODO: should this only been called for initial login/page-load, & if we need to re-load, add a specific reloadUserData function?
  // TODO: (if not, maybe only conditionally re-load the cache & maybe certain other actions?)
  const loadUserData = async (reload: boolean = false) => {
    console.log('UserProvider - loadUserData - reload:', reload)

    const user = authApi.authUser
    if (!user) {
      setUserStatus(UserStatus.loggedOut)
      return
    }

    if (!reload) setUserStatus(UserStatus.loading)
    setUser(user)

    // TODO: PORT (if adding UserCache support to this app?)
    // const userCache = this.loadUserCache(user.id)
    // this.setState({ userCache })

    // TODO: load any other user specific data (e.g. if admin may need to make additional api calls etc.)...
    //await new Promise(resolve => setTimeout(resolve, 1000)) // DEBUG ONLY: add a delay to test the loading state

    console.log('UserProvider - loadUserData - setUserStatus:', UserStatus.loggedIn)
    setUserStatus(UserStatus.loggedIn)

    // TESTING HERE:
    if (user && user.roles && user.roles.includes('admin')) {
      // TESTING: when openai direct mode is enabled load the api from our api server (so the user doesn't have to request it direct)
      // NB: ONLY admins can use this api request
      if (CONFIG.AI_DIRECT_MODE_ENABLED) {
        const openAIKey = await serverConfigContext.actions.loadOpenAIKey()
        if (openAIKey && serverContext.store.openAIApiClient) serverContext.store.openAIApiClient.authToken = openAIKey
      }
    }
  }

  // NB: not currently used - temp disabled the linter warning for it as we may need it in the future..
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const reloadUserData = async () => {
    console.log('UserProvider - reloadUserData')

    // TESTING: also re-load the main user object (currently done via the original auth api layer)
    // TESTING: NB: we don't do this within loadUserData, as on init the user object has just been loaded by the AuthProvider,
    // TESTING: NB: ..so we just grab that version from its cache to save a dupe api call
    // NB: this will trigger the AuthProvider authUpdated date prop to update, which the componentDidUpdate catches above & calls loadUserData to load the rest of the data
    await authApi.loadLoggedInUser()

    // UPDATE: commented out to stop dupe calls (remove after testing reloads throughout the app to make sure it always fires like that)
    // NB: the call to loadLoggedInUser triggers the AuthProvider authUpdated date prop to update,
    // NB: ..which the componentDidUpdate catches above & triggers loadUserData anyway
    // await loadUserData(true)
  }

  const clearUserData = () => {
    console.log('UserProvider - clearUserData')
    setUserStatus(UserStatus.loggedOut)
    setUser(undefined)
    // TODO: clear any other user/auth related vars here (UserCache etc. if adding support for it in this app)
  }

  // -------

  const actions: IUserActions = {
  }

  const store: IUserStore = {
    userStatus,
    user
  }

  return (
    <UserContext.Provider value={{ actions, store }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
