
class User {

  id: string
  email: string
  name?: string
  isVerified?: boolean
  roles?: Array<string>
  createdAt?: Date
  updatedAt?: Date

  json?: any // raw json data for debugging only

  constructor (
    id: string,
    email: string,
    name?: string,
    isVerified?: boolean,
    roles?: Array<string>,
    createdAt?: Date,
    updatedAt?: Date,
  ) {
    this.id = id
    this.email = email
    this.name = name
    this.isVerified = isVerified
    this.roles = roles
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : User | null {
    if (!json) return null
    //console.log('User - fromJSON - json.id: ', json.id)
    const user = new User(
      json.id ?? json._id,
      json.email,
      json.name,
      json.isVerified,
      json.roles,
      json.createdAt ? new Date(json.createdAt) : undefined, // NB: early users didn't have this - TODO: make this required & add to all users?
      json.updatedAt ? new Date(json.updatedAt) : undefined,
    )
    return user
  }
}

export default User
