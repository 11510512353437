import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AuthContext, AuthStatus } from '../../../providers'
import { UserResetPassLookup } from '../../../models'

import * as ROUTES from '../../../constants/routes'

import BasePage from '../../../components/BasePage/BasePage'
import CenterLayout from '../../../components/CenterLayout'

import styles from './ForgotPasswordResetPage.module.css'

const ForgotPasswordResetPage = () => {

  const { resetToken } = useParams()
  
  const authContext = useContext(AuthContext)

  const navigate = useNavigate()

  const [showForm, setShowForm] = useState<boolean>(true)
  const [tokenDetails, setTokenDetails] = useState<UserResetPassLookup>()
  
  const [pass, setPass] = useState<string>('')
  const [confirmPass, setConfirmPass] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [tokenError, setTokenError] = useState<Error | undefined>(undefined) // NB: only used while checking/loading the token details, not for the actual submission, which is handled via the auth provider & its state vars

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [resetComplete, setResetComplete] = useState<boolean>(false)

  const runCheckResetPassToken = async () => {
    console.log('ForgotPasswordResetPage - runCheckResetPassToken - resetToken:', resetToken)
    setLoading(true)
    try {
      const _inviteDetails = await authContext.actions.checkForgotPassToken(resetToken ?? '')
      setTokenDetails(_inviteDetails)
    } catch (error: any) {
      setTokenError(error)
      setShowForm(false)
    }
    setLoading(false)
  }

  const runChecks = async () => {
    
    // redirect to the home page if already logged in (shouldn't get this far if you are, but just incase)
    const isLoggedIn = authContext.actions.isLoggedIn()
    if (isLoggedIn) {
      console.log('InviteAcceptPage - runAcceptInviteToken - ALREADY LOGGED IN - HALT')
     navigate(ROUTES.HOME)
     return // NB: should already be handled by the base routing code
    }

    // TESTING:
    await runCheckResetPassToken()
  }

  const runResetPassword = async () => {
    console.log('InviteAcceptPage - runResetPassword - resetToken:', resetToken)
    setIsSubmitting(true)
    try {
      await authContext.actions.resetEmailPassword(resetToken ?? '', pass, confirmPass)
      setIsSubmitting(false)
      setResetComplete(true)
    } catch (error) {
      // NB: we don't handle the error directly here (the AuthProvider handles it via its authError store var), we simply want to reset the local submitting flag so it doesn't get stuck on
      setIsSubmitting(false)
    }
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log('ForgotPasswordResetPage - onSubmit')
    event.preventDefault()
    runResetPassword()
  }

  useEffect(() => {
      runChecks()
    },
    // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // UPDATE: disabled - unlike the invite accept handling we don't auto log the user in after a password change, they need to do so manually
  // useEffect(() => {
  //   console.log('ForgotPasswordResetPage - useEffect - authContext.store.authStatus:', authContext.store.authStatus)
  //   if (authContext.store.authStatus === AuthStatus.loggedIn && resetComplete === false && !tokenError) {
  //     console.log('ForgotPasswordResetPage - useEffect - authContext.store.authStatus - RESET PASSWORD COMPLETE...')
  //     setResetComplete(true)
  //   }
  // }, [authContext.store.authStatus, resetComplete, tokenError])

  // TESTING: handle resetting the auth error if one happened & we then change the password values so we can try a re-submit...
  // UPDATE: not needed (the submit button is now reset as soon as an error fires instead)
  // useEffect(() => {
  //   if (authContext.store.authError) {
  //     authContext.actions.resetAuthError()
  //   }
  //   // NB: work-around for funcitonal React component ref loop hell - ref: https://stackoverflow.com/a/58101280
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pass, confirmPass]) // NB: we don't want to add `authContext.actions` so we use the lint disable line above to stop the warning for it

  const onSuccessClick = () => {
    navigate(ROUTES.LOGIN)
  }

  const onGotoForgotPasswordRestartClick = () => {
    navigate(ROUTES.LOGIN_PASSWORD_FORGOT)
  }

  return (
    <BasePage>
      <CenterLayout>
        <h1>{!(resetComplete && !tokenError) ? <>Reset Password</> : <>Password Reset</>}</h1>
        {showForm && !resetComplete && (<p>Enter a new password to finish resetting it.</p>)}
        {tokenError && loading && (<>Checking...</>)}
        {tokenError && isSubmitting && (<>Processing...</>)}
        {resetComplete && !tokenError && (
          <div className={styles.successMsg}>
            <h3>Success!</h3>
            <p>You can now login using your new password</p>
            <br />
            <div>
              <button onClick={onSuccessClick}>Continue</button>
            </div>
          </div>
        )}
        {tokenError && (
          <div className={styles.errMsg}>
            <h3>Reset Password Error</h3>
            <p>{tokenError.message}</p>
            <div>
              <button onClick={onGotoForgotPasswordRestartClick}>Restart</button>
            </div>
          </div>
        )}
        {showForm && !resetComplete && (
          <form className={styles.form + (isSubmitting ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
            {authContext.store.authError && (
              <div className={styles.error}>{authContext.store.authError.message}</div>
            )}
            <div className={styles.field}>
              <label htmlFor="pass">Password:</label>
              <div className={styles.input}>
                <input type="password" value={pass} id="pass" name="pass" autoComplete="password" onChange={(event) => { setPass(event.target.value) }} />
              </div>
            </div>
            <div className={styles.field}>
              <label htmlFor="confirmPass">Confirm Password:</label>
              <div className={styles.input}>
                <input type="password" value={confirmPass} id="confirmPass" name="confirmPass" /*autoComplete="password"*/ onChange={(event) => { setConfirmPass(event.target.value) }} />
              </div>
            </div>
            <div className={styles.buttons}>
              <div className={styles.field + ' ' + styles.button}>
                <input type="submit" value="Reset Password" disabled={isSubmitting} />
              </div>
            </div>
          </form>
        )}
      </CenterLayout>
    </BasePage>
  )
}

export default ForgotPasswordResetPage
