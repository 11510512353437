
export interface IServerAuthConfig {
  regEnabled: boolean
  roles: Array<string>
}

export class ServerConfig {
  
  constructor (
    public apiVersion: string,
    public authConfig: IServerAuthConfig
  ) {
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : ServerConfig | null {
    if (!json) return null
    const authConfig: IServerAuthConfig = {
      regEnabled: json.auth?.regEnabled ?? false,
      roles: json.auth?.roles ?? []
    }
    return new ServerConfig(
      json.apiVersion,
      authConfig
    )
  }
}
