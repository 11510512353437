/**
 * ServerConfigProvider
 */

import React, { createContext, ReactNode, useState } from 'react'
import { ServerConfig } from '../models/ServerConfig'
import ServerAPIClient from '../services/ServerAPIClient'
import ServerConfigAPI from '../services/ServerConfigAPI'

interface IServerConfigActions {
  loadServerConfig: () => Promise<void>
  loadOpenAIKey: () => Promise<string | undefined>
}

interface IServerConfigStore {
  serverConfig?: ServerConfig
  serverConfigLoading: boolean
  serverConfigError?: Error
}

export interface IServerConfigContext {
  actions: IServerConfigActions
  store: IServerConfigStore
}

export interface IServerConfigMultiContext {
  serverConfigContext: IServerConfigContext
}

interface ServerConfigProviderProps {
  apiClient: ServerAPIClient
  serverConfigApi?: ServerConfigAPI
  children: ReactNode
}

export const ServerConfigContext = createContext<IServerConfigContext>({} as IServerConfigContext)

const ServerConfigProvider = (props: ServerConfigProviderProps) => {
  const { children } = props

  const serverConfigApi = props.serverConfigApi ?? new ServerConfigAPI(props.apiClient)

  const [serverConfig, setServerConfig] = useState<ServerConfig>()
  const [serverConfigLoading, setServerConfigLoading] = useState<boolean>(false)
  const [serverConfigError, setServerConfigError] = useState<Error>()

  const loadServerConfig = async () => {
    try {
      setServerConfigError(undefined)
      setServerConfigLoading(true)
      const _serverConfig = await serverConfigApi.getServerConfig()
      setServerConfig(_serverConfig)
      console.log('ServerConfigProvider - loadServerConfig - serverConfig:', _serverConfig)
    } catch (error: any) {
      setServerConfigError(error)
      setServerConfig(undefined) // clear stale data
    }
    setServerConfigLoading(false)
  }

  const loadOpenAIKey = async () => {
    try {
      const _openAIKey = await serverConfigApi.getOpenAIKey()
      console.log('ServerConfigProvider - loadOpenAIKey - _openAIKey:', _openAIKey)
      return _openAIKey
    } catch (error: any) {
      console.error('ServerConfigProvider - loadOpenAIKey - error:', error)
      // TODO: PORT/UPDATE...
      // TODO: throw? (if so make sure calling code is setup to catch it) for now just leaving it to return undefined
    }
    return undefined
  }

  const actions: IServerConfigActions = {
    loadServerConfig,
    loadOpenAIKey
  }

  const store: IServerConfigStore = {
    serverConfig,
    serverConfigLoading,
    serverConfigError
  }

  return (
    <ServerConfigContext.Provider value={{ actions, store }}>
      {children}
    </ServerConfigContext.Provider>
  )
}

const withServerConfigContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withServerConfigContextHOC = (props: any) => (
    <ServerConfigContext.Consumer>
      {(serverConfigContext) => {
        if (serverConfigContext === null) {
          throw new Error('ServerConfigConsumer must be used within a ServerConfigProvider')
        }
        return (<Component {...props} {...{ serverConfigContext: serverConfigContext }} />)
      }}
    </ServerConfigContext.Consumer>
  )
  return withServerConfigContextHOC
}

export default ServerConfigProvider
export { withServerConfigContext }
