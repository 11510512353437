import React, { useContext } from 'react'
import { UserContext, UserStatus } from '../../providers'

import BasePage from '../../components/BasePage/BasePage'
import HomePageLoggedInView from './HomePageLoggedInView'
import PageContent from '../../components/PageContent'

const HomePage = () => {

  const userContext = useContext(UserContext)
  const { userStatus } = userContext.store
  const isLoggedIn = userStatus === UserStatus.loggedIn
  return (
    <PageContent
      leftSidebar={isLoggedIn ? undefined : undefined}
      // rightSidebar={isLoggedIn ? undefined: undefined}
    >
      {isLoggedIn && (<HomePageLoggedInView />)}
    </PageContent>
  )
}

export default HomePage
