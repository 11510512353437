import React from 'react'

import PageContent from '../../components/PageContent'
import NodePlaygroundView from './NodePlaygroundView'

import styles from './NodePlaygroundPage.module.css'

const NodePlaygroundPage = () => {
  return (
    <PageContent>
      <div className={styles.page}>
        <NodePlaygroundView />
      </div>
    </PageContent>
  )
}

export default NodePlaygroundPage
