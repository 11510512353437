import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ProjectContext } from 'src/providers'

import Message from 'src/components/Message'
import Button from 'src/components/Button'

import styles from './ProjectAddEditForm.module.css'
import formStyles from 'src/components/Form/Form.module.css'

interface ProjectImportFormProps {
  onClose: Function
  onCancel?: Function
}

const ProjectImportForm = (props: ProjectImportFormProps) => {
  const { onClose, onCancel } = props
  const projectContext = useContext(ProjectContext)
  
  /**
   * react-hook-form refs:
   *  https://blog.logrocket.com/react-hook-form-complete-guide/
   */
  interface IFormData {
    filelist: FileList
  }

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<IFormData>()

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // console.log('ProjectAddEditForm - watch - firstName:', watch('firstName'))

  const onSubmit = async (data: IFormData) => {
    console.log('ProjectImportForm - onSubmit - data:', data)
    console.log('ProjectImportForm - onSubmit - data.filelist:', data.filelist)
    if (submitting) return
    setSubmitting(true)
    setSaved(false)
    setError(undefined)
    // await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
    try {
      if (!data.filelist || data.filelist.length < 1) {
        throw new Error('No project import file found.')
      }
      await projectContext.actions.importProject(data.filelist[0])
      setSaved(true)
    } catch (error: any) {
      console.error('ProjectImportForm - onSubmit - error:', error)
      setError(error)
    }
    setSubmitting(false)
  }

  const onError = (errors: any) => { // TOOD: type <<<<
    console.log('ProjectImportForm - onError - errors:', errors)
  }

  // TESTING: submit on enter - ref: https://github.com/react-hook-form/react-hook-form/issues/936
  const onKeyDownInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    console.log('ProjectImportForm - onKeyDownInput - event:', event)
    if (event.key === "Enter" && !event.shiftKey) {
      console.log('ProjectImportForm - onKeyDownInput - OK - SUBMIT...')
      event.preventDefault()
      handleSubmit(onSubmit)()
    }
  }
  // const onKeyDownButton = (event: React.KeyboardEvent<HTMLButtonElement>) => {
  //   console.log('ProjectAddEditForm - onKeyDownButton - event:', event)
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     console.log('ProjectAddEditForm - onKeyDownButton - ENTER - SUBMIT...')
  //     event.preventDefault()
  //   }
  // }

  return (
    <>
      {saved && (
        <div className={styles.successMsg}>
          <Message positive>
            <p>Project imported</p>
          </Message>
          <Button secondary autoFocus onClick={() => { onClose() }} className={styles.successMsgBtn} /* onKeyDown={onKeyDownButton} */>OK</Button>
        </div>
      )}
      {error && (
        <div className={styles.errorMsg}>
          <Message error>
            <p>Error: {error.message}</p>
          </Message>
          {/* <Button secondary onClick={() => { onClose() }} className={styles.errorMsgBtn}>OK</Button> */}
        </div>
      )}
      {!saved && (
        <form onSubmit={handleSubmit(onSubmit, onError)} className={formStyles.form}>
          
          <div className={formStyles.field}>
            <label htmlFor='title'>Project Import File</label>
            <input
              type='file'
              placeholder='Project Import File'
              accept='application/json'
              // defaultValue={project ? defaultProjectExportFilename(project) : undefined}
              // size={30}
              autoFocus
              {...register('filelist', { required: 'Project import file is required' })}
              onKeyDown={onKeyDownInput}
            />
            {errors.filelist && <div className={formStyles.fieldError}>{errors.filelist.message}</div>}
          </div>

          <div className={formStyles.fieldGroup + ' spaceEqual padTop'}>
            <div className={formStyles.field}>
              {/* <input type="button" value="Cancel" onClick={() => { if (onCancel) onCancel() }} /> */}
              <Button secondary disabled={submitting} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.preventDefault() // don't submit
                if (onCancel) onCancel()
              }}>Cancel</Button>
            </div>
            <div className={formStyles.field}>
              {/* <input type="submit" value="Save" /> */}
              <Button type='submit' primary loading={submitting} disabled={submitting}>Import</Button>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default ProjectImportForm
