import React from 'react'
import { BezierEdgeProps, getBezierPath } from 'reactflow' // EdgeProps, Position, SmoothStepEdgeProps

// ref: https://reactflow.dev/docs/examples/edges/edge-with-button/

import styles from './ButtonEdge.module.css'

const foreignObjectSize = 40

const ButtonEdge = (props: BezierEdgeProps) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd
  } = props

  const onEdgeClick = (evt: any, id?: string) => {
    evt.stopPropagation();
    console.log('onEdgeClick - remove - id:', id)
  }

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className={styles.edgeButtonForeignObject}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div>
          <button className={styles.edgeButton} onClick={(event) => onEdgeClick(event, id)}>
            ×
          </button>
        </div>
      </foreignObject>
    </>
  )
}

export default ButtonEdge
