import React from 'react'
import { Link } from 'react-router-dom'

import BasePage from '../../../components/BasePage/BasePage'
import * as ROUTES from '../../../constants/routes'

import styles from './AccountMenuPage.module.css'

const AccountMenuPage = () => {
  return (
    <BasePage
      pageTitle='Account'
      breakcrumb={[<Link to={ROUTES.HOME}>Home</Link>]}
    >
      <div className={styles.account}>
        <ul>
          <li><Link to={ROUTES.ACCOUNT_DETAILS}>Account Details</Link></li>
          <li><Link to={ROUTES.ACCOUNT_PASSWORD}>Change Password</Link></li>
        </ul>
      </div>
    </BasePage>
  )
}

export default AccountMenuPage
