import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '../Button'
import Header from '../Header'
import Message from '../Message'
import Modal, { ModalProps } from '../Modal'

import styles from './DeleteConfirmModal.module.css'
import formStyles from 'src/components/Form/Form.module.css'


export interface DeleteConfirmModalProps extends ModalProps {
  typeName: string
  deleteName: string
  onConfirm: () => Promise<{ result: boolean, error?: Error }>
  onCancel: () => void
}

const DeleteConfirmModal = (props: DeleteConfirmModalProps) => {
  const { typeName, deleteName, onConfirm, onCancel, ...otherProps } = props

  interface IFormData {
    deleteName: string
  }

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormData>()

  const watchNameField = watch('deleteName')

  const [allowDelete, setAllowDelete] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [deleted, setDeleted] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    if (watchNameField === deleteName && allowDelete === false) {
      setAllowDelete(true)
    } else if (allowDelete === true) {
      setAllowDelete(false)
    }
  }, [watchNameField])

  // -------

  const resetState = () => {
    reset() // reset the (internal) form values
    setAllowDelete(false)
    setSubmitting(false)
    setDeleted(false)
    setError(undefined)
  }

  // -------

  const onSubmit = async (data: IFormData) => {
    console.log('DeleteConfirmModal - onSubmit - data:', data)
    if (submitting) return
    setSubmitting(true)
    setDeleted(false)
    setError(undefined)
    // // DEBUG ONLY: mock api call
    // await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
    // setSubmitting(false)
    // // DEBUG ONLY: mock success response
    // // setDeleted(true)
    // // DEBUG ONLY: mock error
    // // setError(new Error('DEBUG ERROR'))
    const result = await onConfirm()
    console.log('DeleteConfirmModal - onSubmit - result:', result)
    // const result: { result: boolean, error?: Error } = { result: true, error: undefined }
    setSubmitting(false)
    if (result.result && !result.error) {
      setDeleted(true)
    } else {
      setError(result.error ?? new Error('Failed to delete'))
    }
  }

  const onClose = () => {
    resetState()
    onCancel()
  }

  // -------

  return (
    <Modal
      {...otherProps}
    >
      {deleted && (
        <>
          <Header title={`${typeName} Deleted`}></Header>
          <div className={styles.successMsg}>
            <Message positive><p>The {typeName} was successfully deleted</p></Message>
            <Button secondary onClick={() => { onClose() }} className={styles.successMsgBtn}>OK</Button>
          </div>
        </>
      )}
      {!deleted && (
        <>
          <Header title={`Delete ${typeName}?`}></Header>
          <div className={styles.content}>
            <form onSubmit={handleSubmit(onSubmit)} className={formStyles.form + ' ' + styles.deleteLevelForm}>
              <p>Are you sure you want to delete {typeName}: <span className={styles.deleteName}>{deleteName}</span>?</p>
              <p>This cannot be undone!</p>
              {error && (
                <Message error className={styles.errorMsg}><p>Error: {error.message}</p></Message>
              )}
              <p>Type the {typeName} name below to confirm you want to delete it.</p>
              <div className={formStyles.field}>
                {/* <label htmlFor='name'>Level Name</label> */}
                <input type='text' placeholder={`${typeName} name`} size={30} {...register('deleteName', { required: `${typeName} name is required` })} />
                {errors.deleteName && <div className={formStyles.fieldError}>{errors.deleteName.message}</div>}
              </div>
              <div className={formStyles.fieldGroup + ' spaceEqual padTop'}>
                <div className={formStyles.field}>
                  {/* <input type="button" value="Cancel" onClick={() => { if (onCancel) onCancel() }} /> */}
                  <Button secondary disabled={submitting} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.preventDefault() // don't submit
                    onClose()
                  }}>Cancel</Button>
                </div>
                <div className={formStyles.field}>
                  {/* <input type="submit" value="Save" /> */}
                  <Button error loading={submitting} disabled={!allowDelete || submitting}>Delete</Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </Modal>
  )
}

export default DeleteConfirmModal
