import React, { useContext } from 'react'

import { ProjectProvider, ServerContext } from '../../providers'

import PageContent from '../../components/PageContent'
import ProjectsListView from './ProjectsListView'

import styles from './ProjectsPage.module.css'

const ProjectsPage = () => {
  const serverContext = useContext(ServerContext)
  return (
    <PageContent>
      <div className={styles.page}>
        <ProjectProvider apiClient={serverContext.store.apiClient}>
          <ProjectsListView />
        </ProjectProvider>
      </div>
    </PageContent>
  )
}

export default ProjectsPage
