import React from 'react'
import { Handle, NodeProps, Position } from 'reactflow'

import styles from './UserInputNode.module.css'

export interface UserInputNodeProps {
  input: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const UserInputNode = (props: NodeProps<UserInputNodeProps>) => {
  const { isConnectable, data } = props
  const maxLength = 100
  const inputText = data.input && data.input.length > maxLength ? data.input.substring(0, maxLength) + '...' : data.input
  return (
    <div className={styles.node}>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#1a192b' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div>
        {inputText}
      </div>
      {/* <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color} /> */}
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ /* left: 0, */ background: '#1a192b' }}
        isConnectable={isConnectable}
      />
      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        style={{ right: 0, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      /> */}
    </div>
  )
}

export default UserInputNode
