import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'

import PrivateRoute, { AccessLevel } from '../../components/PrivateRoute/PrivateRoute'

import { ServerAdminProvider, ServerContext } from '../../providers'

import * as ROUTES from '../../constants/routes'

import AdminMenuPage from './AdminMenuPage/AdminMenuPage'
import AdminUsersPage from './AdminUsersPage/AdminUsersPage'
import AdminUsersInvitePage from './AdminUsersPage/AdminUsersInvitePage'

import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage'

const AdminRouter = () => {
  const { store } = useContext(ServerContext)
  return (
    <ServerAdminProvider apiClient={store.apiClient}>
      <Routes>
        <Route path={'/' /*ROUTES.ADMIN*/} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<AdminMenuPage />} />} />
        <Route path={ROUTES.ADMIN_USERS.replace(ROUTES.ADMIN, '')} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<AdminUsersPage />} />} />
        <Route path={ROUTES.ADMIN_USERS_INVITE.replace(ROUTES.ADMIN, '')} element={<PrivateRoute accessLevel={AccessLevel.Admin} outlet={<AdminUsersInvitePage />} />} />

        {/* unknown route fallback */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ServerAdminProvider>
  )
}

export default AdminRouter
