import React from 'react'
import { Link } from 'react-router-dom'

import BasePage from '../../../components/BasePage/BasePage'
import * as ROUTES from '../../../constants/routes'

import styles from './AccountDetailsPage.module.css'

const AccountDetailsPage = () => {
  return (
    <BasePage
      pageTitle='Account Details'
      breakcrumb={[<Link to={ROUTES.HOME}>Home</Link>, <Link to={ROUTES.ACCOUNT}>Account</Link>]}
    >
      <div className={styles.accountDetails}>
        TODO: ...
      </div>
    </BasePage>
  )
}

export default AccountDetailsPage
