export enum UserInviteStatus {
  created, sent, accepted, cancelled, unknown // NB: added unknown to this client side UserInvite model to cover parsing errors/issues
}

export interface IUserInviteSendHistory {
  status: UserInviteStatus
  userId: string
  timeAt: Date
}

// TODO: rename? - this is the admin view of a users invite (not a users own invite)
class UserInvite {

  json?: any // raw json data for debugging only

  constructor (
    public id: string,
    public email: string,
    public status: UserInviteStatus,
    public invitedBy: string,
    public createdAt: Date,
    public updatedAt?: Date,
    public inviteHistory?: Array<IUserInviteSendHistory>,
  ) {
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : UserInvite | null {
    if (!json) return null
    console.log('UserInvite - fromJSON - json.status:', typeof json.status, '=', json.status)
    const userInvite = new UserInvite(
      json.id,
      json.email,
      UserInviteStatus[ UserInviteStatus[json.status] as keyof typeof UserInviteStatus] ?? UserInviteStatus.unknown, // TODO: is this still a number & not the enum?
      json.invitedBy,
      new Date(json.createdAt),
      json.updatedAt ? new Date(json.updatedAt) : undefined,
      json.inviteHistory
    )
    console.log('UserInvite - fromJSON - userInvite - status:', typeof userInvite.status, '=', userInvite.status)
    return userInvite
  }
}

// user specific invite lookup details (non admin)
class UserInviteLookup {

  json?: any // raw json data for debugging only

  constructor (
    public email: string,
    public status: UserInviteStatus,
    public createdAt: Date,
    public updatedAt?: Date,
    public expiresAt?: Date,
    public currentAt?: Date,
    public expiresIn?: number
  ) {
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (json: any) : UserInviteLookup | null {
    if (!json) return null
    const userInvite = new UserInviteLookup(
      json.email,
      UserInviteStatus[ UserInviteStatus[json.status] as keyof typeof UserInviteStatus] ?? UserInviteStatus.unknown, // TODO: is this still a number & not the enum?
      new Date(json.createdAt),
      json.updatedAt ? new Date(json.updatedAt) : undefined,
      json.expiresAt ? new Date(json.expiresAt) : undefined,
      json.currentAt ? new Date(json.currentAt) : undefined,
      json.expiresIn
    )
    return userInvite
  }
}

export default UserInvite
export { UserInviteLookup }
