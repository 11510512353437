import React, { createContext, ReactNode, useRef } from 'react'

import { User, UserInvite } from '../models'
import { ServerAdminAPI, ServerAPIClient, ServerAuthAPI } from '../services'

export const ServerAdminContext = createContext<IServerAdminContext>({} as IServerAdminContext)

interface IServerAdminStore {
}

interface IServerAdminActions {
  // -------
  getUsers: () => Promise<Array<User>>
  updateUserRoles: (userId: string, roles: Array<string>) => Promise<boolean>
  deleteUser: (userId: string) => Promise<boolean>
  // -------
  getUserInvites: () => Promise<Array<UserInvite>>
  inviteUser: (email: string) => Promise<boolean>
  resendUserInvite: (email: string) => Promise<boolean> // TODO: change to use the invite id instead of email?
  cancelUserInvite: (email: string) => Promise<boolean> // TODO: change to use the invite id instead of email?
  // -------
}

interface IServerAdminContext {
  actions: IServerAdminActions
  store: IServerAdminStore
}

interface ServerAdminProviderProps {
  apiClient: ServerAPIClient
  // adminApi: ServerAdminAPI
  children: ReactNode
}

const ServerAdminProvider = (props: ServerAdminProviderProps) => {
  const { apiClient, children } = props

  const adminApiRef = useRef(new ServerAdminAPI(apiClient))
  const adminApi = adminApiRef.current

  // -------

  const getUsers = async (): Promise<Array<User>> =>
    adminApi.getUsers()

  const updateUserRoles = async (userId: string, roles: Array<string>): Promise<boolean> =>
    adminApi.updateUserRoles(userId, roles)

  const deleteUser = async (userId: string): Promise<boolean> =>
    adminApi.deleteUser(userId)

  // -------

  const getUserInvites = async (): Promise<Array<UserInvite>> =>
    adminApi.getUserInvites()

  const inviteUser = async (email: string): Promise<boolean> =>
    adminApi.inviteUser(email)

  const resendUserInvite = async (email: string): Promise<boolean> =>
    adminApi.resendUserInvite(email)

  const cancelUserInvite = async (email: string): Promise<boolean> =>
    adminApi.cancelUserInvite(email)

  // -------

  const actions: IServerAdminActions = {
    // -------
    getUsers,
    updateUserRoles,
    deleteUser,
    // -------
    getUserInvites,
    inviteUser,
    resendUserInvite,
    cancelUserInvite
    // -------
  }

  const store: IServerAdminStore = {
  }

  return (
    <ServerAdminContext.Provider value={{ actions, store }}>
      {children}
    </ServerAdminContext.Provider>
  )
}

export default ServerAdminProvider
