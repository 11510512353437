import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext, UserContext, UserStatus } from '../../providers'

import * as ROUTES from '../../constants/routes'

import styles from './UserHeaderMenu.module.css'

export interface UserHeaderMenuProps {
}

const UserHeaderMenu = (props: UserHeaderMenuProps) => {

  const navigate = useNavigate()
  
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const { user, userStatus } = userContext.store

  const isLoggedIn = userStatus === UserStatus.loggedIn

  const onLoginClick = () => {
    console.log('UserHeaderMenu - onLoginClick')
    navigate(ROUTES.LOGIN)
  }

  const onAccountOptionsClick = () => {
    console.log('UserHeaderMenu - onAccountOptionsClick')
    
  }
  const onAccountClick = () => {
    console.log('UserHeaderMenu - onAccountClick')
    navigate(ROUTES.ACCOUNT)
  }

  const onLogoutClick = () => {
    console.log('UserHeaderMenu - onLogoutClick')
    authContext.actions.logout()
  }

  return (
    <div className={styles.userMenu}>
      {!isLoggedIn && (<div className={styles.btn} onClick={onLoginClick}>LOGIN</div>)}
      {isLoggedIn && (
        <>
          <div className={styles.btn} onClick={onAccountOptionsClick}>{user?.email}</div>
          <div className={styles.userMenuDropdown}>
            <ul>
              <li><div className={styles.btn} onClick={onAccountClick}>Account</div></li>
              <li><div className={styles.btn} onClick={onLogoutClick}>Logout</div></li>
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

export default UserHeaderMenu
