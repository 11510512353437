import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../../providers'

import * as ROUTES from '../../../constants/routes'

import BasePage from '../../../components/BasePage/BasePage'

import styles from './LoginPage.module.css'

const LoginPage = () => {

  const authContext = useContext(AuthContext)
  
  const [email, setEmail] = useState<string>('')
  const [pass, setPass] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log('LoginPage - onSubmit - email:', email, 'pass:', pass)
    event.preventDefault()
    setLoading(true)
    await authContext.actions.loginWithEmailAndPassword(email, pass)
    setLoading(false)
  }

  return (
    <BasePage>
      <div className={styles.page}>
        <form className={styles.form + (loading ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
          <h1>Login</h1>
          {authContext.store.authError && (
            <div className={styles.error}>{authContext.store.authError.message}</div>
          )}
          <div className={styles.field}>
            <label htmlFor="email">Email:</label>
            <div className={styles.input}>
              <input type="text" value={email} id="email" name="email" autoComplete="username" onChange={(event) => { setEmail(event.target.value) }} />
            </div>
          </div>
          <div className={styles.field}>
            <label htmlFor="pass">Password:</label>
            <div className={styles.input}>
              <input type="password" value={pass} id="pass" name="pass" autoComplete="password" onChange={(event) => { setPass(event.target.value) }} />
            </div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.field + ' ' + styles.button + ' loginSubmit'}>
              <input type="submit" value="Login" disabled={loading} />
            </div>
          </div>
          {/* <hr /> */}
          <div className={styles.links}>
            <Link to={ROUTES.REGISTER} className={styles.linkRegister}>Register</Link> | 
            <Link to={ROUTES.LOGIN_PASSWORD_FORGOT} className={styles.linkForgot}>Forgot Password?</Link>
          </div>
        </form>
      </div>
    </BasePage>
  )
}

export default LoginPage
