import ServerAPIClient, { ServerAPIError } from './ServerAPIClient'
import { User } from '../models'
import UserInvite from '../models/UserInvite'

class ServerAdminAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // -------

  getUsers = async (): Promise<Array<User>> => {
    try {
      const response = await this._apiClient.apiGet('/users')
      console.log('ServerAdminAPI - getUsers - response: ', response)
      if (response.data && response.data.users) {
        const usersData = response.data.users
        console.log('ServerAdminAPI - getUsers - usersData: ', usersData)
        const users: Array<User> = []
        for (const userData of usersData) {
          const user = User.fromJSON(userData)
          if (user) users.push(user)
        }
        return users
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerAdminAPI - getUsers - error: ', error)
      throw error
    }
  }

  updateUserRoles = async (userId: string, roles: Array<string>): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiPut('/users/' + userId, { roles })
      if (response.status === 200) {
        // TODO: parse anything from the response? (NB: returns the previous user object (before deletion) as `deletedUser`)
        return true
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - updateUserRoles - error: ', error)
      throw error
    }
  }

  deleteUser = async (userId: string): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/users/' + userId)
      if (response.status === 200) {
        // TODO: parse anything from the response? (NB: returns the previous user object (before deletion) as `deletedUser`)
        return true
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - deleteUser - error: ', error)
      throw error
    }
  }

  // -------

  getUserInvites = async (): Promise<Array<UserInvite>> => {
    try {
      // NB: add `all=1` to also return accepted invites (only active or cancelled are returned by default)
      const response = await this._apiClient.apiGet('/users/invites') // + '?all=1')
      console.log('ServerAdminAPI - getUserInvites - response: ', response)
      if (response.data && response.data.userInvites) {
        const userInvitesData = response.data.userInvites
        console.log('ServerAdminAPI - getUserInvites - userInvitesData: ', userInvitesData)
        const userInvites: Array<UserInvite> = []
        for (const userInviteData of userInvitesData) {
          const userInvite = UserInvite.fromJSON(userInviteData)
          if (userInvite) userInvites.push(userInvite)
        }
        return userInvites
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerAdminAPI - getUserInvites - error: ', error)
      throw error
    }
  }

  inviteUser = async (email: string): Promise<boolean> => {
    if (!email || email.length === 0) {
      throw new ServerAPIError('Email is required')
    }
    try {
      const response = await this._apiClient.apiPost('/users/invite', { email })
      if (response.status === 200) {
        // TODO: parse anything from the response?
        return true
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - inviteUser - error: ', error)
      throw error
    }
  }

  resendUserInvite = async (email: string): Promise<boolean> => {
    if (!email || email.length === 0) {
      throw new ServerAPIError('Email is required')
    }
    try {
      const response = await this._apiClient.apiPost('/users/invite/resend', { email })
      if (response.status === 200) {
        // TODO: parse anything from the response?
        return true
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - resendUserInvite - error: ', error)
      throw error
    }
  }

  cancelUserInvite = async (email: string): Promise<boolean> => {
    if (!email || email.length === 0) {
      throw new ServerAPIError('Email is required')
    }
    try {
      const response = await this._apiClient.apiDelete('/users/invite', { email })
      if (response.status === 200) {
        // TODO: parse anything from the response?
        return true
      }
      throw new ServerAPIError('Invalid response')
    } catch (error) {
      console.error('ServerAuthAPI - cancelUserInvite - error: ', error)
      throw error
    }
  }

  // -------
}

export default ServerAdminAPI
