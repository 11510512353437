import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { AuthContext, AuthStatus, ServerConfigContext } from '../../../providers'

import * as ROUTES from '../../../constants/routes'

import BasePage from '../../../components/BasePage/BasePage'

import styles from './RegisterPage.module.css'

const RegisterPage = () => {

  const serverConfigContext = useContext(ServerConfigContext)
  
  const authContext = useContext(AuthContext)
  
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [pass, setPass] = useState<string>('')
  const [confirmPass, setConfirmPass] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log('RegisterPage - onSubmit - name:', name, 'email:', email, 'pass:', pass, 'confirmPass:', confirmPass)
    event.preventDefault()
    setLoading(true)
    await authContext.actions.registerUserWithEmailAndPassword(name, email, pass, confirmPass)
    setLoading(false)
  }

  if (!serverConfigContext.store.serverConfig?.authConfig.regEnabled) {
    return (
      <BasePage>
        <div className={styles.page}>
          <div className={styles.closed}>
            <h1>REGISTRATION CLOSED</h1>
            <p>Sorry new registration's are currently closed.</p>
            <p>Please try another day to see if we've opened<br />new user registrations again.</p>
          </div>
          <hr  className={styles.divider} />
          <div className={styles.links}>
            <Link to={ROUTES.LOGIN}>Already have an account? Login</Link>
          </div>
        </div>
      </BasePage>
    )
  }

  return (
    <BasePage>
      <div className={styles.page}>
        {authContext.store.authStatus === AuthStatus.loggedIn && (
          <div>
            REGISTRATION SUCCESS
          </div>
        )}
        {authContext.store.authStatus !== AuthStatus.loggedIn && (
          <form className={styles.form + (loading ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
            <h1>Register</h1>
            {authContext.store.authError && (
              <div className={styles.error}>{authContext.store.authError.message}</div>
            )}
            <div className={styles.field}>
              <label htmlFor="email">Name:</label>
              <div className={styles.input}>
                <input type="text" value={name} id="display_name" name="display_name" autoComplete="display_name" onChange={(event) => { setName(event.target.value) }} />
              </div>
            </div>
            <div className={styles.field}>
              <label htmlFor="email">Email:</label>
              <div className={styles.input}>
                <input type="text" value={email} id="email" name="email" autoComplete="username" onChange={(event) => { setEmail(event.target.value) }} />
              </div>
            </div>
            <div className={styles.field}>
              <label htmlFor="pass">Password:</label>
              <div className={styles.input}>
                <input type="password" value={pass} id="pass" name="pass" autoComplete="password" onChange={(event) => { setPass(event.target.value) }} />
              </div>
            </div>
            <div className={styles.field}>
              <label htmlFor="confirmPass">Confirm Password:</label>
              <div className={styles.input}>
                <input type="password" value={confirmPass} id="confirmPass" name="confirmPass" /*autoComplete="password"*/ onChange={(event) => { setConfirmPass(event.target.value) }} />
              </div>
            </div>
            <div className={styles.buttons}>
              <div className={styles.field + ' ' + styles.button}>
                <input type="submit" value="Register" disabled={loading} />
              </div>
            </div>
            <hr  className={styles.divider} />
            <div className={styles.links}>
              <Link to={ROUTES.LOGIN}>Already have an account? Login</Link>
            </div>
          </form>
        )}
      </div>
    </BasePage>
  )
}

export default RegisterPage
