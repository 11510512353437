import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import BasePage from '../../../components/BasePage/BasePage'
import { AuthContext, UserContext } from '../../../providers'

import * as ROUTES from '../../../constants/routes'

import styles from './ChangePasswordPage.module.css'

const ChangePasswordPage = () => {

  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const { user } = userContext.store
  
  const [currentPass, setCurrentPass] = useState<string>('')
  const [newPass, setNewPass] = useState<string>('')
  const [newPass2, setNewPass2] = useState<string>('')

  const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({})

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log('ChangePasswordPage - onSubmit - currentPass:', currentPass, 'newPass:', newPass, 'newPass2:', newPass2)
    event.preventDefault()
    setLoading(true)

    const formErrors: { [key: string]: any } = {}
    
    //await new Promise(resolve => setTimeout(resolve, 1000)) // DEBUG ONLY: add a delay to test the loading state

    // required fields check
    if (currentPass.length === 0) {
      formErrors['currentPass'] = { msg: 'Current Password is required' }
    }
    if (newPass.length === 0) {
      formErrors['newPass'] = { msg: 'New Password is required' }
    }
    if (newPass2.length === 0) {
      formErrors['newPass2'] = { msg: 'Confirm New Password is required' }
    }

    if (formErrors['newPass'] === undefined && formErrors['newPass2'] === undefined) {

      // new passwords match
      if (newPass !== newPass2) {
        formErrors['newPass'] = { msg: '' }
        formErrors['newPass2'] = { msg: 'Passwords don\'t match' }
      }
      // new pass is different to the old
      else if (currentPass === newPass) {
        formErrors['newPass'] = { msg: 'New password must be different to the old one' }
      }
    }
    
    // TODO: other password validation checks (min length, chars etc.)? or leave it for the server api call to respond & display those errors directly instead??

    console.log('ChangePasswordPage - onSubmit - formErrors:', Object.keys(formErrors).length, '==', formErrors)

    if (Object.keys(formErrors).length > 0) {
      setFormErrors(formErrors)
    } else {
      setFormErrors({})

      // TODO: handle errors (either catch exception or get the AuthProvider to catch & update state which we check here??)
      await authContext.actions.changePassword(currentPass, newPass)
    }

    setLoading(false)
  }

  return (
    <BasePage
      pageTitle='Change Password'
      breakcrumb={[<Link to={ROUTES.HOME}>Home</Link>, <Link to={ROUTES.ACCOUNT}>Account</Link>]}
    >
      <div className={styles.page}>
        <form className={styles.form + (loading ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
          <h1>Change Password</h1>
          <div className={styles.field + ' ' + styles.emailField}>{/* NB: email field only added for accessibility, hidden via css */}
            <label htmlFor="email">Email:</label>
            <div className={styles.input}>
              <input type="text" value={user?.email} id="email" name="email" autoComplete="email" disabled={true} />
            </div>
          </div>
          <div className={styles.field + (formErrors['currentPass'] !== undefined ? ' ' + styles.error : '')}>
            <label htmlFor="currentPass">Current Password:</label>
            <div className={styles.input}>
              <input type="password" value={currentPass} id="currentPass" name="currentPass" autoComplete="password" onChange={(event) => { setCurrentPass(event.target.value) }} />
              {formErrors['currentPass'] !== undefined && (<div className={styles.errorMsg}>{formErrors['currentPass'].msg}</div>)}
            </div>
          </div>
          <div className={styles.field + (formErrors['newPass'] !== undefined ? ' ' + styles.error : '')}>
            <label htmlFor="newPass">New Password:</label>
            <div className={styles.input}>
              <input type="password" value={newPass} id="newPass" name="newPass" autoComplete="new-password" onChange={(event) => { setNewPass(event.target.value) }} />
              {formErrors['newPass'] !== undefined && (<div className={styles.errorMsg}>{formErrors['newPass'].msg}</div>)}
            </div>
          </div>
          <div className={styles.field + (formErrors['newPass2'] !== undefined ? ' ' + styles.error : '')}>
            <label htmlFor="newPass2">Confirm New Password:</label>
            <div className={styles.input}>
              <input type="password" value={newPass2} id="newPass2" name="newPass2" autoComplete="new-password" onChange={(event) => { setNewPass2(event.target.value) }} />
              {formErrors['newPass2'] !== undefined && (<div className={styles.errorMsg}>{formErrors['newPass2'].msg}</div>)}
            </div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.field + ' ' + styles.button}>
              <input type="submit" value="Submit" disabled={loading} />
            </div>
          </div>
        </form>
      </div>
    </BasePage>
  )
}

export default ChangePasswordPage
