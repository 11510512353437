import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../../providers'

import * as ROUTES from '../../../constants/routes'

import BasePage from '../../../components/BasePage/BasePage'

import styles from './ForgotPasswordPage.module.css'

const ForgotPasswordPage = () => {

  const authContext = useContext(AuthContext)
  
  const [email, setEmail] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [sent, setSent] = useState<boolean>(false)

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log('ForgotPasswordPage - onSubmit - email:', email)
    event.preventDefault()
    setLoading(true)
    setSent(false)
    try {
      await authContext.actions.forgotEmailPassword(email)
      setSent(true)
    } catch (error) {
      console.error('ForgotPasswordPage - onSubmit - error:', error)
    }
    setLoading(false)
  }

  return (
    <BasePage>
      <div className={styles.page}>
        <form className={styles.form + (loading ? ' ' + styles.submitting : '')} onSubmit={onSubmit}>
          <h1>Forgot Password</h1>
          {authContext.store.authError && (
            <div className={styles.error}>{authContext.store.authError.message}</div>
          )}
          {!sent && (<>
            <div className={styles.field}>
              <label htmlFor="email">Email:</label>
              <div className={styles.input}>
                <input type="text" value={email} id="email" name="email" autoComplete="username" onChange={(event) => { setEmail(event.target.value) }} />
              </div>
            </div>
            <div className={styles.buttons}>
              <div className={styles.field + ' ' + styles.button}>
                <input type="submit" value="Submit" disabled={loading} />
              </div>
            </div>
          </>)}
          {sent && (<>
            <div className={styles.successMsg}>
              <h2>Email Sent</h2>
              <p>A password reset link has been emailed to you.</p>
              <p><strong>Please follow the steps in the email to reset your password.</strong></p>
            </div>
            <hr  className={styles.divider} />
          </>)}
          {/* <hr  className={styles.divider} /> */}
          <div className={styles.links}>
            <Link to={ROUTES.LOGIN}>or Login</Link>
          </div>
        </form>
      </div>
    </BasePage>
  )
}

export default ForgotPasswordPage
